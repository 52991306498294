import React from "react";

import {Grid, TextField} from "@material-ui/core";
import {  convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const StaticPageFrom = ({formik , RichTextEditor  , text , setText }) => {
    const {handleChange, values, touched, errors} = formik;



const handletext=(value)=>{
    console.log(draftToHtml(convertToRaw(value.getCurrentContent())));
  
    setText(value)

}
 
console.log("scsdvc",values.message);
    return (
        <Grid container spacing={3}>
            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    
                    variant="outlined"
                    id="title"
                    name="title"
                    label="Title"
                    value={values.title}
                    onChange={handleChange}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                />
            </Grid>
            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    
                    variant="outlined"
                    id="subTitle"
                    name="subTitle"
                    label="Sub Title"
                    value={values.subTitle}
                    onChange={handleChange}
                    error={touched.subTitle && Boolean(errors.subTitle)}
                    helperText={touched.subTitle && errors.subTitle}
                />
            </Grid>

            <Grid sm={12} xs={12} item>
                <label>Message</label>
                <br/>
            {/* <RichTextEditor value={values.message.toString("html")} onChange={handletext} /> */}
            <Editor
  wrapperClassName="wrapper-class"
  editorClassName="editor-class"
  toolbarClassName="toolbar-class"
  onEditorStateChange={handletext}
  editorState={text} 
//   defaultContentState={}
  
/>
            </Grid>
           

           
        </Grid>
    );
};

export default StaticPageFrom;
