import React from "react";
import moment from 'moment';
import {LinearProgress, Paper, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import {Link} from "react-router-dom";

const sortIcon = <ArrowDownward/>;

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        table: {
            minWidth: 650,
        },
    },
    btn: {
        margin: theme.spacing(4)
      }
}));

const NotificationList = ({giftsList}) => {
    const classes = useStyles();
    const heading = [
        {
            name: "Sr. No",
            wrap: true,
            sortable: true,
            cell: (row, index) => index + 1
        }, {
            name: "Image",
            wrap: true,
            sortable: true,
            cell: (giftsList) => <>
            {
                giftsList.image ? 
                <a target="_blank" href={giftsList.image} style={{textDecoration: 'none'}}>
                    <img src={giftsList.image} alt="Img" style={{ width: "50px", height: "50px" }} />
                </a> : <p style={{ textAlign: "center" }}>-</p>
            }
            </>
        }, {
            name: "Title",
            selector: "title",
            wrap: true,
            sortable: true
        }, {
            name: "Message",
            selector: "message",
            wrap: true,
            sortable: true
        }, {
            name: "User Count",
            wrap: true,
            sortable: true,
            cell: (giftsList) => (
                <Link to="/dashboard/users" style={{textDecoration: 'none'}}>
                    <p>{giftsList && giftsList.toUsers && giftsList.toUsers.length}</p>
                </Link>
            )
        }, {
            name: "Date",
            wrap: true,
            sortable: true,
            cell: (giftsList) => (
                <p>{giftsList && giftsList.createdAt ? moment(new Date(giftsList.createdAt)).format('DD/MM/YYYY hh:mm') : ""}</p>
            )
        }
    ];

    return (
        <div>
            {!giftsList ? (
                <LinearProgress color="primary"/>
            ) : (
                <Paper className={classes.paper} square>
                    <Link to='/dashboard/send-notification'>
                        <Button variant="contained" color="primary" className={classes.btn}>Send Notification</Button>
                    </Link>
                    <DataTable
                        columns={heading}
                        noHeader={true}
                        data={giftsList}
                        pagination
                        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                        sortIcon={sortIcon}
                        striped={true}
                        highlightOnHover={true}
                        responsive={true}
                    />
                </Paper>
            )}
        </div>
    );
};

export default NotificationList;
