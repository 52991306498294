import React from "react";
import Button from '@material-ui/core/Button';
import {TextField} from '@material-ui/core';

const SearchLayout = (props) => {
    const {onFilter, placeHolderText} = props;
    const [query, setQuery] = React.useState("");

    const handleSearch = async (e) => {
        e.preventDefault();
        onFilter(query);
    };

    return (
        <form onSubmit={handleSearch}>
            <div className="row">
                <div className="col-md-12">
                    <div className="input-group serch-sec">
                        <TextField
                            size="small"
                            variant="outlined"
                            type="search"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            className="form-control rounded"
                            placeholder={placeHolderText}
                            aria-label="Search"
                            aria-describedby="search-addon"
                        />&nbsp;

                        <Button style={{height:"38px"}}variant="contained" color="primary" onClick={handleSearch}>
                            Go
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default SearchLayout;
