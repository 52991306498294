import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageContainer from "../../layouts/PageContainer";
import Button from "@material-ui/core/Button";
import { LocationOn } from "@material-ui/icons";
import { TextField } from "@material-ui/core";
import { Grid } from "@mui/material";
import icon from "../../assets/images/avatar-icon.png";
import { helpers } from "../../helper/helper";
import LikeModal from "./LikeModal";
import CommentModal from "./CommentModal";
import PaginationControls from "./PaginationControls";
import PaginationInfo from "./PaginationInfo";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";
import DeleteReson from "../../components/DeleteReson";
import HideReson from "../../components/HideReson";
import SelectTopPostModel from "../../components/SelectTopPostModel";

const Posts = () => {
  // post top selection :
  const [isHidePost, setIsHidePost] = useState(false);
  const [isDeletedPost, setIsDeletedPost] = useState(false);
  const [postTopMadal, setPostTopMadal] = useState(false);
  const [isSelectedPost, setIsSelectedPost] = useState(false);
  const [postId, setPostId] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [deletedPosts, setdeletedPosts] = React.useState("0");
  const [hidePosts, setHidePosts] = React.useState("0");
  const [search, setSearch] = React.useState("");
  const [totalCounts, setTotalCounts] = React.useState(null);
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [list, setList] = React.useState([]);
  const [likeModal, setLikeModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [selectedPostLikeData, setSelectedPostLikeData] = useState([]);
  const [selectedPostCommentData, setSelectedPostCommentData] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [tag, setTag] = React.useState(location.state?.tag || "");
  const [modal, setModal] = React.useState(false);
  const [reason, setReason] = useState("");
  const [deletablePostId, setDeletablePostId] = useState("");
  const [hideModal, setHideModal] = React.useState(false);
  const [hideReason, setHideReason] = useState("");
  const [hidePostId, setHidePostId] = useState("");

  const handleCloseModal = () => {
    setModal(false);
    setReason("");
    setDeletablePostId("");
  };

  const handleCloseHideModal = () => {
    setHideModal(false);
    setHideReason("");
    setHidePostId("");
  };

  const handleCloseLikeModal = () => {
    setLikeModal(false);
    setSelectedPostLikeData([]);
  };

  const handleCloseCommentModal = () => {
    setCommentModal(false);
    setSelectedPostCommentData([]);
  };

  useEffect(() => {
    getListOfPost();
  }, []);

  useEffect(() => {
    getListOfPost();
  }, [page, deletedPosts, hidePosts, tag]);

  const getListOfPost = async () => {
    setLoading(true);
    const url = `post/list/admin?skip=${page}&limit=${limit}&deleted=${deletedPosts}&hide=${hidePosts}&search=${search}&tag=${tag}`;
    const result = await helpers.apiCall("GET", url);
    setList(
      result && result.data && result.data.result
        ? result.data.result.response
        : []
    );
    setTotalCounts(
      result && result.data && result.data.result
        ? result.data.result.count
        : []
    );
    setLoading(false);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleShowDeletedPost = () => {
    setIsDeletedPost(true);
    setSearch("");
    setdeletedPosts("1");
    setHidePosts("0");
  };

  const handleShowHidePost = () => {
    setIsHidePost(true);
    setSearch("");
    setdeletedPosts("0");
    setHidePosts("1");
  };

  const handleClearBtnFilter = () => {
    setIsHidePost(false);
    setIsDeletedPost(false);
    setSearch("");
    if (deletedPosts === "1") {
      setdeletedPosts("0");
    }
    if (hidePosts === "1") {
      setHidePosts("0");
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    const url = `post/list/admin?skip=${page}&limit=${limit}&deleted=${deletedPosts}&hide=${hidePosts}&search=${search}&tag=${tag}`;
    const result = await helpers.apiCall("GET", url);
    setList(
      result && result.data && result.data.result
        ? result.data.result.response
        : []
    );
    setTotalCounts(
      result && result.data && result.data.result
        ? result.data.result.count
        : []
    );
    setLoading(false);
  };

  const handleClearTagButton = async () => {
    setTag("");
  };

  const clearAllData = () => {
    setTag("");
    setSearch("");
    setdeletedPosts("0");
    setHidePosts("0");
    getListOfPost();
  };

  const handleDeletePost = (id) => {
    setModal(true);
    setDeletablePostId(id);
  };

  const handleHidePost = async (id, text) => {
    if (text !== "Hide") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f50057",
        cancelButtonColor: "#263273",
        confirmButtonText: `Yes, ${text} it!`,
      }).then(async (result) => {
        if (result.value) {
          Swal.fire(`${text}!`, `Post has been ${text}.`, "success");
          const url = `post/hide/admin/v1`;
          const result = await helpers.apiCall("POST", url, { id, reason: "" });
          console.log("result", result);
          if (result.status === 200) {
            clearAllData();
            // handleClose();
            enqueueSnackbar(`Post ${text} successfully`, {
              variant: "success",
              autoHideDuration: 3000,
            });
          } else {
            enqueueSnackbar(
              result.data ? result.data.message : result.message,
              {
                variant: "error",
                autoHideDuration: 3000,
              }
            );
          }
        }
      });
    } else {
      setHideModal(true);
      setHidePostId(id);
    }
  };

  const confirmDeleteModal = async () => {
    if (!reason) {
      alert("Please add reason first");
      return;
    }
    const url = `post/delete/admin/v1`;
    const result = await helpers.apiCall("POST", url, {
      id: deletablePostId,
      reason,
    });
    handleCloseModal();
    clearAllData();
  };

  const confirmHideModal = async () => {
    if (!hideReason) {
      alert("Please add reason first");
      return;
    }
    const url = `post/hide/admin/v1`;
    const result = await helpers.apiCall("POST", url, {
      id: hidePostId,
      reason: hideReason,
    });
    handleCloseHideModal();
    clearAllData();
  };

  return (
    <>
      <PageContainer pageheading="Posts"></PageContainer>
      <div className="row">
        <div className="col-12">
          <div className="row m-3">
            <div className="col-6">
              {tag ? (
                <h4 className="text-center mb-3">
                  <span style={{ color: "#F85476", fontSize: "22px" }}>
                    #{tag}
                  </span>{" "}
                  Posts
                </h4>
              ) : (
                <>
                  {hidePosts === "1" && <h4>Hide Posts</h4>}{" "}
                  {deletedPosts === "1" && <h4>Deleted Posts</h4>}
                </>
              )}
            </div>
            <div className="col-6">
              {tag ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleClearTagButton()}
                >
                  Clear
                </Button>
              ) : (
                <>
                  {hidePosts === "1" || deletedPosts === "1" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => handleClearBtnFilter()}
                    >
                      Back
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleShowDeletedPost()}
                      >
                        Deleted Posts
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="ml-3"
                        onClick={(e) => handleShowHidePost()}
                      >
                        Hide Posts
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="col-6">
              <Grid item xs={12} sm={12} md={12}>
                <div className="">
                  <div className="input-group serch-sec">
                    <TextField
                      size="small"
                      variant="outlined"
                      type="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control rounded"
                      placeholder="Search Post Title, Created By user name and user Id"
                      aria-label="Search"
                      aria-describedby="search-addon"
                    />
                    &nbsp;
                    <Button
                      style={{ height: "38px" }}
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                    >
                      Go
                    </Button>
                  </div>
                </div>
              </Grid>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-md-6 d-flex">
                  {/* <p className="my-auto" style={{ fontWeight: "500" }}>1 to 10 of {totalCounts} items</p> */}
                  <PaginationInfo
                    page={page}
                    limit={limit}
                    totalCounts={totalCounts}
                  />
                </div>
                <PaginationControls
                  limit={limit}
                  onPageChange={handlePageChange}
                  page={page}
                  totalCounts={totalCounts}
                />
                {/* <div className="col-md-6 d-flex pagination-btns justify-content-end align-items-center">
                  <i className="mdi mdi-arrow-left"></i>
                  <span className="active">1</span>
                  <span>2</span>
                  <span>...</span>
                  <span>6</span>
                  <i className="mdi mdi-arrow-right"></i>
                </div> */}
              </div>
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "60vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="spinner-border"
                style={{ color: "#F85476" }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <div className="row m-3">
                {list &&
                  list?.length > 0 &&
                  list?.map((post) => (
                    <div className="col-md-6 col-sm-12 mt-3" key={post?._id}>
                      <div className="post_card">
                        <div className="post_card_header">
                          <div>
                            <img
                              src={
                                post?.createdByUserDetails?.profilePic
                                  ? post.createdByUserDetails.profilePic
                                  : icon
                              }
                              alt=""
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                history.push(
                                  `/dashboard/users/${post?.createdByUserDetails?._id}`
                                )
                              }
                            />
                            <span className="ml-3">
                              {post?.createdByUserDetails?.name}
                            </span>
                          </div>
                          <div>
                            {!isHidePost && !isDeletedPost ? (
                              !post?.deleted && !post?.isSelectedInTop ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="mb-2 mr-1"
                                  onClick={(e) => {
                                    setIsSelectedPost(true);
                                    setPostId(post?._id);
                                    setPostTopMadal(true);
                                  }}
                                >
                                  Select as Top
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="mb-2 mr-1"
                                  onClick={(e) => {
                                    setIsSelectedPost(false);
                                    setPostId(post?._id);
                                    setPostTopMadal(true);
                                  }}
                                >
                                  Unselect to top
                                </Button>
                              )
                            ) : (
                              ""
                            )}
                            {!post?.deleted && (
                              <Button
                                variant="contained"
                                color="primary"
                                className="mb-2"
                                onClick={(e) => handleDeletePost(post?._id)}
                              >
                                Delete Post
                              </Button>
                            )}
                            {!post?.deleted && (
                              <Button
                                variant="contained"
                                color="primary"
                                className="ml-3 mb-2"
                                onClick={(e) =>
                                  handleHidePost(
                                    post?._id,
                                    post?.adminHide ? "UnHide" : "Hide"
                                  )
                                }
                              >
                                {post?.adminHide === true
                                  ? "UnHide Post"
                                  : "Hide Post"}
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className="post_card_content">
                          <div className="post_media">
                            <p>{post?.title}</p>
                            {/* ["message", "image", "video", "location"] */}
                            {post?.type === "image" && (
                              <img
                                onClick={(e) =>
                                  window.open(post?.src, "__blank")
                                }
                                src={post?.src}
                                alt=""
                                style={{
                                  maxHeight: "280px",
                                  width: "auto",
                                  maxWidth: "100%",
                                  height: "auto",
                                  display: "block",
                                  margin: 0,
                                  objectFit: "contain",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                            {post?.type === "video" && (
                              <video
                                controls
                                style={{
                                  maxHeight: "280px",
                                  width: "auto",
                                  maxWidth: "100%",
                                  height: "auto",
                                  display: "block",
                                  margin: 0,
                                }}
                              >
                                <source src={post?.src} />
                              </video>
                            )}
                            {post?.type === "location" && (
                              <>
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    window.open(
                                      `https://www.google.com/maps?q=${post?.lat},${post?.long}`,
                                      "__blank"
                                    )
                                  }
                                >
                                  <LocationOn /> <span>{post?.address}</span>
                                </p>
                              </>
                            )}
                          </div>
                          <div className="post_hashtags">
                            {post?.tags &&
                              post?.tags?.length > 0 &&
                              post?.tags?.map((tag) => (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => setTag(tag)}
                                >
                                  #{tag}
                                </span>
                              ))}
                          </div>
                          <div className="d-flex interaction-action">
                            <div className="d-flex flex-column align-items-center">
                              <span>
                                {post?.totalZems ? post.totalZems : 0}
                              </span>
                              <span
                                style={{ fontSize: "28px" }}
                                className="mdi mdi-diamond-outline"
                              ></span>
                              {/* <span>Views</span> */}
                            </div>
                            <div
                              onClick={() => {
                                setSelectedPostLikeData(post?.postLikes);
                                setLikeModal(!likeModal);
                              }}
                              style={{ cursor: "pointer" }}
                              className="d-flex flex-column align-items-center"
                            >
                              <span>{post?.postLikes?.length}</span>
                              <span
                                style={{ fontSize: "28px" }}
                                className="mdi mdi-thumb-up-outline"
                              ></span>
                              {/* <span>Like</span> */}
                            </div>
                            <div
                              onClick={() => {
                                setSelectedPostCommentData(post?.postComments);
                                setCommentModal(!commentModal);
                              }}
                              style={{ cursor: "pointer" }}
                              className="d-flex flex-column align-items-center"
                            >
                              <span>{post?.postComments?.length}</span>
                              <span
                                style={{ fontSize: "28px" }}
                                className="mdi mdi-comment-text-outline"
                              ></span>
                              {/* <span>Comment</span> */}
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <span>{post?.share}</span>
                              <span
                                style={{ fontSize: "28px" }}
                                className="mdi mdi-share-outline"
                              ></span>
                              {/* <span>Share</span> */}
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <span>{post?.bookmarkCounts}</span>
                              <span
                                style={{ fontSize: "28px" }}
                                className="mdi mdi-bookmark-outline"
                              ></span>
                              {/* <span>Bookmark</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
      <SelectTopPostModel
        setPostTopMadal={setPostTopMadal}
        modal={postTopMadal}
        isSelectedPost={isSelectedPost}
        postId={postId}
        getListOfPost={getListOfPost}
      />
      <LikeModal
        likeModal={likeModal}
        handleCloseLikeModal={handleCloseLikeModal}
        data={selectedPostLikeData}
      />
      <CommentModal
        commentModal={commentModal}
        handleCloseCommentModal={handleCloseCommentModal}
        data={selectedPostCommentData}
      />
      <DeleteReson
        handleCloseModal={handleCloseModal}
        handleDelete={confirmDeleteModal}
        modal={modal}
        reason={reason}
        setReason={setReason}
      />
      <HideReson
        handleCloseModal={handleCloseHideModal}
        handleHide={confirmHideModal}
        modal={hideModal}
        reason={hideReason}
        setReason={setHideReason}
      />
    </>
  );
};

export default Posts;
