import React, {useState} from "react";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {IconButton, Button} from "@material-ui/core";
import yellow from "@material-ui/core/colors/yellow";
import {Link} from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import SearchLayoutGender from "../../layouts/SearchLayoutGender";
import VisibilityIcon from "@material-ui/icons/Visibility";
import user from "../../assets/images/faces-clipart/pic-1.png";
import { Edit } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: theme.spacing(32),
            height: theme.spacing(16),
        },
    },
    yellowPaper: {
        backgroundColor: yellow[300],
    },
    customBorder: {
        border: `3px solid ${yellow[200]}`,
    },
    customBorderRadius: {
        borderRadius: 10,
    },
}));

const sortIcon = <ArrowDownward/>;

const UsersList = ({
                       userList,
                       deleteGifts,
                       BlockUser,
                       setOpen,
                       open,
                       handleClose,
                       setOpenBlock,
                       openBlock,
                       handleCloseBlock,
                   }) => {
    const classes = useStyles();
    // const [filterText, setFilterText] = useState("");
    const [filterText, setFilterText] = useState({
        query: "",
        queryType: { gender: "", subscribe: "" },
      });

//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClickOpenBlock = () => {
//     setOpenBlock(true);
//   };
    // const filteredItems =
    //     userList && userList.result
    //         ? userList.result.filter((item) => {
    //             let search = filterText.toLowerCase();
    //             let IDs = item.userId && item.userId.toLowerCase().includes(search);
    //             let name = item.name && item.name.toLowerCase().includes(search);
    //             // let kyc = item.isProfilePicVerified && item.isProfilePicVerified.toLowerCase().includes(search);
    //             return IDs || name;
    //         })
    //         : "";

    const filteredItems =
    userList && userList.result
      ? userList.result.filter((item) => {
          let filterData = { gender: true, subscribe: true };
          let search = filterText.query.toLowerCase();
  
          let IDs = item.userId && item.userId.toLowerCase().includes(search);
          let name = item.name && item.name.toLowerCase().includes(search);
          // Exact match for mobile and email with the same search field
          let mobile = item.mobile === filterText.query; // Assuming mobile is stored in exact format as searched
          let email = item.email.toLowerCase() === search; // Case-insensitive match for email
  
          if (filterText.queryType.gender) {
            filterData["gender"] =
              item.gender &&
              item.gender.toLowerCase().startsWith(filterText.queryType.gender);
          }
          if (filterText.queryType.subscribe) {
            filterData["subscribe"] =
              item.isProfilePicVerified.toString() ===
              filterText.queryType.subscribe
                ? true
                : false;
          }
  
          // Return true if any of the IDs, name, mobile, or email conditions are met
          // and all other filter conditions are true
          return (IDs || name || mobile || email) && filterData.gender && filterData.subscribe;
        })
      : "";

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
         <>   <div style={{marginTop: "15px", width: "100%"}} className="test">
         <SearchLayoutGender
             onFilter={setFilterText}
             filteredItems={filteredItems}
             placeHolderText="Search by Name or User Id or Email or Phone"
         />
     </div> 
    </>
        );
    }, [setFilterText]);

    const headingAdmin = [
        {
            name: "User Id",
            selector: (row) => row.userId,
            wrap: true,
            sortable: true,
        },
        {
            name: "Image",
            wrap: true,
            sortable: true,
            cell: (userList) => (
                <div>
                    <img
                        style={{width: "60px", height: "60px", borderRadius: "50%"}}
                        src={userList && userList.profilePic ? userList.profilePic : user}
                        alt="Logo"
                    />
                </div>
            ),
        },
        {
            name: "Name",
            selector: (row) => row.name,
            wrap: true,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            wrap: true,
            sortable: true,
        },
        {
            name: "Subscribed",
            wrap: true,
            sortable: true,
            cell: (userList) => (
                <div>
                    <p
                        style={{fontSize: "13px"}}
                        className={
                            userList && userList.isSubscribed
                                ? "badge badge-success"
                                : "badge badge-danger"
                        }
                    >
                        {userList && userList.isSubscribed
                            ? userList.isSubscribed
                                ? "Active"
                                : ""
                            : " IN Active"}
                    </p>
                </div>
            ),
        },
        {
            name: "KYC Status",
            wrap: true,
            sortable: true,
            cell: (userList) => (
                <div>
                    <p
                        style={{fontSize: "13px"}}
                        className={
                            userList && userList.isProfilePicVerified
                                ? "badge badge-success"
                                : "badge badge-danger"
                        }
                    >
                        {userList && userList.isProfilePicVerified
                            ? userList.isProfilePicVerified
                                ? "Verified"
                                : ""
                            : " Not Verified"}
                    </p>
                </div>
            ),
        },
        {
            name: "Created At",
            selector: (row) =>
                moment(new Date(row.createdAt)).format("DD/MM/YYYY hh:mm"),
            wrap: true,
            sortable: true,
        },
        {
            name: "Action",
            wrap: true,
            center: true,
            cell: (userList) => (
                <div>
                    <Button
                        color={userList && userList.isBlocked ? "primary" : "secondary"}
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            BlockUser(userList._id);
                        }}
                    >
                        {userList && userList.isBlocked ? "UnBlock" : "Block"}
                    </Button>
                </div>
            ),
        },
        {
            name: "",
            wrap: true,
            center: true,
            cell: (userList) => (
                <div>
                    <Link to={"/dashboard/users/" + userList._id}>
                        <VisibilityIcon/>
                    </Link>
                    <Link to={`/dashboard/users/update/details/${userList._id}`} className="ml-3">
                        <Edit/>
                    </Link>

                    <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                            deleteGifts(userList._id);
                        }}
                    >
                        <DeleteIcon color="primary"/>
                    </IconButton>
                </div>
            ),
        },
    ];



    return (
       <> 
       <Paper className={classes.customBorderRadius} elevation={15}>
           <DataTable
               columns={headingAdmin}
               data={filteredItems}
               defaultSortField="createdAt"
               pagination
               paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
               sortIcon={sortIcon}
               subHeader
               noHeader={true}
               subHeaderWrap={false}
               subHeaderAlign="right"
               subHeaderComponent={subHeaderComponentMemo}
               striped={true}
               highlightOnHover={true}
               responsive={true}
           />
       </Paper></>
    );
};
export default UsersList;
