import React, { useEffect, useState, useCallback } from "react";
import { helpers } from "../../helper/helper";
import { ProgressBar } from "react-bootstrap";

const Dashboard = () => {
  const [dashboard, setDashboard] = useState();
  const [userList, setUserList] = useState();

  const DashboardData = useCallback(async () => {
    const url = "devices";
    const result = await helpers.apiCall("GET", url);
    setDashboard(result.data.result);
  }, [setDashboard]);
  const UsersData = useCallback(async () => {
    const url = "users";
    const result = await helpers.apiCall("GET", url);
    setUserList(result.data);
  }, [setUserList]);

  useEffect(() => {
    DashboardData();
    UsersData();
  }, [DashboardData, UsersData]);

  const filterGender = (gender) => {
    const data =
      userList && userList.result
        ? userList.result.filter((item) => {
            const male = item.gender && item.gender.toLowerCase() === gender;
            return male;
          })
        : "0";
    return data;
  };
  const filterVerified = (gender) => {
    const data =
      userList && userList.result
        ? userList.result.filter((item) => {
            const Verified =
              item.isProfilePicVerified && item.isProfilePicVerified;
            return Verified;
          })
        : "0";
    return data;
  };

  return (
    <div>
      <div className="row proBanner">
        <div className="col-12"></div>
      </div>
      <div className="row page-title-header mt-4 text-center">
        <div className="col-12">
          <div className="">
            <h2 className="text-center text-denger">Dashboard</h2>
          </div>
        </div>
      </div>
      <hr />
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-danger mr-2" />
                  <p className="mb-0">Total Active Devices</p>
                </div>
                <h4 className="font-weight-semibold">
                  {dashboard && dashboard.installed ? dashboard.installed : "0"}
                </h4>
                <ProgressBar
                  variant="danger"
                  now={
                    dashboard && dashboard.installed ? dashboard.installed : "0"
                  }
                />
              </div>

              <div className="col-md-4 mt-4 mt-md-0">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-success mr-2" />
                  <p className="mb-0">Total Registered Users</p>
                </div>
                <h4 className="font-weight-semibold">
                  {dashboard && dashboard.activeUser
                    ? dashboard.activeUser
                    : "0"}
                </h4>
                <ProgressBar
                  variant="success"
                  now={
                    dashboard && dashboard.activeUser
                      ? dashboard.activeUser
                      : "0"
                  }
                />
              </div>

              <div className="col-md-4 mt-4 mt-md-0">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-success mr-2" />
                  <p className="mb-0">Total Subscribed Users</p>
                </div>
                <h4 className="font-weight-semibold">
                  {dashboard && dashboard.subscribedUsers
                    ? dashboard.subscribedUsers
                    : "0"}
                </h4>
                <ProgressBar
                  variant="success"
                  now={
                    dashboard && dashboard.subscribedUsers
                      ? dashboard.subscribedUsers
                      : "0"
                  }
                />
              </div>
            </div>
            <hr className="mt-4" />
            <div className="row ">
              <div className="col-md-4 mt-4 mt-md-0">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-success mr-2" />
                  <p className="mb-0">Total Male User </p>
                </div>
                <h4 className="font-weight-semibold">
                  {filterGender("male").length}
                </h4>
                <ProgressBar
                  variant="success"
                  now={filterGender("male").length}
                />
              </div>
              <div className="col-md-4 mt-4 mt-md-0">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-success mr-2" />
                  <p className="mb-0">Total Female User</p>
                </div>
                <h4 className="font-weight-semibold">
                  {filterGender("female").length}
                </h4>
                <ProgressBar
                  variant="success"
                  now={filterGender("female").length}
                />
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-danger mr-2" />
                  <p className="mb-0">Total Other</p>
                </div>
                <h4 className="font-weight-semibold">
                  {filterGender("other").length}
                </h4>
                <ProgressBar
                  variant="danger"
                  now={filterGender("other").length}
                />
              </div>
            </div>
            <hr className="mt-4" />
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-danger mr-2" />
                  <p className="mb-0">Total Verified User</p>
                </div>
                <h4 className="font-weight-semibold">
                  {filterVerified().length}
                </h4>
                <ProgressBar variant="danger" now={filterVerified().length} />
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-success mr-2" />
                  <p className="mb-0">Total Posts</p>
                </div>
                <h4 className="font-weight-semibold">
                  {dashboard?.postCount}
                </h4>
                <ProgressBar variant="success" now={dashboard?.postCount} />
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-success mr-2" />
                  <p className="mb-0">Total Shorts</p>
                </div>
                <h4 className="font-weight-semibold">
                  {dashboard?.reelCount}
                </h4>
                <ProgressBar variant="success" now={dashboard?.reelCount} />
              </div>
            </div>
            <hr className="mt-4" />
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-success mr-2" />
                  <p className="mb-0">Total Posts Hash Tags</p>
                </div>
                <h4 className="font-weight-semibold">
                  {dashboard?.postHashTag}
                </h4>
                <ProgressBar variant="success" now={dashboard?.postHashTag} />
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-success mr-2" />
                  <p className="mb-0">Total Posts Videos</p>
                </div>
                <h4 className="font-weight-semibold">
                  {dashboard?.postVideos}
                </h4>
                <ProgressBar variant="success" now={dashboard?.postVideos} />
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-success mr-2" />
                  <p className="mb-0">Total Shorts Hash Tags</p>
                </div>
                <h4 className="font-weight-semibold">
                  {dashboard?.reelHashTag}
                </h4>
                <ProgressBar variant="success" now={dashboard?.reelHashTag} />
              </div>
            </div>
            <hr className="mt-4" />
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-success mr-2" />
                  <p className="mb-0">Total Shorts Categories</p>
                </div>
                <h4 className="font-weight-semibold">
                  {dashboard?.reelCategories}
                </h4>
                <ProgressBar variant="success" now={dashboard?.reelCategories} />
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center pb-2">
                  <div className="dot-indicator bg-success mr-2" />
                  <p className="mb-0">Total Shorts Music</p>
                </div>
                <h4 className="font-weight-semibold">
                  {dashboard?.reelSong}
                </h4>
                <ProgressBar variant="success" now={dashboard?.reelSong} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <User />*/}
    </div>
  );
};

export default Dashboard;
