import React from "react";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Button from "@material-ui/core/Button";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Grid } from "@mui/material";

const SearchLayout = (props) => {
  const { onFilter, placeHolderText, filteredItems } = props;
  const [query, setQuery] = React.useState("");
  const [queryType, setQueryTypt] = React.useState({
    gender: "",
    subscribe: "",
  });

  const handleSearch = async (e) => {
    e.preventDefault();
    onFilter({ query, queryType });
  };

   const downloadCsvData = (data, filename) => {
    let passingData = data?.map((d) => {
      return {
        name: d?.name,
        email: d?.email,
        mobile: d?.mobile,
        gender: d?.gender,
        district: d?.district
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(passingData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, `${filename}.xlsx`);
  };

  return (
    <form onSubmit={handleSearch} sx={{width: '100%'}}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        spacing={1}
      >
        
        <Grid item xs={6} sm={6} md={3}>
          <FormControl variant="outlined"  size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
               labelId="demo-simple-select-filled-label"
               id="demo-simple-select-filled"
              value={queryType.gender}
              label="gender"
              variant="outlined" 
              onChange={(e) =>
                setQueryTypt({ ...queryType, gender: e.target.value })
              }
            >
              <MenuItem value={''}>--Select Gender--</MenuItem>
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"female"}>Female</MenuItem>
              <MenuItem value={"other"}>Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <FormControl variant="outlined"  size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">Kyc Status</InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              variant="outlined" 
              id="demo-simple-select"
              value={queryType.subscribe}
              
              label="subscribe"
              onChange={(e) =>
                setQueryTypt({ ...queryType, subscribe: e.target.value })
              }
            >
              <MenuItem value={''}>--Select Kyc Status--</MenuItem>
              <MenuItem value={'true'}>Verified</MenuItem>
              <MenuItem value={'false'}>Not Verified</MenuItem>
              
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={9} sm={9}  md={3}>
          <div className="">
            <div className="input-group serch-sec">
              <TextField
                size="small"
                variant="outlined"
                type="search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="form-control rounded"
                placeholder={placeHolderText}
                aria-label="Search"
                aria-describedby="search-addon"
              />
              &nbsp;
              <Button
                style={{ height: "38px" }}
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                Go
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} style={{textAlign:"end"}}>
              <Button
                style={{ height: "38px",textAlign:"right" }}
                variant="contained"
                color="primary"
                onClick={(e) => downloadCsvData(filteredItems, "users")}
              >
               Export XLS
              </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchLayout;
