import { Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { helpers } from "../../helper/helper";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Label } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "91%",
  },
}));

const UpdatePoster = () => {

  const [data, setData] = useState({
    image: "",
    link: "",
    isDeleted: false
  });
  const classes = useStyles();
  const history = useHistory();
  const id = useParams().id;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getPosterDetails();
  }, [id]);

  const getPosterDetails = async () => {
    const url = `poster/get/${id}/v1`;
    const result = await helpers.apiCall("GET", url);
    setData(result && result.data && result.data.result && result.data.result.data);
  };

  const handleUpdatePoster = async () => {
    const formData = new FormData();
    if (data.image && data.image[0]) {
      formData.append("poster", data.image[0]);
    } else {
      formData.append("poster", "");
    }
    formData.append("link", data.link);
    formData.append("isDeleted", data.isDeleted);
    const url = `poster/update/${id}/v1`;
    const result = await helpers.apiCallForm("POST", url, formData);
    if (result.status === 200) {
      enqueueSnackbar("Updated successfully", {
        variant: 'success',
        autoHideDuration: 3000,
      });
      history.push("/dashboard/poster")
    }
    else {
      enqueueSnackbar(result.data ? result.data.result.message : result.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleRemoveImg = () => {
    setData({ ...data, image: "" });
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper} square>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Update Poster</h4>
            <Grid container spacing={3}>
              <Grid sm={8} xs={12} item>
                {data.image && (typeof data.image) == "object" ? <div style={{ position: "relative" }}><img src={URL.createObjectURL(data.image[0])} style={{ width: "200px", marginTop: "20px" }} /><button onClick={e => handleRemoveImg()} style={{ position: "absolute", top: "20px", right: "20px" }}>Remove Image</button></div> :
                  data.image &&
                  <div style={{ position: "relative" }}><img src={data.image} style={{ width: "200px", marginTop: "20px" }} /><button onClick={e => handleRemoveImg()} style={{ position: "absolute", top: "20px", right: "20px" }}>Remove Image</button></div>}
                <InputLabel style={{ marginTop: "10px" }}>Select Poster</InputLabel>
                <TextField
                  type="file"
                  fullWidth
                  variant="outlined"
                  onChange={e => {
                    setData({ ...data, image: e.target.files });
                  }}
                />
              </Grid>
              <Grid sm={8} xs={12} item>
                <InputLabel style={{ marginTop: "10px" }}>Enter Link</InputLabel>
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  variant="outlined"
                  id="Enter Link"
                  name="Enter Link"
                  label="Link"
                  value={data.link}
                  onChange={e => setData({ ...data, link: e.target.value })}
                  multiline
                  rows={4}  // Adjust the number of rows as needed
                />
              </Grid>
              <Grid sm={8} xs={12} item>
                <FormGroup>
                  <InputLabel style={{ marginTop: "10px" }}>Poster Status</InputLabel>
                  <FormControlLabel control={<Checkbox checked={data.isDeleted} onChange={e => setData({ ...data, isDeleted: !data.isDeleted })} />} label="Is Hide" />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid align="right" item style={{ marginTop: "10px" }}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.submit}
                  color="primary"
                  onClick={e => handleUpdatePoster()}
                >
                  Update
                </Button>
              </div>
            </Grid>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  )
};

export default UpdatePoster;