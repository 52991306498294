import React from "react";
import PageContainer from "../../layouts/PageContainer";
import CategoryList from "./CategoryList";

const ShortsCategory = () => {
  return (
    <React.Fragment>
      <PageContainer pageheading="SHORTS CATEGORY">
        <CategoryList />
      </PageContainer>
    </React.Fragment>
  )
};

export default ShortsCategory;