import React from "react";
import PageContainer from "../../layouts/PageContainer";
import List from "./List";
import ResolveList from "./ResolveList";

const Poster = () => {
  return (
    <React.Fragment>
      <PageContainer pageheading="REPORT POST">
        <List />
      </PageContainer>
      <PageContainer pageheading="RESOLVED REPORTS">
        <ResolveList />
      </PageContainer>
    </React.Fragment>
  )
};

export default Poster;