import React from 'react';

const PaginationControls = ({ totalCounts, limit, page, onPageChange }) => {
  const totalPages = Math.ceil(totalCounts / limit);
  const maxPageNumberButtons = 5; // for example, show 5 page numbers at a time
  let startPage, endPage;

  if (totalPages <= maxPageNumberButtons) {
    startPage = 1;
    endPage = totalPages;
  } else {
    const maxPagesBeforeCurrentPage = Math.floor(maxPageNumberButtons / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPageNumberButtons / 2) - 1;
    if (page <= maxPagesBeforeCurrentPage) {
      startPage = 1;
      endPage = maxPageNumberButtons;
    } else if (page + maxPagesAfterCurrentPage >= totalPages) {
      startPage = totalPages - maxPageNumberButtons + 1;
      endPage = totalPages;
    } else {
      startPage = page - maxPagesBeforeCurrentPage;
      endPage = page + maxPagesAfterCurrentPage;
    }
  }

  const range = (start, end) => Array.from({ length: end - start + 1 }, (_, i) => start + i);

  return (
    <div className="col-md-6 d-flex pagination-btns justify-content-end align-items-center">
      <i className={`mdi mdi-arrow-left ${page === 1 ? "disabled" : ""}`} onClick={() => page > 1 && onPageChange(page - 1)}></i>
      {startPage > 1 && <span onClick={() => onPageChange(1)}>1</span>}
      {startPage > 2 && <span>...</span>}
      {range(startPage, endPage).map(p => (
        <span key={p} className={page === p ? "active" : ""} onClick={() => onPageChange(p)}>{p}</span>
      ))}
      {endPage < totalPages - 1 && <span>...</span>}
      {endPage < totalPages && <span onClick={() => onPageChange(totalPages)}>{totalPages}</span>}
      <i className={`mdi mdi-arrow-right ${page === totalPages ? "disabled" : ""}`} onClick={() => page < totalPages && onPageChange(page + 1)}></i>
    </div>
  );
};


export default PaginationControls;