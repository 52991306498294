import React, {useState, useCallback, useEffect} from "react";
import { useHistory } from "react-router-dom";
import {helpers} from "../../helper/helper";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import SearchLayout from "../../layouts/SearchLayout";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useSnackbar} from "notistack";
import user from "../../assets/images/faces-clipart/pic-1.png";

const sortIcon = <ArrowDownward/>;

const UsersLists = ({userList}) => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [filterText, setFilterText] = useState("");
    const [open, setOpen] = React.useState(false);
    const [id, setId] = React.useState('');
    const [subList, setSubList] = useState();
    const [subId, setSubId] = React.useState('');
    const [giftsList, setGiftsList] = useState();
    const [giftsId, setGiftsId] = React.useState('');

    const handleChange = (event) => {
        setSubId(event.target.value);
    };
    const handleChangeGifts = (event) => {
        setGiftsId(event.target.value);
    };

    const SubData = useCallback(async () => {
        const url = "plans";
        const result = await helpers.apiCall("GET", url);
        setSubList(
            result && result.data && result.data.result ? result.data.result : []
        );
    }, [setSubList]);
    const GiftsData = useCallback(async () => {
        const url = "gifts";
        const result = await helpers.apiCall("GET", url);
        setGiftsList(
            result && result.data && result.data.result ? result.data.result : []
        );
    }, [setGiftsList]);

    useEffect(() => {
        SubData();
        GiftsData()
    }, [SubData, GiftsData]);

    const handleClickOpen = (value) => {
        setOpen(true);
        setId(value)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const filteredItems = userList && userList.result ? userList.result.filter((item) => {
        let search = filterText.toLowerCase();
        let IDs = item.userId && item.userId.toLowerCase().includes(search);
        let name = item.name && item.name.toLowerCase().includes(search);
        return IDs || name

    }) : '';

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <React.Fragment>
                <SearchLayout onFilter={setFilterText} placeHolderText="Search by Name / User Id"/>
                <Button variant="contained" color="primary"
                        style={{marginLeft: '20px', width: '12%', float: 'right', height: '38px'}} onClick={e => history.push('/dashboard/giveaway/list')}>Show History</Button>
            </React.Fragment>
        );
    }, [setFilterText, history]);


    const handleSubmit = async (values, action) => {
        let formData = new FormData();
        formData.append("planId", subId || '');
        formData.append("userId", id || '');
        const Data = {
            giftId: giftsId || '',
            planId: subId || '',
            userId: id || ''
        };
        const url = "users/give-away";
        const result = await helpers.apiCall("POST", url, Data);
        if (result.status === 200) {
            enqueueSnackbar("Give away updated successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
            
            setSubId();
            setGiftsId();
            handleClose();

        }
        else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };

    const headingAdmin = [
        {
            name: "User Id",
            selector: "userId",
            wrap: true,
            sortable: true,

        }, {
            name: "Name",
            selector: "name",
            wrap: true,
            sortable: true,
        },
        {
            name: "Profile Image",
            selector: "isSubscribed",
            wrap: true,
            sortable: true,
            cell: (userList) => (<div>
                <img
                        style={{width: "60px", height: "60px", borderRadius: "50%"}}
                        src={userList && userList.profilePic ? userList.profilePic : user}
                        alt="Logo"
                    />
            </div>)
        }, {
            name: "Email",
            selector: "email",
            wrap: true,
            sortable: true,
        },
        {
            name: "Gender",
            selector: "gender",
            wrap: true,
            sortable: true,
        },
        {
            name: "Matches",
            selector: "matchCount",
            wrap: true,
            sortable: true,
        },
        {
            name: "Subscribed",
            selector: "isSubscribed",
            wrap: true,
            sortable: true,
            cell: (userList) => (<div>
                <p className={userList && userList.isSubscribed ? "badge badge-success" : "badge badge-danger"}>{userList && userList.isSubscribed ? (userList.isSubscribed ? "Active" : '') : ' IN Active'}</p>
            </div>)
        }, {

            name: " ",
            selector: "_id",
            right: true,
            cell: (userList) => (
                <Button variant="contained" color="primary"
                        style={{width: '100%'}} onClick={e => handleClickOpen(userList._id)}>GiveAway</Button>
            )
        }
    ];
    return (
        <React.Fragment>

            <DataTable
                columns={headingAdmin}
                data={filteredItems}
                defaultSortField="name"
                pagination
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                sortIcon={sortIcon}
                subHeader
                noHeader={true}
                subHeaderWrap={false}
                subHeaderAlign="right"
                subHeaderComponent={[subHeaderComponentMemo]}
                striped={true}
                highlightOnHover={true}
                responsive={true}

            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle style={{textAlign: "center", padding: "20px"}}
                             id="alert-dialog-title">{"Please select"}</DialogTitle>
                <DialogContent style={{textAlign: "center", padding: "40px"}}>
                    <FormControl style={{width: '110px'}}>
                        <InputLabel id="demo-simple-select-label">Plans</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={subId}
                            onChange={handleChange}
                        >
                            {subList ? subList.map((data) => (
                                <MenuItem value={data._id}>{data.name}</MenuItem>
                            )) : ''}
                        </Select>
                    </FormControl> &nbsp;
                    <FormControl style={{width: '110px'}}>
                        <TextField id="standard-basic" value={giftsId} onChange={handleChangeGifts} label="Zoles" variant="standard" type='number' />


                        {/* <InputLabel id="demo-simple-select-label">Zoles</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={giftsId}
                            onChange={handleChangeGifts}
                        >
                            {giftsList ? giftsList.map((data) => (

                                <MenuItem value={data._id}>{data.zole} zoles</MenuItem>

                            )) : ''} 
                        </Select>*/}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" autoFocus>
                        Give
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    )
};

export default UsersLists;