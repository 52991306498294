import React, { useEffect, useState } from "react";
import { Button, IconButton, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "react-data-table-component";
import moment from "moment";
import { ArrowDownward } from "@material-ui/icons";
import yellow from "@material-ui/core/colors/yellow";
import { Link } from "react-router-dom";
import { helpers } from "../../helper/helper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useSnackbar } from "notistack";

const sortIcon = <ArrowDownward />;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(32),
      height: theme.spacing(16),
    },
  },
  yellowPaper: {
    backgroundColor: yellow[300],
  },
  customBorder: {
    border: `3px solid ${yellow[200]}`,
  },
  customBorderRadius: {
    borderRadius: 10,
  },
  btn: {
    margin: theme.spacing(4)
  }
}));

const HistoryList = ({ month, year }) => {

  const [list, setList] = useState([]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      name: "User",
      selector: (row) => (
        <>
          <img src={row?.userData?.profilePic} alt="User Profile" style={{ width: "50px", height: "50px", marginRight: "10px" }} />
          <span>{row?.userData?.name}</span>
        </>
      ),
      wrap: true,
      sortable: true,
    },
    {
      name: "Month Name",
      selector: (row) => (
        <>
          <img src={row?.winningPrize?.image} alt="User Profile" style={{ width: "50px", height: "50px", marginRight: "10px" }} />
          <span>{row?.winningPrize?.name}</span>
        </>
      ),
      wrap: true,
      sortable: true,
    },
    {
      name: "Popularity",
      selector: (row) => row.popularity,
      wrap: true,
      sortable: true,
    }
  ];

  useEffect(() => {
    getListOfRewards();
  }, []);

  const getListOfRewards = async () => {
    const url = `popularity/reward/history/${month}/${year}/v1`;
    const result = await helpers.apiCall("GET", url);
    setList(result && result.data && result.data.result ? result.data.result.response.winnerUsers : []);
  };

  return (
    <React.Fragment>
      <h3 className="text-center mt-3 mb-3 text-muted">REWARD HISTORY</h3>
      <Paper className={classes.customBorderRadius} elevation={15} style={{ marginTop: "20px" }}>
        <DataTable
          columns={columns}
          data={list}
          defaultSortField="createdAt"
          pagination
          paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
          sortIcon={sortIcon}
          noHeader={true}
          striped={true}
          highlightOnHover={true}
          responsive={true}
        />
      </Paper>
    </React.Fragment>
  )
};

export default HistoryList;