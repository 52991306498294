import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { helpers } from "../../helper/helper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Swal from "sweetalert2";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";
import Loader from "../../layouts/Loader";
import PageContainer from "../../layouts/PageContainer";

const sortIcon = <ArrowDownward />;

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto",
    marginTop: 2,
  },
  table: {
    minWidth: 650,
  },
});

export default function GameHistoryList() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [gameList, setGameList] = useState();

  const [count, setCount] = React.useState();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e, index) => {
    setCount(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const GameData = useCallback(async () => {
    const url = "game/gameHistory";
    const result = await helpers.apiCall("post", url);
    setGameList(
      result && result.data && result.data.result ? result.data.result : []
    );
  }, [setGameList]);

  useEffect(() => {
    GameData();
  }, [GameData]);

  const deleteGifts = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f50057",
      cancelButtonColor: "#263273",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        const url = "game/deleteGame";
        const Data = {
          gameId: id,
        };
        const result = await helpers.apiCall("POST", url, Data);
        if (result.status === 200) {
          GameData();
          handleClose();
          enqueueSnackbar("Game Deleted successfully", {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(result.data ? result.data.message : result.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      }
    });
  };

  const heading = [
    {
      name: "Plan Name",
      selector: "name",
      wrap: true,
      sortable: true,
      cell: (gameList) => <p>{gameList.game.gameType}</p>,
    },
    {
      name: "User 1st",
      selector: "name",
      wrap: true,
      sortable: true,
      cell: (gameList) => <p>{gameList?.game?.firstUser?.name}</p>,
    },
    {
      name: "User 2nd",
      selector: "name",
      wrap: true,
      sortable: true,
      cell: (gameList) => (
        <p>
          {gameList.game &&
          gameList.game.secUser &&
          gameList.game.secUser &&
          gameList.game.secUser.name
            ? gameList.game.secUser.name
            : ""}
        </p>
      ),
    },
    {
      name: "Winner",
      selector: "name",
      wrap: true,
      sortable: true,
      cell: (gameList) => (
        <p>
          {" "}
          {gameList.game && gameList.game.winnerUser
            ? gameList.game.firstUser?._id === gameList.game.winnerUser
              ? gameList.game.firstUser.name
              : gameList.game.secUser?._id === gameList.game.winnerUser
              ? gameList.game.secUser.name
              : ""
            : ""}
        </p>
      ),
    },
    {
      name: "Zole",
      selector: "name",
      wrap: true,
      sortable: true,
      cell: (gameList) => (
        <p>
          {gameList.game && gameList.game.zoleWin ? gameList.game.zoleWin : ""}
        </p>
      ),
    },
    {
      name: "",
      selector: "name",
      wrap: true,
      sortable: true,
      cell: (gameList, index) => (
        <>
          <VisibilityIcon onClick={(e) => handleClickOpen(e, index)} />

          <DeleteIcon
            color="primary"
            onClick={() => {
              deleteGifts(gameList.game._id);
            }}
          />
        </>
      ),
    },
  ];
  return (
    <PageContainer pageheading="GAME HISTORY">

    <Paper className={classes.root}>
      {gameList ? (
        <DataTable
          columns={heading}
          noHeader={true}
          data={gameList}
          pagination
          paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
          sortIcon={sortIcon}
          striped={true}
          highlightOnHover={true}
          responsive={true}
        />
      ) : (
        <Loader />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"List"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table
              style={{
                textAlign: "center",
                widht: "100%",
              }}
            >
              <TableHead style={{ backgroundColor: "blue" }}>
                <TableRow>
                  <TableCell style={{ color: "white" }}>Round Name</TableCell>
                  <TableCell style={{ color: "white" }} align="right">
                    User 1st
                  </TableCell>
                  <TableCell style={{ color: "white" }} align="right">
                    User 2nd
                  </TableCell>
                  <TableCell style={{ color: "white" }} align="right">
                    Winner
                  </TableCell>
                </TableRow>
              </TableHead>

              {gameList && gameList.length && gameList[count]
                ? gameList[count].rounds.map((data) => (
                    <TableBody>
                      <>
                        <TableCell component="th" scope="row">
                          {data && data.roundName ? data.roundName : ""}
                        </TableCell>
                        <TableCell align="right">
                          {data.firstUser && data.firstUser.name
                            ? data.firstUser.name
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          {data.secUser && data.secUser.name
                            ? data.secUser.name
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          <p>
                            {" "}
                            {data && data.winnerUser
                              ? data.firstUser._id === data.winnerUser
                                ? data.firstUser.name
                                : data.secUser._id === data.winnerUser
                                ? data.secUser.name
                                : ""
                              : ""}
                          </p>
                          {/* {data && data.winnerUser ? data.winnerUser : "-"} */}
                        </TableCell>
                      </>
                    </TableBody>
                  ))
                : ""}
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            back
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
    </PageContainer>
  );
}
