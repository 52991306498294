import React from "react";
import { Button, IconButton, LinearProgress, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";

const sortIcon = <ArrowDownward />;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    table: {
      minWidth: 650,
    },
  
  },
  btn: {
    margin: theme.spacing(4)
  }
}));

const PlanList = ({
  planList,
  editPlan,
  deletePlan,
  check,
  setOpen,
  open,
  handleClose,
  singlePlanById,
}) => {
  const classes = useStyles();

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

  const heading = [
    {
      name: "Sr. No",
      wrap: true,
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Plan Name",
      selector: "name",
      wrap: true,
      sortable: true,
    },
    {
      name: "Amount",

      wrap: true,
      sortable: true,
      cell: (planList) => <p>{"Rs " + planList.amount}</p>,
    },
    {
      name: "Zole's",
      selector: "bonus",
      wrap: true,
      sortable: true,
    },
    {
      name: "Action",
      wrap: true,
      center: true,
      cell: (planList) => (
        <div>
          <Link to={"/dashboard/create-plans/" + planList._id}>
            <IconButton
              edge="end"
              aria-label="edit"
              // onClick={() => {
              //     singlePlanById(planList._id);
              // }}
            >
              <EditIcon color="primary" />
            </IconButton>
          </Link>

          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => {
              deletePlan(planList._id);
            }}
          >
            <DeleteIcon color="primary" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      {!planList ? (
        <LinearProgress color="primary" />
      ) : (
        <Paper className={classes.paper} square>
          <Link to="/dashboard/create-plans">
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
            >
              Add Plan
            </Button>
          </Link>

          {check ? (
            <DataTable
              columns={heading}
              noHeader={true}
              data={planList}
              pagination
              paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
              sortIcon={sortIcon}
              striped={true}
              highlightOnHover={true}
              responsive={true}
            />
          ) : (
            ""
          )}
        </Paper>
      )}
    </div>
  );
};

export default PlanList;
