import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Dropzone from "../../../layouts/Dropzone/Dropzone"
import {Grid, TextField, FormHelperText} from "@material-ui/core";

const CreateBannerForm = ({formik, image, setImage, handleChanges, types , handleChangeTitle , title}) => {

    const {handleChange, values, touched, errors} = formik;

    return (
        <Grid container spacing={3}>
            {/* <Grid sm={6} xs={12} item>
                <FormControl style={{width: '100%'}}>
                    <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
                    <Select
                    variant="filled"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.type ? values.type : types}
                        onChange={handleChanges}
                        error={touched.type && Boolean(errors.type)}
                        helperText={touched.type && errors.type}
                    >
                        <MenuItem value={"3 Line Banner"}>Line Banner</MenuItem>
                        <MenuItem value={"Notification Banner"}>Notification Banner</MenuItem>
                    </Select>
                </FormControl>
            </Grid> */}

            {/* <Grid sm={6} xs={12} item>
            <FormControl style={{width: '100%'}}>
                    <InputLabel id="demo-simple-select-label">Select Title</InputLabel>
                    <Select
                    variant="filled"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.title ? values.title : title}
                        onChange={handleChangeTitle}
                    >
                        <MenuItem value={"Youtube"}>Youtube</MenuItem>
                        <MenuItem value={"Facebook"}>Facebook</MenuItem>
                        <MenuItem value={"Youtube"}>Twitter</MenuItem>
                        <MenuItem value={"Instagram"}>Instagram</MenuItem>

                    </Select>
                </FormControl>
            </Grid> */}
{/* 
            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="link"
                    name="link"
                    label="link"
                    value={values.link}
                    onChange={handleChange}
                    error={touched.link && Boolean(errors.link)}
                    helperText={touched.link && errors.link}
                />
            </Grid> */}
            <Grid sm={12} xs={12} item>
                <Dropzone acceptType=".gif" acceptedMimeTypes='image/gif' files={image} setFiles={setImage} title="Image (Landscape)"
                 imgSrc={image && image.length ? "" : values.image ? values.image : ''}
                          caption="Drag 'n' drop image files here, or click to select image files "/>
                 <Grid container justifyContent="center">
                 <FormHelperText >Note: image type should be GIF/gif only</FormHelperText>
                 </Grid>
            </Grid>

        </Grid>
    );
};

export default CreateBannerForm;
