import React, {useState, useEffect , useCallback} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Paper, Button} from "@material-ui/core";
import {Formik, Form} from "formik";
import {helpers} from "../../../helper/helper";
import CreateZoleForm from "./CreateZoleForm";
import {useSnackbar} from "notistack";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        background: 'transparent',

        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(4, 0, 1, 0),
    },
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "91%",
    },
}));


const CreateZole = (props) => {
    const Id = props.match.params.id
    const history = useHistory()
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [singlePlan, setSinglePlan] = useState();
    const [planId, setPlanId] = useState();
    const [editCheck, setEditCheck] = useState(false);

    const initialValues = {
        zole: "",
        price: "",
        bonus: "",
        appleId: ""
    };

    const editValues = {
        zole: singlePlan && singlePlan.zole ? singlePlan.zole : '',
        price: singlePlan && singlePlan.price ? singlePlan.price : '',
        bonus: singlePlan && singlePlan.bonus ? singlePlan.bonus : '',
        appleId: singlePlan && singlePlan.appleId ? singlePlan.appleId : '',
    };

    const handleSubmit = async (values) => {
        const url = "zoles";
       
        const result = await helpers.apiCall("POST", url, values);
        if (result.status === 200) {
          
            enqueueSnackbar("Zoles Created successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
            history.push('/dashboard/zole')
        }
        else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };


    const editPlan = async (values) => {
        const url = `zoles/${planId}`;
        const result = await helpers.apiCall("PUT", url, values);
        if (result.status === 200) {
          
            setEditCheck(false);
            enqueueSnackbar("Updated successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
            history.push('/dashboard/zole')
        }
        else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };

    const singlePlanById = useCallback(async (id) => {
        setPlanId(Id);
        const url = `zoles/${Id}`;
        const result = await helpers.apiCall("get", url);
        if (result.status === 200) {
            setSinglePlan(result.data.result);
            setEditCheck(true);
        } else {
            setEditCheck(false);
        }
    },[Id])

    useEffect(() => {
        if(Id && !editCheck){
            singlePlanById()
        }
    }, [singlePlanById , Id , editCheck]);

    return (
        <Paper className={classes.paper} square>
            <Formik
                enableReinitialize="true"
                initialValues={editCheck ? editValues : initialValues}
                onSubmit={editCheck ? editPlan : handleSubmit}
            >
                {(formik) => {
                    return (
                        <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Zole Form</h4>
                <p className="card-description"> Add Zole Form Detail Here </p>
                        <Form autoComplete="off">
                            <CreateZoleForm formik={formik}/>
                            <Grid align="right" item style={{marginTop: "10px"}}>
                                <div className={classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className={classes.submit}
                                        color="primary"
                                    >
                                        {editCheck ? "UPDATE" : "Create"}
                                    </Button>
                                </div>
                            </Grid>
                        </Form>
                        </div>
                        </div>
                        </div>
                    );
                }}
            </Formik>
        </Paper>


    );
};

export default CreateZole;
