import { Button, Grid, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { helpers } from "../../helper/helper";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "91%",
  },
}));

const getMonthString = (month) => {
  switch (month) {
    case 1:
      return "January";

    case 2:
      return "Febuary";

    case 3:
      return "March";

    case 4:
      return "April";

    case 5:
      return "May";

    case 6:
      return "June";

    case 7:
      return "July";

    case 8:
      return "August";

    case 9:
      return "September";

    case 10:
      return "October";

    case 11:
      return "November";

    case 12:
      return "December";

    default: return null;
  }
};

const CreateReward = () => {

  const [rewardData, setRewardData] = useState({
    month: null,
    monthName: "",
    year: null
  });
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectMonthYear = (e) => {
    if (e.target.value) {
      const array = e.target.value.split("-");
      setRewardData({
        ...rewardData,
        year: Number(array[0]),
        month: Number(array[1]),
        monthName: getMonthString(Number(array[1]))
      });
    }
  };

  const handleCreateReward = async () => {
    if (rewardData.month && rewardData.monthName && rewardData.year) {
      const url = 'popularity/create/month/year/for/reward/v1';
      const result = await helpers.apiCall("POST", url, rewardData);
      if (result.status === 200) {

        setRewardData({
          month: null,
          monthName: "",
          year: null
        });
        enqueueSnackbar("Created successfully", {
          variant: 'success',
          autoHideDuration: 3000,
        });
        history.push("/dashboard/popularity/reward")
      }
      else {
        enqueueSnackbar(result.data ? result.data.result.message : result.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      setRewardData({
        month: null,
        monthName: "",
        year: null
      });

    } else {
      enqueueSnackbar("Please Fill Fields!", {
        variant: 'warning',
        autoHideDuration: 3000,
      });
    }
  };

  const maxMonth = new Date().toISOString().split('-').slice(0, 2).join('-');
  const desiredLength = 2;

  return (
    <React.Fragment>
      <Paper className={classes.paper} square>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Add Reel Category Form</h4>
            <p className="card-description"> Add Reel Category Here </p>
            <Grid container spacing={3}>
              <Grid sm={8} xs={12} item>
                <TextField
                  fullWidth
                  type="month"
                  variant="outlined"
                  id="Month Year"
                  name="Month Year"
                  label="Month Year"
                  value={`${rewardData.year}-${rewardData.month?.toString().padStart(desiredLength, "0")}`}
                  onChange={handleSelectMonthYear}
                  inputProps={{ min: maxMonth }}
                />
              </Grid>
            </Grid>
            <Grid align="right" item style={{ marginTop: "10px" }}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.submit}
                  color="primary"
                  onClick={e => handleCreateReward()}
                >
                  Create
                </Button>
              </div>
            </Grid>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  )
};

export default CreateReward;