// import React, { useCallback, useEffect, useState } from "react";
// import moment from "moment";
// import {
//   LinearProgress,
//   Paper,
//   Button,
//   Grid,
//   InputLabel,
//   Select,
//   RadioGroup,
//   Checkbox,
//   FormControlLabel,
//   Radio,
// } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import DataTable from "react-data-table-component";
// import ArrowDownward from "@material-ui/icons/ArrowDownward";
// import { Link } from "react-router-dom";
// import DOMPurify from "dompurify";
// import SmsForm from "./SmsForm.jsx";
// import SmsHistoryList from "./SmsHistoryList.jsx";
// import { helpers } from "../../helper/helper";
// import { useSnackbar } from "notistack";
// import { FormControl } from "react-bootstrap";
// import { MenuItem } from "react-contextmenu";
// import CountryAndCity from "../../components/StatesAndDistricts.json";

// const sortIcon = <ArrowDownward />;

// const SendSms = () => {
//   // const classes = useStyles();
//   const [smsHistory, setSmsHistory] = useState([]);
//   const [inputValue, setInputValue] = React.useState("");
//   const [loading, setLoading] = useState(false);
//   const { enqueueSnackbar } = useSnackbar();
//   const [city, setCity] = useState("");
//   const [state, setState] = useState("");
//   const [gender, setGender] = useState("");
//   const [selectedAll, setSelectedAll] = useState("false");

//   const handleGenderChange = (event) => setGender(event.target.value);
//   const handleSelectAllChange = (event) => setSelectedAll(event.target.value);
//   const handleCityChange = (event) => setCity(event.target.value);
//   const handleCountryChange = (event) => setState(event.target.value);
//   const [sendDelete, setSendDelete] = useState(false);

//   // Email history data:
//   const smsHistoryData = useCallback(async () => {
//     const url = "users/get/all/sms/v1";
//     const result = await helpers.apiCall("GET", url);

//     setSmsHistory(
//       result && result.data && result.data.result ? result.data.result : []
//     );
//   }, [setSmsHistory]);

//   useEffect(() => {
//     smsHistoryData();
//   }, [smsHistoryData]);

//   const sendSms = async () => {
//     if (!inputValue?.length > 0) {
//       return enqueueSnackbar("Please provide templateId", {
//         variant: "success",
//         autoHideDuration: 3000,
//       });
//     }

//     const url = "users/send/sms/v1";
//     setLoading(true);
//     const result = await helpers.apiCall("POST", url, {
//       templateId: inputValue,
//     });

//     if (result.status === 200) {
//       enqueueSnackbar("Email Send successfully", {
//         variant: "success",
//         autoHideDuration: 3000,
//       });
//       setLoading(false);
//       setInputValue("");
//       smsHistoryData(); // history.push("/dashboard/staticpage");
//     } else {
//       setLoading(false);
//       enqueueSnackbar(result.data ? result.data.message : result.message, {
//         variant: "error",
//         autoHideDuration: 3000,
//       });
//     }
//   };

//   return (
//     <div style={{ width: "100%" }}>
//       <SmsForm
//         loading={loading}
//         sendSms={sendSms}
//         inputValue={inputValue}
//         setInputValue={setInputValue}
//       />
//       <Grid container spacing={3}>
//         <h4 style={{ marginLeft: "2vh", fontWeight: 500, textAlign: "left" }}>
//           Sms History
//         </h4>
//         <Grid item sm={12} xs={12}>
//           <SmsHistoryList smsHistoryList={smsHistory} />
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default SendSms;

import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SmsForm from "./SmsForm.jsx";
import SmsHistoryList from "./SmsHistoryList.jsx";
import { helpers } from "../../helper/helper";
import { useSnackbar } from "notistack";
import CountryAndCity from "../../components/StatesAndDistricts.json";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const SendSms = () => {
  const classes = useStyles();
  const [smsHistory, setSmsHistory] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [gender, setGender] = useState("");
  const [selectedAll, setSelectedAll] = useState("false");
  const [sendDelete, setSendDelete] = useState(false);
  const [religion, setReligion] = useState("");

  const allReligion = [
    "Hindu",
    "Muslim",
    "Sikh",
    "Christian",
    "Budhhist",
    "Jain",
    "Others",
  ];

  const handleGenderChange = (event) => {
    setGender(event.target.value);
    setSelectedAll("false");
    setSendDelete(false);
  };
  const handleCityChange = (event) => {
    setCity(event.target.value);
    setSelectedAll("false");
    setSendDelete(false);
  };
  const handleCountryChange = (event) => {
    setState(event.target.value);
    setSelectedAll("false");
    setSendDelete(false);
  };
  const handleReligionClick = (e) => {
    setReligion(e.target.value);
    setSelectedAll("false");
    setSendDelete(false);
  };
  const handleDeleteClick = () => {
    setSendDelete(!sendDelete);
    setSelectedAll("false");
    setCity("");
    setState("");
    setGender("");
    setReligion("");
  };

  const handleSelectAllChange = (event) => {
    setSelectedAll(event.target.value);
    setSendDelete(false);
    setCity("");
    setState("");
    setGender("");
    setReligion("");
  };

  const smsHistoryData = useCallback(async () => {
    const url = "users/get/all/sms/v1";
    const result = await helpers.apiCall("GET", url);

    setSmsHistory(result.data?.result || []);
  }, []);

  useEffect(() => {
    smsHistoryData();
  }, [smsHistoryData]);

  const sendSms = async () => {
    if (!inputValue) {
      return enqueueSnackbar("Please provide templateId", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }

    const url = "users/send/sms/v1";
    setLoading(true);
    const result = await helpers.apiCall("POST", url, {
      templateId: inputValue,
      state,
      city,
      selectedAll,
      gender,
      sendDelete,
      religion
    });

    if (result.status === 200) {
      enqueueSnackbar("SMS sent successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setLoading(false);
      setInputValue("");
      smsHistoryData();
    } else {
      setLoading(false);
      enqueueSnackbar(result.data?.message || result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <h4
        style={{
          fontWeight: 500,
          textAlign: "left",
          marginLeft: "2vh",
          paddingTop: "2vh",
        }}
      >
        Filters
      </h4>

      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={2}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel>Gender</InputLabel>
            <Select value={gender} onChange={handleGenderChange}>
              <MenuItem value="Other">
                <em>Other</em>
              </MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel>State</InputLabel>
            <Select value={state} onChange={handleCountryChange}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.keys(CountryAndCity)
                .sort()
                .map((c) => (
                  <MenuItem key={c} value={c}>
                    {c}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel>City</InputLabel>
            <Select value={city} onChange={handleCityChange}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {state &&
                CountryAndCity[state]?.map((c) => (
                  <MenuItem key={c} value={c}>
                    {c}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel>Select Religion</InputLabel>
            <Select value={religion} onChange={handleReligionClick}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {allReligion &&
                allReligion?.map((c) => (
                  <MenuItem key={c} value={c}>
                    {c}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormControl className={classes.formControl}>
            <RadioGroup
              value={selectedAll}
              onChange={handleSelectAllChange}
              row
            >
              <FormControlLabel
                value="false"
                control={<Radio />}
                label={<em>None</em>}
              />
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Select All Users"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox checked={sendDelete} onChange={handleDeleteClick} />
              }
              label="Send Only to Deleted Users"
            />
          </FormControl>
        </Grid>
      </Grid>
      <SmsForm
        loading={loading}
        sendSms={sendSms}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />

      <Grid container spacing={3}>
        <h4 style={{ marginLeft: "2vh", fontWeight: 500, textAlign: "left" }}>
          SMS History
        </h4>
        <Grid item sm={12} xs={12}>
          <SmsHistoryList smsHistoryList={smsHistory} />
        </Grid>
      </Grid>
    </div>
  );
};

export default SendSms;
