import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import { helpers } from "../helper/helper";
const SelectTopReelModel = ({
  setReelTopMadal,
  modal,
  isSelectedReel,
  reelId,
  getListOfShort
}) => {
  const [sortNumber, setSortNumber] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setReelTopMadal(false);
  };

  const handleSubmitReel = async() => {
    const isSelected = true;
    const id = reelId;

    if (!sortNumber) {
      return enqueueSnackbar("Enter number please");
    }

    setReelTopMadal(false);
    const url = `reel/select/top/reel/admin/v1`;
    const data={id,isSelected,sortNumber:Number(sortNumber)};
    const result = await helpers.apiCall("PUT",url,data);
    setSortNumber(0);
    getListOfShort();
  };

  const handleUpdateReel = async() => {
    const id = reelId;
    const isSelected = false;
    const sortNumber=0;
    const url = `reel/select/top/reel/admin/v1`;
    const data={id,isSelected,sortNumber:Number(sortNumber)};
    console.log("data",data)
    const result = await helpers.apiCall("PUT",url,data);
    setReelTopMadal(false);
    getListOfShort();
  };

  return (
    <Modal show={modal} onHide={handleClose}>
    {isSelectedReel?  <><Modal.Header closeButton>
        <Modal.Title>Select Top No</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>Enter Sort No</label>
              <textarea
                className="form-control"
                value={sortNumber}
                onChange={(e) => setSortNumber(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Modal.Body></>:<Modal.Header closeButton>
        <Modal.Title>Remove from top list</Modal.Title>
      </Modal.Header>}
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {isSelectedReel ? (
          <Button variant="primary" onClick={handleSubmitReel}>
            Submit
          </Button>
        ) : (
          <Button variant="primary" onClick={handleUpdateReel}>
             Confirm
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SelectTopReelModel;
