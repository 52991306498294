import React, {useEffect, useState} from "react";
import PageContainer from "../../layouts/PageContainer";
import {helpers} from '../../helper/helper';
import {useCallback} from "react";
import GiveAwayList from "./GiveAwayList";
import Loader from "../../layouts/Loader";

const List = () => {
    const [userList, setUserList] = useState();
    const UsersData = useCallback(async () => {
        const url = "users/give-away/list";
        const result = await helpers.apiCall('GET', url);
        setUserList(result.data.result);
    }, [setUserList]);

    useEffect(() => {
        UsersData()
    }, [UsersData]);
    console.log('data', userList)

    return (
        <PageContainer pageheading="USER LIST">
            {!userList ? <Loader/> :
                <GiveAwayList userList={userList}/>
            }
        </PageContainer>
    )
};

export default List;