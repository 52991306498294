import { Button, FormHelperText, Grid, InputLabel, MenuItem, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { helpers } from "../../helper/helper";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { InfoOutlined } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import InfoModal from "./InfoModal";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "91%",
  },
}));

const UpdateZems = () => {

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    zemsToZoleConvert: null,
    zemsToZoleSend: null
  });
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setMessage("");
    setTitle("");
    setOpen(!open);
  };

  useEffect(() => {
    getZemsDetails();
  }, []);

  const getZemsDetails = async () => {
    const url = `zems/admin/v1`;
    const result = await helpers.apiCall("GET", url);
    setData(result && result.data && result.data.result && result.data.result.data);
  };

  const handleUpdateZemsDetails = async () => {
    if (!data.zemsToZoleConvert || !data.zemsToZoleSend) {
      enqueueSnackbar("Please Fill all Fields", {
        variant: 'warning',
        autoHideDuration: 3000,
      });
      return;
    }
    const url = `zems/update/v1`;
    const result = await helpers.apiCall("PUT", url, data);
    if (result.status === 200) {
      enqueueSnackbar("Updated successfully", {
        variant: 'success',
        autoHideDuration: 3000,
      });
      history.push("/dashboard/zems")
    }
    else {
      enqueueSnackbar(result.data ? result.data.result.message : result.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper} square>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Update Zems</h4>
            <Grid container spacing={3}>
              <Grid sm={8} xs={11} item>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  id="zemsToZoleConvert"
                  name="zemsToZoleConvert"
                  label="Zems To Zole (Convert)"
                  value={data.zemsToZoleConvert}
                  onChange={e => setData({ ...data, zemsToZoleConvert: e.target.value })}
                  InputLabelProps={{
                    shrink: Boolean(data.zemsToZoleConvert),
                  }}
                />
              </Grid>
              <Grid sm={4} xs={1} item>
                <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                  <InfoOutlined style={{ cursor: "pointer" }} onClick={e => {
                    setTitle("Zems To Zole Convert");
                    setMessage(`1 Zems = ${data.zemsToZoleConvert} Zoles`);
                    setOpen(true);
                  }} />
                </div>
              </Grid>
              <Grid sm={8} xs={11} item>
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  variant="outlined"
                  type="number"
                  id="zemsToZoleSend"
                  name="zemsToZoleSend"
                  label="Zems To Zole (Send)"
                  value={data.zemsToZoleSend}
                  onChange={e => setData({ ...data, zemsToZoleSend: e.target.value })}
                  InputLabelProps={{
                    shrink: Boolean(data.zemsToZoleSend),
                  }}
                />
              </Grid>
              <Grid sm={4} xs={1} item>
                <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                  <InfoOutlined style={{ cursor: "pointer" }} onClick={e => {
                    setTitle("Zems To Zole Send");
                    setMessage(`1 Zem = 1 x ${data.zemsToZoleSend} = ${data.zemsToZoleSend * 1} Zoles`);
                    setOpen(true);
                  }} />
                </div>
              </Grid>
            </Grid>
            <Grid align="right" item style={{ marginTop: "10px" }}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.submit}
                  color="primary"
                  onClick={e => handleUpdateZemsDetails()}
                >
                  Update
                </Button>
              </div>
            </Grid>
          </div>
        </div>
      </Paper>
      <InfoModal open={open} handleClose={handleClose} title={title} message={message} />
    </React.Fragment>
  )
};

export default UpdateZems;