import React, { useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import yellow from "@material-ui/core/colors/yellow";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import SearchLayout from "../../layouts/SearchLayoutGender";
import VisibilityIcon from "@material-ui/icons/Visibility";
import user from "../../assets/images/faces-clipart/pic-1.png";
import { helpers } from "../../helper/helper";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(32),
      height: theme.spacing(16),
    },
  },
  yellowPaper: {
    backgroundColor: yellow[300],
  },
  customBorder: {
    border: `3px solid ${yellow[200]}`,
  },
  customBorderRadius: {
    borderRadius: 10,
  },
}));

const sortIcon = <ArrowDownward />;

const UsersList = ({
  userList,
  deleteGifts,
  BlockUser,
  setOpen,
  open,
  handleClose,
  setOpenBlock,
  openBlock,
  handleCloseBlock,
  UsersData,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [filterText, setFilterText] = useState({
    query: "",
    queryType: { gender: "", subscribe: "" },
  });
  const [modelData, setModelData] = useState({ isOpen: false, data: "" });

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };
  //   const handleClickOpenBlock = () => {
  //     setOpenBlock(true);
  //   };
  const filteredItems =
    userList && userList.result
      ? userList.result.filter((item) => {
          let fileterData = { gender: true, subscribe: true };
          let search = filterText.query.toLowerCase();
          let IDs = item.userId && item.userId.toLowerCase().includes(search);
          let name = item.name && item.name.toLowerCase().includes(search);

          if (filterText.queryType.gender) {
            fileterData["gender"] =
              item.gender &&
              item.gender.toLowerCase().startsWith(filterText.queryType.gender);
          }
          if (filterText.queryType.subscribe) {
            fileterData["subscribe"] =
              item.isProfilePicVerified.toString() ===
              filterText.queryType.subscribe
                ? true
                : false;
          }
          //  return IDs||name
          return (IDs || name) && fileterData.gender && fileterData.subscribe
            ? true
            : false;
        })
      : "";

  console.log("data", userList);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div style={{ marginTop: "15px", width: "100%" }}>
        <SearchLayout
          onFilter={setFilterText}
          placeHolderText="Search by Name or User Id"
        />
      </div>
    );
  }, [setFilterText]);

  const verifyUser = async (checkKyc) => {
    const url =
      checkKyc === 1
        ? `users/verify/${modelData.data._id}`
        : `users/reject/${modelData.data._id}`;
    const result = await helpers.apiCall("PUT", url)
    .then(res=>{
      UsersData();
      enqueueSnackbar(checkKyc === 1 ? "User Verified" : "Kyc Operation Done", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setModelData({ isOpen: false, data: "" });
    }).catch(err=>{
      enqueueSnackbar(result.data ? result.data.message : result.message, {
            variant: "error",
            autoHideDuration: 3000,
      });
    })
    
    // if (result.status === 200) {
    //   UsersData();
    //   enqueueSnackbar(checkKyc === 1 ? "User Verified" : "Kyc Operation Done", {
    //     variant: "success",
    //     autoHideDuration: 3000,
    //   });

    //   setModelData({ isOpen: false, data: "" });
    // } else {
    //   enqueueSnackbar(result.data ? result.data.message : result.message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
  };

  const headingAdmin = [
    {
      name: "User Id",
      selector: (row) => row.userId,
      wrap: true,
      sortable: true,
    },
    {
      name: "Image",
      wrap: true,
      sortable: true,
      cell: (userList) => (
        <div>
          <img
            style={{ width: "60px", height: "60px", borderRadius: "50%" }}
            src={userList && userList.profilePic ? userList.profilePic : user}
            alt="Logo"
          />
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
      wrap: true,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      wrap: true,
      sortable: true,
    },
    {
      name: "Subscribed",
      wrap: true,
      sortable: true,
      cell: (userList) => (
        <div>
          <p
            style={{ fontSize: "13px" }}
            className={
              userList && userList.isSubscribed
                ? "badge badge-success"
                : "badge badge-danger"
            }
          >
            {userList && userList.isSubscribed
              ? userList.isSubscribed
                ? "Active"
                : ""
              : " IN Active"}
          </p>
        </div>
      ),
    },
    {
      name: "KYC Status",
      wrap: true,
      sortable: true,
      cell: (userList) => (
        <div>
          <p
            style={{ fontSize: "13px" }}
            className={
              userList && userList.isProfilePicVerified
                ? "badge badge-success"
                : "badge badge-danger"
            }
          >
            {userList && userList.isProfilePicVerified
              ? userList.isProfilePicVerified
                ? "Verified"
                : ""
              : " Not Verified"}
          </p>
        </div>
      ),
    },
    {
      name: "Created At",
      selector: (row) =>
        moment(new Date(row.createdAt)).format("DD/MM/YYYY hh:mm"),
      wrap: true,
      sortable: true,
    },
    {
      name: "KYC Image",
      wrap: true,
      sortable: true,
      cell: (userList) => (
        <div>
          <img
            onClick={() =>
              setModelData({ ...modelData, isOpen: true, data: userList })
            }
            style={{ width: "60px", height: "60px", borderRadius: "50%" }}
            src={userList && userList.kycImage ? userList.kycImage : user}
            alt="Logo"
          />
        </div>
      ),
    },
    {
      name: "Action",
      wrap: true,
      center: true,
      cell: (userList) => (
        <div>
          <Button
            color={userList && userList.isBlocked ? "primary" : "secondary"}
            variant="outlined"
            size="small"
            onClick={() => {
              BlockUser(userList._id);
            }}
          >
            {userList && userList.isBlocked ? "UnBlock" : "Block"}
          </Button>
        </div>
      ),
    },
    {
      name: "",
      wrap: true,
      center: true,
      cell: (userList) => (
        <div>
          <Link to={"/dashboard/users/" + userList._id}>
            <VisibilityIcon />
          </Link>

          {/* <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => {
              deleteGifts(userList._id);
            }}
          >
            <DeleteIcon color="primary" />
          </IconButton> */}
        </div>
      ),
    },
  ];
  return (
    <Paper className={classes.customBorderRadius} elevation={15}>
      <Dialog
        open={modelData.isOpen}
        onClose={() => setModelData({ ...modelData, isOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ textAlign: "center" }}
      >
        <DialogTitle id="alert-dialog-title">{"KYC Image"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img
              src={
                modelData.data && modelData.data.kycImage
                  ? modelData.data.kycImage
                  : modelData.data.avatar
              }
              style={{ width: "30%", height: "50%" }}
              alt="img   "
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {modelData.data && modelData.data.isProfilePicVerified ? (
            ""
          ) : (
            <Button
              onClick={(e) => verifyUser(1)}
              color="primary"
              variant="contained"
            >
              Verify
            </Button>
          )}
          {modelData.data && modelData.data.isProfilePicVerified ? (
            ""
          ) : (
            <Button
              onClick={(e) => verifyUser(0)}
              color="primary"
              variant="contained"
            >
              Reject
            </Button>
          )}
          <Button
            onClick={() => setModelData({ ...modelData, isOpen: false })}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <DataTable
        columns={headingAdmin}
        data={filteredItems}
        defaultSortField="createdAt"
        pagination
        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
        sortIcon={sortIcon}
        subHeader
        noHeader={true}
        subHeaderWrap={false}
        subHeaderAlign="right"
        subHeaderComponent={subHeaderComponentMemo}
        striped={true}
        highlightOnHover={true}
        responsive={true}
      />
    </Paper>
  );
};
export default UsersList;
