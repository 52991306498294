import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageContainer from "../../layouts/PageContainer";
import Button from "@material-ui/core/Button";
import {
  IconButton,
  TextField,
} from "@material-ui/core";
import { Grid } from "@mui/material";
import { helpers } from "../../helper/helper";
import { Card, makeStyles } from '@material-ui/core';
import { PlayArrow } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "15px",
    background: "#F85476",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Space between the creator and the song name
    position: 'relative', // For absolute positioning of creator info
    height: '150px', // Adjust as needed
  },
  creatorInfo: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    display: 'flex',
    alignItems: 'center',
    color: "#fff"
  },
  creatorImage: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: '10px'
  },
  name: {
    textAlign: "center",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "18px",
    marginTop: "10px",
    cursor: "pointer",
    '&:hover': {
      textDecoration: "underline", // Underline the name on hover
    },
  },
  audioPlayer: {
    marginTop: "30px",
    width: '100%', // Takes the full width of the card
    bottom: '15px', // Space from the bottom
  }
}));

const ShortMusics = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    getListOfShortMusics();
  }, []);

  const getListOfShortMusics = async () => {
    setLoading(true);
    const url = `reel/song/list/v1?search=`;
    const result = await helpers.apiCall("GET", url);
    setList(result && result.data && result.data.result ? result.data.result.response : []);
    setLoading(false);
  };

  const handleSearch = async () => {
    setLoading(true);
    const url = `reel/song/list/v1?search=${search}`;
    const result = await helpers.apiCall("GET", url);
    setList(result && result.data && result.data.result ? result.data.result.response : []);
    setLoading(false);
  };

  return (
    <>
      <PageContainer pageheading="Shorts Tags"></PageContainer>
      <div className="row">
        <div className="col-12">
          <div className="row m-3">
            <div className="col-6">
              <Grid item xs={12} sm={12} md={12}>
                <div className="">
                  <div className="input-group serch-sec">
                    <TextField
                      size="small"
                      variant="outlined"
                      type="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control rounded"
                      placeholder="Search Short Musics name"
                      aria-label="Search"
                      aria-describedby="search-addon"
                    />
                    &nbsp;
                    <Button
                      style={{ height: "38px" }}
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                    >
                      Go
                    </Button>
                  </div>
                </div>
              </Grid>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-md-6 d-flex">
                  <p className="my-auto" style={{ fontWeight: "500", fontSize: "16px" }}>Total Tags - {list?.length} items</p>
                </div>
              </div>
            </div>
          </div>
          {
            loading ?
              <div style={{ display: "flex", width: "100%", height: "60vh", justifyContent: "center", alignItems: "center" }}>
                <div className="spinner-border" style={{ color: "#F85476" }} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              :
              <>
                <div className="row ml-3 mr-3">
                  {
                    list && list?.length > 0 ?
                      <>
                        {
                          list?.map(song => (
                            <div className="col-3 mt-3" key={song._id}>
                              <Card className={classes.card} >
                                <div className={classes.creatorInfo}>
                                  <img src={song?.userDetails?.profilePic} alt="Creator" className={classes.creatorImage} style={{ cursor: "pointer" }} onClick={e => history.push(`/dashboard/users/${song?.userDetails?._id}`)} />
                                  <span>{song?.userDetails?.name}</span>
                                </div>
                                <audio controls className={classes.audioPlayer}>
                                  <source src={song?.url} type="audio/mpeg" />
                                  Your browser does not support the audio element.
                                </audio>
                                <p className={classes.name} onClick={e => history.push('/dashboard/shorts', { song: song?.name })}>{song?.name}</p>
                              </Card>
                            </div>
                          ))
                        }
                      </>
                      :
                      <>
                        <h4 className="text-center ml-3" style={{ color: "#F85476" }}>Not Found</h4>
                      </>
                  }
                </div>
              </>
          }
        </div>
      </div>
    </>
  )
};

export default ShortMusics;