import React from "react";
import PageContainer from "../../layouts/PageContainer";
import List from "./List";

const Poster = () => {
  return (
    <React.Fragment>
      <PageContainer pageheading="SPLASH">
        <List />
      </PageContainer>
    </React.Fragment>
  )
};

export default Poster;