import React, { useEffect, useState, useCallback } from "react";
import PageContainer from "../../layouts/PageContainer";

import { Grid } from "@material-ui/core";
import ZoleList from "./ZoleList";
import { helpers } from "../../helper/helper";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";

const Zole = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [zoleList, setZoleList] = useState();
  const [check, setCheck] = useState(false);

  const PlanData = useCallback(async () => {
    const url = "zoles";
    const result = await helpers.apiCall("GET", url);
    setZoleList(
      result && result.data && result.data.result ? result.data.result : []
    );
  }, [setZoleList]);

  useEffect(() => {
    PlanData();
  }, [PlanData]);

  const deletePlan = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f50057",
      cancelButtonColor: "#263273",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        const url = `zoles/${id}`;
        const result = await helpers.apiCall("DELETE", url);
        if (result.status === 200) {
          PlanData();
          enqueueSnackbar("Deleted successfully", {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(result.data ? result.data.message : result.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      }
    });
  };
  // const handleChange = () => {
  //     setCheck(!check);
  // };

  return (
    <PageContainer pageheading="CREATE ZOLE">
      <Grid container spacing={3}>
        <Grid item sm={12} xs={12}>
          {/* <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch color="primary" checked={check} onChange={handleChange} name="check"/>
                            }
                            label="Show Plan List"
                        />
                    </FormGroup> */}

          <ZoleList
            planList={zoleList}
            deletePlan={deletePlan}
            check={check}
            setCheck={setCheck}
          />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Zole;
