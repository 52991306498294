import React, { useState, useCallback, useEffect } from "react";
import { helpers } from "../../../helper/helper";
import { Paper, Grid, TextField, Button } from "@material-ui/core";
import PageContainer from "../../../layouts/PageContainer";
import { Formik, Form } from "formik";
import UpdateForm from "./updateForm";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Loader from "../../../layouts/Loader";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "91%",
  },
}));

const FeedbackContainer = (props) => {
  const classes = useStyles();
  const Id = props.match.params.id;
  const initialValues = {
    status: "true",
    adminRemark: "",
  };
  const [feedbackData, setFeedbackData] = useState();
  const [statusV, setStatusV] = React.useState("true");
  const [state, setState] = React.useState({
    checkedA: true,
  });
  const [check, setCheck] = useState(false);
  const handleChangeToggle = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChanges = (event) => {
    setStatusV(event.target.value);
  };

  const FeedbackData = useCallback(async () => {
    const url = `feedback/${Id}`;
    const result = await helpers.apiCall("GET", url);
    setFeedbackData(result.data.result);
  }, [setFeedbackData, Id]);

  const handleSubmit = async (values) => {
    setCheck(true);
    const feedbackdetail = getdetail(values);
    const url = `feedback/${Id}`;
    const result = await helpers.apiCall("PUT", url, feedbackdetail);
    if (result.status === 200) {
      FeedbackData();
      setCheck(false);
    }
  };

  useEffect(() => {
    FeedbackData();
  }, [FeedbackData]);

  return (
    <PageContainer pageheading="FEEDBACK">
      {check === true ? (
        <Loader />
      ) : (
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
             
                
                    <Paper style={{padding:"30px"}}>
                        <h2 style={{color:"#3f51b5"}}>User Details</h2>
                        <br/>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="userId"
                        disabled
                        required
                        value={
                          feedbackData &&
                          feedbackData.reportedby &&
                          feedbackData.reportedby.userId
                            ? feedbackData.reportedby.userId
                            : ""
                        }
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Name"
                        disabled
                        required
                        value={
                          feedbackData &&
                          feedbackData.reportedby &&
                          feedbackData.reportedby.name
                            ? feedbackData.reportedby.name
                            : ""
                        }
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Mobile"
                        disabled
                        required
                        value={
                          feedbackData &&
                          feedbackData.reportedby &&
                          feedbackData.reportedby.mobile
                            ? feedbackData.reportedby.mobile
                            : ""
                        }
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Reported By"
                        disabled
                        required
                        value={
                          feedbackData &&
                          feedbackData.reportedby &&
                          feedbackData.reportedby.email
                            ? feedbackData.reportedby.email
                            : ""
                        }
                        variant="outlined"
                      />
                    </Grid>
                </Grid>
                </Paper>
                  <br />

                  <Paper style={{padding:"30px"}}>
                  <h2 style={{color:"#3f51b5"}}>Feedback Details</h2>
                  <br/>
                  <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Title"
                      disabled
                      required
                      value={
                        feedbackData && feedbackData.title
                          ? feedbackData.title
                          : ""
                      }
                      variant="outlined"
                    />
                    {/* <Typography
                                gutterBottom
                                style={{fontWeight: "bold", color: 'black'}}
                                color="textSecondary"
                            >
                                &nbsp; Title - {feedbackData && feedbackData.title ? feedbackData.title : ''}
                            </Typography> */}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Description"
                      disabled
                      required
                      value={
                        feedbackData && feedbackData.description
                          ? feedbackData.description
                          : ""
                      }
                      variant="outlined"
                    />
                    {/* <Typography
                                gutterBottom
                                style={{fontWeight: "bold", color: 'black'}}
                                color="textSecondary"
                            >
                                &nbsp; Description
                                - &nbsp;&nbsp;{feedbackData && feedbackData.description ? feedbackData.description : ''}
                            </Typography> */}
                  </Grid>
                  </Grid>
                  </Paper>
                  <br/>
                  <Paper style={{padding:"30px"}}>
                  <h2 style={{color:"#3f51b5"}}>Admin Status</h2>
                  <br/>
                  <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Admin Remark"
                      disabled
                      required
                      value={
                        feedbackData && feedbackData.adminRemark
                          ? feedbackData.adminRemark
                          : ""
                      }
                      variant="outlined"
                    />
                    {/* <Typography
                                gutterBottom
                                style={{fontWeight: "bold", color: 'black'}}
                                color="textSecondary"
                            >
                                &nbsp; Admin Remark
                                - &nbsp;&nbsp;{feedbackData && feedbackData.adminRemark ? feedbackData.adminRemark : ''}
                            </Typography> */}
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Status"
                      disabled
                      required
                      value={
                        feedbackData && feedbackData.status
                          ? feedbackData.status
                          : ""
                      }
                      variant="outlined"
                    />
                    {/* <Typography
                                gutterBottom
                                style={{fontWeight: "bold", color: 'black'}}
                                color="textSecondary"
                            >
                                &nbsp; Status
                                - {feedbackData && feedbackData.status ? (feedbackData.status === true ? "True" : "False" ) : 'False'}
                            </Typography> */}
                  </Grid>
                </Grid>
                </Paper>
                <br />
                <br />
                <hr />
                <label>&nbsp;&nbsp;Mark status</label>
                <Switch
                  checked={state.checkedA}
                  onChange={handleChangeToggle}
                  name="checkedA"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <br />
                <Paper style={{padding:"30px"}}>
                {state.checkedA === true ? (
                  <Formik
                    enableReinitialize="true"
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => {
                      return (
                        <Form autoComplete="off">
                          <UpdateForm
                            formik={formik}
                            setStatusV={setStatusV}
                            statusV={statusV}
                            handleChanges={handleChanges}
                            feedbackData={feedbackData}
                          />
                          <Grid
                            align="right"
                            item
                            style={{ marginTop: "10px" }}
                          >
                            <div className={classes.wrapper}>
                              <Button
                                variant="contained"
                                type="submit"
                                className={classes.submit}
                                color="primary"
                              >
                                {"Update Status"}
                              </Button>
                            </div>
                          </Grid>
                        </Form>
                      );
                    }}
                  </Formik>
                ) : (
                  ""
                )}
                </Paper>
            
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default FeedbackContainer;

const getdetail = (values) => {
  let feedbackDetails = {};

  feedbackDetails = {
    status: values.status,
    adminRemark: values.adminRemark,
  };
  return feedbackDetails;
};
