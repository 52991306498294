import React, {useEffect, useState, useCallback} from "react";
import PageContainer from "../../layouts/PageContainer";

import {Grid} from "@material-ui/core";
import NotificationList from "./NotificationList";
import {helpers} from "../../helper/helper";

const Notification = () => {
    const [giftsList, setGiftsList] = useState();

    const GiftsData = useCallback(async () => {
        const url = "notifications/list";
        const result = await helpers.apiCall("GET", url);
        setGiftsList(
            result && result.data && result.data.result ? result.data.result : []
        );
    }, [setGiftsList]);

    useEffect(() => {
        GiftsData();
    }, [GiftsData]);

    return (
        <PageContainer pageheading="NOTIFICATION">
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                    <NotificationList
                        giftsList={giftsList}
                    />
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Notification;
