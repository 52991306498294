import React, {useEffect, useState} from "react";
import PageContainer from "../../layouts/PageContainer";
import {helpers} from '../../helper/helper';
import {useCallback} from "react";
import UsersList from "./UsersList";
import Loader from "../../layouts/Loader";
import {useSnackbar} from "notistack";
import Swal from "sweetalert2";

const KycReq = () => {
    const {enqueueSnackbar} = useSnackbar();
    const [userList, setUserList] = useState();
    const [open, setOpen] = React.useState(false);
    const [openBlock, setOpenBlock] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseBlock = () => {
        setOpenBlock(false);
    };

    const UsersData = useCallback(async () => {
        const url = "users/kycrequest";
        const result = await helpers.apiCall('GET', url);
        setUserList(result.data);
    }, [setUserList]);

    useEffect(() => {
        UsersData();
        setOpen(false);
        setOpenBlock(false)
    }, [UsersData]);

    const deleteGifts = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#f50057",
            cancelButtonColor: "#263273",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.value) {
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
                const url = `users/${id}`;
                const result = await helpers.apiCall("DELETE", url);
                if (result.status === 200) {

                    UsersData();
                    enqueueSnackbar("User Deleted successfully", {
                        variant: 'success',
                        autoHideDuration: 3000,
                    });
                    handleClose()
                } else {
                    enqueueSnackbar(result.data ? result.data.message : result.message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                }
            }
        });

    };
    const BlockUser = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't to Block /Unblock ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#f50057",
            cancelButtonColor: "#263273",
            confirmButtonText: "Yes",
        }).then(async (result) => {
            if (result.value) {
                Swal.fire("", "", "success");
                const url = `users/block/${id}`;
                const result = await helpers.apiCall("PUT", url);
                if (result.status === 200) {
                    handleCloseBlock();
                    UsersData();
                    enqueueSnackbar("User BLocked successfully", {
                        variant: 'success',
                        autoHideDuration: 3000,
                    });
                } else {
                    enqueueSnackbar(result.data ? result.data.message : result.message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                }
            }
        });

    };
    return (
        <PageContainer pageheading="KYC REQUEST LIST">
            {!userList ? <Loader/> :
                <UsersList userList={userList} deleteGifts={deleteGifts} BlockUser={BlockUser} setOpen={setOpen}
                           setOpenBlock={setOpenBlock}
                           open={open}
                           handleClose={handleClose}
                           openBlock={openBlock}
                           UsersData={UsersData}
                           handleCloseBlock={handleCloseBlock}
                           />
            }
        </PageContainer>
    )
};
export default KycReq;