import React from "react";
import {Grid, TextField, Hidden, MenuItem} from "@material-ui/core";


const CreateInviteForm = ({formik, image, setImage, handleChanges, rewardTypes, planList}) => {
    const {handleChange, values, touched, errors} = formik;

    console.log('planList', planList)

    return (
        <>
       
        <Grid container spacing={3}>
            
          
           
            <Hidden only={['xs', 'sm', 'md','lg','xl']}>
            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="popularity"
                    name="popularity"
                    label="popularity"
                    value={0}
                    onChange={handleChange}
                    disabled={true}
                    error={touched.popularity && Boolean(errors.popularity)}
                    helperText={touched.popularity && errors.popularity}
                />
            </Grid>
            </Hidden>
            
            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="zole"
                    name="zole"
                    label="Zole"
                    value={values.zole}
                    onChange={handleChange}
                    error={touched.zole && Boolean(errors.zole)}
                    helperText={touched.zole && errors.zole}
                />
            </Grid>
            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    id="message"
                    name="message"
                    label="message"
                    value={values.message}
                    onChange={handleChange}
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                />
            </Grid>
            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    select
                    variant="outlined"
                    id="planId"
                    name="planId"
                    label="Select Plan"
                    value={values.planId}
                    onChange={handleChange}
                    error={touched.planId && Boolean(errors.planId)}
                    helperText={touched.planId && errors.planId}
                >
                {
                    planList && planList.length > 0 && planList.map(plan => (
                        <MenuItem key={plan._id} value={plan._id}>{plan.name}</MenuItem>
                    ))
                }
                </TextField>
              </Grid>
            {/* <Grid sm={12} xs={12} item>
                <Dropzone
                    acceptType="image/*"
                    files={image}
                    setFiles={setImage}
                    imgSrc={image && image.length ? "" : values.image ? values.image : ""}
                    title="Image"
                    caption="Drag 'n' drop image files here, or click to select image files "
                />
            </Grid> */}
        </Grid>
        </>
    );
};

export default CreateInviteForm;
