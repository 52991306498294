import React from "react";
import { IconButton, LinearProgress, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";
import moment from 'moment'

const sortIcon = <ArrowDownward />;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    table: {
      minWidth: 650,
    },
  },
  btn: {
    margin: theme.spacing(4)
  }
}));

const BannerList = ({
  gifList,
  editBanner,
  setOpen,
  open,
  handleClose,
  deleteGifts,
  check,
  singleGiftsById,
}) => {
  const classes = useStyles();

  const heading = [
    {
      name: "Sr. No",
      wrap: true,
      sortable: true,
      cell: (row, index) => index + 1,
    },
    
    {
      name: "Gif",
      selector: "image",
      wrap: true,
      sortable: true,
      cell: (gifList) => (
        <div>
          <img
            style={{ height: "60px", width: "60px" }}
            src={gifList.image}
            alt="img"
          />
        </div>
      ),
    },
    {
      name: "Date",
      selector: "type",
      wrap: true,
      sortable: true,
      cell: (gifList) => (
        <div>
          {moment(gifList.updatedAt).format('DD/MM/YYYY')  }
        </div>
      ),
    },
    {
      name: "Action",
      wrap: true,
      center: true,

      cell: (gifList) => (
        <div>
          <Link to={"/dashboard/create-gif/" + gifList._id}>
            <IconButton
              edge="end"
              aria-label="edit"
              // onClick={() => {
              //     singleGiftsById(gifList._id);
              // }}
            >
              <EditIcon color="primary" />
            </IconButton>
          </Link>

          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => {
              deleteGifts(gifList._id);
            }}
          >
            <DeleteIcon color="primary" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      {!gifList ? (
        <LinearProgress color="primary" />
      ) : (
        <Paper className={classes.paper} square>
          {check ? (
            <>
              <Link to="/dashboard/create-gif">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                >
                  Add GIF
                </Button>
              </Link>
              <DataTable
                columns={heading}
                noHeader={true}
                data={gifList}
                pagination
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                sortIcon={sortIcon}
                striped={true}
                highlightOnHover={true}
                responsive={true}
              />
            </>
          ) : (
            ""
          )}
        </Paper>
      )}
    </div>
  );
};

export default BannerList;
