import React from "react";
import {IconButton, LinearProgress, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import EditIcon from "@material-ui/icons/Edit";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";

const sortIcon = <ArrowDownward/>;

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        table: {
            minWidth: 650,
        },
    },
}));

const InviteList = ({channelsList, editGifts, deleteGifts, check, singleGiftsById}) => {
    const classes = useStyles();
    const heading = [
        {
            name: "Sr. No",
            wrap: true,
            sortable: true,
            cell: (row, index) => index + 1,
        },
        //  {
        //     name: "Popularity",
        //     selector: "popularity",
        //     wrap: true,
        //     sortable: true,
        // },
         {
            name: "Zole",
            selector: "zole",
            wrap: true,
            sortable: true,
        }, 
        {
            name: "Message",
            selector: "message",
            wrap: true,
            sortable: true,
        },{
            name: "Action",
            wrap: true,
            center: true,
            cell: (channelsList) => (
                <div>
                    <Link to={"/dashboard/create-invite/"+channelsList._id}>
                    <IconButton
                        edge="end"
                        aria-label="edit"
                       
                    >
                        <EditIcon color="primary"/>
                    </IconButton>
                    </Link>
                   
                </div>
            )
        }
    ];

    return (
        <div>
            {!channelsList ? (
                <LinearProgress color="primary"/>
            ) : (
                <Paper className={classes.paper} square>
                    {!check ?
                        <DataTable
                            columns={heading}
                            noHeader={true}
                            data={channelsList}
                            pagination
                            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                            sortIcon={sortIcon}
                            striped={true}
                            highlightOnHover={true}
                            responsive={true}
                        />
                        : ''}
                </Paper>
            )}
        </div>
    );
};

export default InviteList;
