import { Button, Grid, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { helpers } from "../../helper/helper";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "91%",
  },
}));

const CreateCategory = () => {

  const [name, setName] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleCreateCategory = async () => {
    const data = { name };
    const url = 'reel/category/create';
    const result = await helpers.apiCall("POST", url, data);
    if (result.status === 200) {

      setName('');
      enqueueSnackbar("Created successfully", {
        variant: 'success',
        autoHideDuration: 3000,
      });
      history.push("/dashboard/shorts/category")
    }
    else {
      enqueueSnackbar(result.data ? result.data.result.message : result.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
    setName('');
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper} square>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Add Reel Category Form</h4>
            <p className="card-description"> Add Reel Category Here </p>
            <Grid container spacing={3}>
              <Grid sm={12} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="Category Name"
                  name="Category Name"
                  label="Category Name"
                  value={name}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid align="right" item style={{ marginTop: "10px" }}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.submit}
                  color="primary"
                  onClick={e => handleCreateCategory()}
                >
                  Create
                </Button>
              </div>
            </Grid>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  )
};

export default CreateCategory;