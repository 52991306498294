import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import { helpers } from "../helper/helper";
const SelectTopPostModel = ({
  setPostTopMadal,
  modal,
  isSelectedPost,
  postId,
  getListOfPost
}) => {
  const [sortNumber, setSortNumber] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setPostTopMadal(false);
  };

  const handleSubmitPost = async() => {
    const isSelected = true;
    const id = postId;
    if (!sortNumber) {
      return enqueueSnackbar("Enter number please");
    }
    const url = `post/select/top/post/admin/v1`;
    const data={id,isSelected,sortNumber:Number(sortNumber)};
    console.log("data",data)
    const result = await helpers.apiCall("PUT",url,data);
    setSortNumber(0);
    setPostTopMadal(false);
    getListOfPost();
  };

  const handleUpdatePost = async() => {
    const id = postId;
    const isSelected = false;
    const sortNumber=0;
    const url = `post/select/top/post/admin/v1`;
    const data={id,isSelected,sortNumber:Number(sortNumber)};
    console.log("data",data)
    const result = await helpers.apiCall("PUT",url,data);
    setPostTopMadal(false);
    getListOfPost();
  };

  return (
    <Modal show={modal} onHide={handleClose}>
    {isSelectedPost?  <><Modal.Header closeButton>
        <Modal.Title>Select Top No</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>Enter Sort No</label>
              <textarea
                className="form-control"
                value={sortNumber}
                onChange={(e) => setSortNumber(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Modal.Body></>:<Modal.Header closeButton>
        <Modal.Title>Remove from top list</Modal.Title>
      </Modal.Header>}
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {isSelectedPost ? (
          <Button variant="primary" onClick={handleSubmitPost}>
            Submit
          </Button>
        ) : (
          <Button variant="primary" onClick={handleUpdatePost}>
             Confirm
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SelectTopPostModel;
