import React, { useEffect, useState } from "react";
import PageContainer from "../../layouts/PageContainer";
import icon from "../../assets/images/avatar-icon.png";
import Button from "@material-ui/core/Button";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { helpers } from "../../helper/helper";
import Swal from "sweetalert2";
import LikeModal from "./LikeModal";
import CommentModal from "./CommentModal";
import DisLikeModal from "./DisLikeModal";
import { Grid, TextField } from "@material-ui/core";
import PaginationInfo from "./PaginationInfo";
import PaginationControls from "./PaginationControls";
import DeleteReson from "../../components/DeleteReson";
import HideReson from "../../components/HideReson";
import SelectTopReelModel from "../../components/SelectTopReelModel";

const Shorts = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isHideShort,setIsHideShort]=useState(false);
  const [isDeletedShort,setIsDeletedShort]=useState(false);
  const [isSelectedReel,setIsSelectedReel]= useState(false)
  const [reelId,setReelId]= useState("")
  const [reelTopMadal, setReelTopMadal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [deletedShort, setdeletedShort] = React.useState("0");
  const [hideShort, setHideShort] = React.useState("0");
  const [search, setSearch] = React.useState("");
  const [totalCounts, setTotalCounts] = React.useState(null);
  const [limit, setLimit] = React.useState(12);
  const [page, setPage] = React.useState(1);
  const [list, setList] = React.useState([]);
  const [likeModal, setLikeModal] = useState(false);
  const [disLikeModal, setDisLikeModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [selectedShortLikeData, setSelectedShortLikeData] = useState([]);
  const [selectedShortDisLikeData, setSelectedShortDisLikeData] = useState([]);
  const [selectedShortCommentData, setSelectedShortCommentData] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [tag, setTag] = React.useState(location.state?.tag || "");
  const [category, setCategory] = React.useState(location.state?.category || "");
  const [modal, setModal] = React.useState(false);
  const [reason, setReason] = useState("");
  const [deletablePostId, setDeletablePostId] = useState("");
  const [hideModal, setHideModal] = React.useState(false);
  const [hideReason, setHideReason] = useState("");
  const [hidePostId, setHidePostId] = useState("");

  const handleCloseModal = () => {
    setModal(false);
    setReason("");
    setDeletablePostId("");
  };

  const handleCloseHideModal = () => {
    setHideModal(false);
    setHideReason("");
    setHidePostId("");
  };

  const handleCloseLikeModal = () => {
    setLikeModal(false);
    setSelectedShortLikeData([]);
  };

  const handleCloseDisLikeModal = () => {
    setDisLikeModal(false);
    setSelectedShortDisLikeData([]);
  };

  const handleCloseCommentModal = () => {
    setCommentModal(false);
    setSelectedShortCommentData([]);
  };

  useEffect(() => {
    getListOfShort();
  }, []);

  useEffect(() => {
    getListOfShort();
  }, [page, deletedShort, hideShort, tag, category]);

  const getListOfShort = async () => {
    setLoading(true);
    const url = `reel/list/admin?skip=${page}&limit=${limit}&deleted=${deletedShort}&hide=${hideShort}&search=${search}&tag=${tag}&category=${category}`;
    const result = await helpers.apiCall("GET", url);
    setList(result && result.data && result.data.result ? result.data.result.response : []);
    setTotalCounts(result && result.data && result.data.result ? result.data.result.count : []);
    setLoading(false);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleShowDeletedShort = () => {
    setIsDeletedShort(true);
    setSearch("");
    setdeletedShort("1");
    setHideShort("0");
  };

  const handleShowHideShort = () => {
    setIsHideShort(true);
    setSearch("");
    setdeletedShort("0");
    setHideShort("1");
  };

  const handleClearBtnFilter = () => {
    setIsHideShort(false);
    setIsDeletedShort(false);
    setSearch("");
    if (deletedShort === "1") {
      setdeletedShort("0");
    }
    if (hideShort === "1") {
      setHideShort("0");
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    const url = `reel/list/admin?skip=${page}&limit=${limit}&deleted=${deletedShort}&hide=${hideShort}&search=${search}&tag=${tag}&category=${category}`;
    const result = await helpers.apiCall("GET", url);
    setList(result && result.data && result.data.result ? result.data.result.response : []);
    setTotalCounts(result && result.data && result.data.result ? result.data.result.count : []);
    setLoading(false);
  };

  const handleClearTagButton = async () => {
    setTag("");
  };

  const handleClearCategoryButton = async () => {
    setCategory("");
  };

  const clearAllData = () => {
    setTag("");
    setSearch("");
    setdeletedShort("0");
    setHideShort("0");
    getListOfShort();
    setCategory("");
  };

  const handleDeleteShort = (id) => {
    setModal(true);
    setDeletablePostId(id);
  };

  const confirmDeleteModal = async () => {
    if (!reason) {
      alert("Please add reason first");
      return;
    }
    const url = `reel/delete/admin/v1`;
    const result = await helpers.apiCall("POST", url, { id: deletablePostId, reason });
    handleCloseModal();
    clearAllData();
  };

  const confirmHideModal = async () => {
    if (!hideReason) {
      alert("Please add reason first");
      return;
    }
    const url = `reel/hide/admin/v1`;
    const result = await helpers.apiCall("POST", url, { id: hidePostId, reason: hideReason });
    handleCloseHideModal();
    clearAllData();
  };

  const handleHideShort = async (id, text) => {
    if (text !== "Hide") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f50057",
        cancelButtonColor: "#263273",
        confirmButtonText: `Yes, ${text} it!`,
      }).then(async (result) => {
        if (result.value) {
          Swal.fire(`${text}!`, `Short has been ${text}.`, "success");
          const url = `reel/hide/admin/v1`;
          const result = await helpers.apiCall("POST", url, { id, reason: "" });
          console.log('result', result)
          if (result.status === 200) {
            clearAllData();
            // handleClose();
            enqueueSnackbar(`Short ${text} successfully`, {
              variant: "success",
              autoHideDuration: 3000,
            });
          } else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
              variant: "error",
              autoHideDuration: 3000,
            });
          }
        }
      });
    } else {
      setHideModal(true);
      setHidePostId(id);
    }
  };
console.log(isHideShort,isDeletedShort,"hii")


  return (
    <>
      <PageContainer pageheading="Shorts"></PageContainer>
      <div className="row">
        <div className="col-12">
          <div className="row m-3">
            <div className="col-6">
              {
                tag &&
                <h4 className="text-center mb-3"><span style={{ color: "#F85476", fontSize: "22px" }}>#{tag}</span> Shorts</h4>
              }
              {
                category &&
                <h4 className="text-center mb-3"><span style={{ color: "#F85476", fontSize: "22px" }}>Category applied</span></h4>
              }
              {
                !tag && !category &&
                <>
                  {hideShort === "1" && <h4>Hide Shorts</h4>} {deletedShort === "1" && <h4>Deleted Shorts</h4>}
                </>
              }
            </div>
            <div className="col-6">
              {
                tag &&
                <Button variant="contained" color="primary" onClick={e => handleClearTagButton()}>Clear</Button>
              }
              {
                category &&
                <Button variant="contained" color="primary" onClick={e => handleClearCategoryButton()}>Clear</Button>
              }
              {
                !tag && !category &&
                <>
                  {
                    hideShort === "1" || deletedShort === "1" ?
                      <Button variant="contained" color="primary" onClick={e => handleClearBtnFilter()}>Back</Button> :
                      <>
                        <Button variant="contained" color="primary" onClick={e => handleShowDeletedShort()}>Deleted Shorts</Button>
                        <Button variant="contained" color="primary" className="ml-3" onClick={e => handleShowHideShort()}>Hide Shorts</Button>
                      </>
                  }
                </>
              }
            </div>
            <div className="col-6">
              <Grid item xs={12} sm={12} md={12}>
                <div className="">
                  <div className="input-group serch-sec">
                    <TextField
                      size="small"
                      variant="outlined"
                      type="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control rounded"
                      placeholder="Search Short Title, Created By user name and user Id"
                      aria-label="Search"
                      aria-describedby="search-addon"
                    />
                    &nbsp;
                    <Button
                      style={{ height: "38px" }}
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                    >
                      Go
                    </Button>
                  </div>
                </div>
              </Grid>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-md-6 d-flex">
                  {/* <p className="my-auto" style={{ fontWeight: "500" }}>1 to 10 of {totalCounts} items</p> */}
                  <PaginationInfo page={page} limit={limit} totalCounts={totalCounts} />
                </div>
                <PaginationControls limit={limit} onPageChange={handlePageChange} page={page} totalCounts={totalCounts} />
                {/* <div className="col-md-6 d-flex pagination-btns justify-content-end align-items-center">
                  <i className="mdi mdi-arrow-left"></i>
                  <span className="active">1</span>
                  <span>2</span>
                  <span>...</span>
                  <span>6</span>
                  <i className="mdi mdi-arrow-right"></i>
                </div> */}
              </div>
            </div>
          </div>
          {
            loading ?
              <div style={{ display: "flex", width: "100%", height: "60vh", justifyContent: "center", alignItems: "center" }}>
                <div className="spinner-border" style={{ color: "#F85476" }} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              :
              <>
                <div className="row m-3">
                  {
                    list && list?.length > 0 ?
                      <>
                        {
                          list?.map(short => (
                            <React.Fragment key={short?._id}>
                              <div className="col-md-4 col-sm-12 mt-3">
                                <div className="post_card">
                                  <div className="post_card_header">
                                    <div>
                                      <img src={short?.createdByUserDetails?.profilePic ? short.createdByUserDetails.profilePic : icon} alt="" style={{ cursor: "pointer" }} onClick={e => history.push(`/dashboard/users/${short?.createdByUserDetails?._id}`)} />
                                      <span className="ml-3">{short?.createdByUserDetails?.name}</span>
                                    </div>
                                    <div>
                                    {
                                      !isDeletedShort&&!isHideShort? !short?.deleted && !short?.isSelectedInTop ?
                                        <Button variant="contained" color="primary" className="mb-2" onClick={e =>{
                                          setIsSelectedReel(true);
                                          setReelId(short?._id);
                                          setReelTopMadal(true);}}>Select as Top</Button>
                                          :
                                          <Button variant="contained" color="primary" className="mb-2" onClick={e =>{ setIsSelectedReel(false);
                                            setReelId(short?._id);
                                            setReelTopMadal(true);}}>Unselect to top</Button>:""
                                      } {
                                        !short?.deleted &&
                                        <Button variant="contained" color="primary" className="mb-2" onClick={e => handleDeleteShort(short?._id)}>Delete Short</Button>
                                      }
                                      {
                                        !short?.deleted &&
                                        <Button variant="contained" color="primary" className="ml-3 mb-2" onClick={e => handleHideShort(short?._id, short?.adminHide ? "UnHide" : "Hide")}>{short?.adminHide === true ? "UnHide short" : "Hide short"}</Button>
                                      }
                                    </div>
                                  </div> 
                                  <div className="post_card_content">
                                    <div className="post_media">
                                      <p>{short?.title}</p>
                                      <span style={{ display: "block" }}><span
                                        style={{ fontSize: "18px" }}
                                        className="mdi mdi-bookmark-music"
                                      ></span> {short?.songDetails?.name} {short?.isOriginal && <span
                                        style={{ fontSize: "18px" }}
                                        className="mdi mdi-check-circle"
                                      ></span>}</span>
                                      <span style={{ display: "block", cursor: "pointer" }} onClick={e => setCategory(short?.category)}>category - {short?.reelCategory?.name}</span>
                                      <video controls style={{ maxHeight: "280px", width: "auto", maxWidth: "100%", height: "auto", display: "block", margin: 0 }}>
                                        <source src={short?.url} />
                                      </video>
                                    </div>
                                    <div className="post_hashtags">
                                      {
                                        short?.tags && short?.tags?.length > 0 && short?.tags?.map(tag => (
                                          <span style={{ cursor: "pointer" }} onClick={e => setTag(tag)}>#{tag}</span>
                                        ))
                                      }
                                    </div>
                                    <div className="d-flex interaction-action">
                                      <div className="d-flex flex-column align-items-center">
                                        <span>{short?.views?.length}</span>
                                        <span
                                          style={{ fontSize: "28px" }}
                                          className="mdi mdi-arrow-right-drop-circle-outline"
                                        ></span>
                                        {/* <span>Views</span> */}
                                      </div>
                                      <div className="d-flex flex-column align-items-center">
                                        <span>{short?.totalZems || 0}</span>
                                        <span
                                          style={{ fontSize: "28px" }}
                                          className="mdi mdi-diamond-outline"
                                        ></span>
                                        {/* <span>Views</span> */}
                                      </div>
                                      <div
                                        onClick={() => {
                                          setSelectedShortLikeData(short?.like);
                                          setLikeModal(!likeModal);
                                        }}
                                        style={{ cursor: "pointer" }}
                                        className="d-flex flex-column align-items-center"
                                      >
                                        <span>{short?.like?.length}</span>
                                        <span
                                          style={{ fontSize: "28px" }}
                                          className="mdi mdi-thumb-up-outline"
                                        ></span>
                                        {/* <span>Like</span> */}
                                      </div>
                                      <div
                                        onClick={() => {
                                          setSelectedShortDisLikeData(short?.like);
                                          setDisLikeModal(!disLikeModal);
                                        }}
                                        style={{ cursor: "pointer" }}
                                        className="d-flex flex-column align-items-center"
                                      >
                                        <span>{short?.disLike?.length}</span>
                                        <span
                                          style={{ fontSize: "28px" }}
                                          className="mdi mdi-thumb-down-outline"
                                        ></span>
                                        {/* <span>Like</span> */}
                                      </div>
                                      <div
                                        onClick={() => {
                                          setSelectedShortCommentData(short?.reelComments);
                                          setCommentModal(!commentModal);
                                        }}
                                        style={{ cursor: "pointer" }}
                                        className="d-flex flex-column align-items-center"
                                      >
                                        <span>{short?.reelComments?.length}</span>
                                        <span
                                          style={{ fontSize: "28px" }}
                                          className="mdi mdi-comment-text-outline"
                                        ></span>
                                        {/* <span>Comment</span> */}
                                      </div>
                                      <div className="d-flex flex-column align-items-center">
                                        <span>{short?.share || 0}</span>
                                        <span
                                          style={{ fontSize: "28px" }}
                                          className="mdi mdi-share-outline"
                                        ></span>
                                        {/* <span>Share</span> */}
                                      </div>
                                      {/* <div className="d-flex flex-column align-items-center">
                              <span>89</span>
                              <span
                                style={{ fontSize: "28px" }}
                                className="mdi mdi-bookmark-outline"
                              ></span>
                              <span>Bookmark</span>
                            </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))
                        }
                      </>
                      :
                      <>
                        <h2 className="text-center mt-3">Not Found</h2>
                      </>
                  }
                </div>
              </>
          }
        </div>
      </div>
      <SelectTopReelModel getListOfShort={getListOfShort} reelId={reelId} isSelectedReel={isSelectedReel} handleCloseModal={handleCloseModal} handleDelete={confirmDeleteModal} setReelTopMadal={setReelTopMadal} modal={reelTopMadal} reason={reason} setReason={setReason} />
      <LikeModal likeModal={likeModal} handleCloseLikeModal={handleCloseLikeModal} data={selectedShortLikeData} />
      <DisLikeModal disLikeModal={disLikeModal} handleCloseDisLikeModal={handleCloseDisLikeModal} data={selectedShortDisLikeData} />
      <CommentModal commentModal={commentModal} handleCloseCommentModal={handleCloseCommentModal} data={selectedShortCommentData} />
      <DeleteReson handleCloseModal={handleCloseModal} handleDelete={confirmDeleteModal} modal={modal} reason={reason} setReason={setReason} />
      <HideReson handleCloseModal={handleCloseHideModal} handleHide={confirmHideModal} modal={hideModal} reason={hideReason} setReason={setHideReason} />
    </>
  );
};

export default Shorts;
