import React, { useCallback } from "react";
import { Container, Box, Typography, TextField, Button } from "@mui/material";
import { helpers } from "../../helper/helper";
import { useSnackbar } from "notistack";

const SmsForm = ({ loading, sendSms, setInputValue, inputValue }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 4,
          boxShadow: 3,
          borderRadius: 1,
          width: "100%", // Changed to '100%' to ensure proper width
        }}
      >
        <Typography variant="h5" component="div" gutterBottom>
          Send SMS
        </Typography>
        <TextField
          label="Template Id"
          value={inputValue}
          onChange={handleInputChange}
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={sendSms}>
          {loading ? "Submitting..." : "Submit"}
        </Button>
      </Box>
    </Container>
  );
};

export default SmsForm;
