import { Button, FormHelperText, Grid, InputLabel, MenuItem, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { helpers } from "../../helper/helper";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "91%",
  },
}));

const UpdateProfileVerification = () => {

  const [data, setData] = useState({
    instaUrl: "",
    youtubeUrl: "",
    facebookUrl: "",
    otherUrl: ""
  });
  const classes = useStyles();
  const history = useHistory();
  const id = useParams().id;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getProfileVerificationDetails();
  }, [id]);

  const getProfileVerificationDetails = async () => {
    const url = `profile/verification/admin/${id}/v1`;
    const result = await helpers.apiCall("GET", url);
    setData(result && result.data && result.data.result && result.data.result.response);
  };

  const handleUpdateProfileVerification = async (status) => {
    const url = `profile/verification/update/admin/${id}/v1`;
    const result = await helpers.apiCall("PUT", url, { accountVerifyStatus: status, isVerifyAccount: status === "A" ? true : false });
    if (result.status === 200) {
      enqueueSnackbar("Updated successfully", {
        variant: 'success',
        autoHideDuration: 3000,
      });
      history.push("/dashboard/profile/verification");
    }
    else {
      enqueueSnackbar(result.data ? result.data.result.message : result.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper} square>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Update Profile Verification</h4>
            <Grid container spacing={3} style={{ marginTop: "30px" }}>
              <Grid sm={12} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="instaUrl"
                  name="instaUrl"
                  label="Instagram Url"
                  disabled
                  value={data.instaUrl}
                  onChange={e => setData({ ...data, instaUrl: e.target.value })}
                />
              </Grid>
              <Grid sm={12} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="youtubeUrl"
                  name="youtubeUrl"
                  label="Youtube Url"
                  disabled
                  value={data.youtubeUrl}
                  onChange={e => setData({ ...data, youtubeUrl: e.target.value })}
                />
              </Grid>
              <Grid sm={12} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="facebookUrl"
                  name="facebookUrl"
                  label="Facebook Url"
                  disabled
                  value={data.facebookUrl}
                  onChange={e => setData({ ...data, facebookUrl: e.target.value })}
                />
              </Grid>
              <Grid sm={12} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="otherUrl"
                  name="otherUrl"
                  label="Other Url"
                  disabled
                  value={data.otherUrl}
                  onChange={e => setData({ ...data, otherUrl: e.target.value })}
                />
              </Grid>
            </Grid>
            <Grid align="right" item style={{ marginTop: "10px" }}>
              <div>
                <Button
                  variant="contained"
                  color="success"
                  onClick={e => handleUpdateProfileVerification("A")}
                >
                  Accept
                </Button>
                <Button
                  style={{ marginLeft: "20px" }}
                  variant="contained"
                  color="secondary"
                  onClick={e => handleUpdateProfileVerification("R")}
                >
                  Reject
                </Button>
              </div>
            </Grid>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  )
};

export default UpdateProfileVerification;