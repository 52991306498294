import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import icon from "../../assets/images/avatar-icon.png";
import { useHistory } from 'react-router-dom';
import LikeModal from './LikeModal';

const CommentModal = ({ commentModal, handleCloseCommentModal, data }) => {

  const [likeModal, setLikeModal] = useState(false);
  const [selectedPostLikeData, setSelectedPostLikeData] = useState(false);
  const history = useHistory();

  const handleCloseLikeModal = () => {
    setSelectedPostLikeData([]);
    setLikeModal(false);
  };

  return (
    <>
      <Modal show={commentModal} onHide={handleCloseCommentModal}>
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="likes-modal-list m-0 p-0">
            {
              data && data?.length > 0 ?
                <>
                  {
                    data?.map(comment => (
                      <React.Fragment key={comment?._id}>
                        <li className="align-items-start">
                          <img src={comment?.createdByUserDetails?.profilePic ? comment.createdByUserDetails.profilePic : icon} alt="" style={{ cursor: "pointer" }} onClick={e => history.push(`/dashboard/users/${comment?.createdByUserDetails?._id}`)} />
                          <div>
                            <span>{comment?.createdByUserDetails?.name}</span>
                            <p>{comment?.comment}</p>
                            <div className="like-count" style={{ cursor: "pointer" }} onClick={e => {
                              setSelectedPostLikeData(comment?.like);
                              setLikeModal(true);
                            }}>Likes: {comment?.like?.length}</div>
                          </div>
                        </li>
                        {
                          comment?.commentReply && comment?.commentReply?.length > 0 &&
                          <ul className="likes-modal-list m-0 p-0">
                            {
                              comment?.commentReply?.map(reply => (
                                <li className="reply-comment align-items-start" key={reply._id}>
                                  <img src={reply?.createdByUserDetails?.profilePic ? reply.createdByUserDetails.profilePic : icon} alt="" style={{ cursor: "pointer" }} onClick={e => history.push(`/dashboard/users/${reply?.createdByUserDetails?._id}`)} />
                                  <div>
                                    <span>{reply?.createdByUserDetails?.name}</span>
                                    <p><span style={{ color: "#F85476" }}>@{reply?.mentionUser?.name}</span>{" "}{reply?.comment}</p>
                                    <div className="like-count" style={{ cursor: "pointer" }} onClick={e => {
                                      setSelectedPostLikeData(reply?.like);
                                      setLikeModal(true);
                                    }}>Likes: {reply?.like?.length}</div>
                                  </div>
                                </li>
                              ))
                            }
                          </ul>
                        }
                      </React.Fragment>

                    ))
                  }
                </> :
                <>
                  <div className='text-center'>
                    <h3>Not Data Found</h3>
                  </div>
                </>
            }
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseCommentModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <LikeModal likeModal={likeModal} handleCloseLikeModal={handleCloseLikeModal} data={selectedPostLikeData} />
    </>
  )
};

export default CommentModal;