import React, { useEffect, useState } from "react";
import { Button, IconButton, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "react-data-table-component";
import moment from "moment";
import { ArrowDownward } from "@material-ui/icons";
import yellow from "@material-ui/core/colors/yellow";
import { Link } from "react-router-dom";
import { helpers } from "../../helper/helper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useSnackbar } from "notistack";
import Swal from "sweetalert2";

const sortIcon = <ArrowDownward />;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(32),
      height: theme.spacing(16),
    },
  },
  yellowPaper: {
    backgroundColor: yellow[300],
  },
  customBorder: {
    border: `3px solid ${yellow[200]}`,
  },
  customBorderRadius: {
    borderRadius: 10,
  },
  btn: {
    margin: theme.spacing(4)
  }
}));

const CategoryList = () => {

  const [list, setList] = useState([]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      name: "Category Name",
      selector: (row) => row.name,
      wrap: true,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => moment(new Date(row.createdAt)).format('DD-MM-YYYY'),
      wrap: true,
      sortable: true,
    },
    {
      name: "Action",
      cell: (channelsList) => (
        <div>
          <Link to={`/dashboard/shorts/category/update/${channelsList._id}`}>
            <IconButton
              edge="end"
              aria-label="edit"

            >
              <EditIcon color="primary" />
            </IconButton>
          </Link>
          <IconButton
            edge="end"
            aria-label="delete"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              deleteCategory(channelsList._id);
            }}
          >
            <DeleteIcon color="primary" />
          </IconButton>
        </div>
      ),
      wrap: true,
      sortable: false
    }
  ];

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = async () => {
    const url = "reel/category/list";
    const result = await helpers.apiCall("GET", url);
    setList(result && result.data && result.data.result ? result.data.result.response : []);
  };

  const deleteCategory = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f50057",
      cancelButtonColor: "#263273",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        const url = `reel/category/delete/${id}`;
        await helpers.apiCall("DELETE", url);
        setList(list.filter(c => c._id != id));
        enqueueSnackbar("Deleted successfully", {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Paper className={classes.customBorderRadius} elevation={15}>
        <Link to="/dashboard/shorts/category/create"><Button variant="contained" color="primary" style={{ marginTop: "10px", marginLeft: "10px", marginBottom: "10px" }} >Add Reel Category</Button></Link>
        <DataTable
          columns={columns}
          data={list}
          defaultSortField="createdAt"
          pagination
          paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
          sortIcon={sortIcon}
          noHeader={true}
          striped={true}
          highlightOnHover={true}
          responsive={true}
        />
      </Paper>
    </React.Fragment>
  )
};

export default CategoryList;