import React from "react";

import {Grid, InputLabel, TextField, TextareaAutosize} from "@material-ui/core";


const SendNotificationForm = ({formik, setSelectedFile}) => {
    const {handleChange, values, touched, errors} = formik;

    return (
        <Grid container spacing={3}>
            <Grid sm={8} xs={12} item>
                <TextField
                    fullWidth
                    
                    variant="outlined"
                    id="title"
                    name="title"
                    label="Title"
                    value={values.title}
                    onChange={handleChange}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                />
            </Grid>

            <Grid sm={8} xs={12} item>
                <TextareaAutosize
                    fullWidth
                    variant="outlined"
                    id="message"
                    name="message"
                    aria-label="message"
                    placeholder="message"
                    value={values.message}
                    onChange={handleChange}
                    error={touched.message && Boolean(errors.message)}
                    // This style is to simulate the TextField style
                    style={{
                        width: '100%', // Full width
                        padding: '18.5px 14px', // Similar padding to TextField
                        fontSize: '1rem', // Default font size
                        boxSizing: 'border-box', // To maintain width with padding
                        borderColor: touched.message && errors.message ? 'red' : 'default', // Change border color when there is an error
                        borderRadius: 4, // Rounded corners like TextField
                        boxShadow: touched.message && errors.message ? '0 0 0 2px rgba(255, 0, 0, 0.2)' : 'none', // Shadow effect on error
                    }}
                    minRows={3} // Minimum rows to show by default
                />
                {touched.message && errors.message && (
                    <p style={{ color: 'red', fontSize: '0.75rem', marginTop: '3px' }}>
                        {errors.message}
                    </p>
                )}
            </Grid>


            <Grid sm={8} xs={12} item>
                <InputLabel style={{ marginTop: "10px" }}>Select Prize Image</InputLabel>
                <TextField
                    inputProps={{ accept: 'image/*' }}
                    type="file"
                    fullWidth
                    variant="outlined"
                    onChange={e => {
                        setSelectedFile(e.target.files[0]);
                    }}
                />
            </Grid>

           
        </Grid>
    );
};

export default SendNotificationForm;
