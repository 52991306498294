import React from 'react';

const PaginationInfo = ({ page, limit, totalCounts }) => {
  const startIndex = (page - 1) * limit + 1;
  const endIndex = Math.min(startIndex + limit - 1, totalCounts);

  return (
    <div className="col-md-6 d-flex">
      <p className="my-auto" style={{ fontWeight: "500" }}>
        {startIndex} to {endIndex} of {totalCounts} items
      </p>
    </div>
  );
};

export default PaginationInfo;