import { Button, FormHelperText, Grid, InputLabel, MenuItem, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { helpers } from "../../helper/helper";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { LocationOn } from "@material-ui/icons";
import "../../app/App.scss";
import DeleteReson from "../../components/DeleteReson";
import HideReson from "../../components/HideReson";
import ResolveReson from "../../components/ResolveReson";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "91%",
  },
}));

const ReportShortDetails = () => {

  const [data, setData] = useState();
  const [modal, setModal] = useState(false);
  const [reason, setReason] = useState("");
  const [hideModal, setHideModal] = React.useState(false);
  const [hideReason, setHideReason] = useState("");
  const [resolveModal, setResolveModal] = React.useState(false);
  const [resolveReason, setResolveReason] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const id = useParams().id;
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseModal = () => {
    setModal(false);
    setReason("");
  };

  const handleCloseHideModal = () => {
    setHideModal(false);
    setHideReason("");
  };

  const handleCloseResolveModal = () => {
    setResolveModal(false);
    setResolveReason("");
  };

  useEffect(() => {
    getReportShortDetails();
  }, [id]);

  const getReportShortDetails = async () => {
    const url = `report/admin/list/reel/details/${id}/v1`;
    const result = await helpers.apiCall("GET", url);
    setData(result && result.data && result.data.result && result.data.result.response);
  };

  const handleDeleteShort = () => {
    setModal(true);
  };

  const confirmDeleteModal = async () => {
    if (!reason) {
      alert("Please add reason first");
      return;
    }
    const url = `reel/delete/admin/v1`;
    const result = await helpers.apiCall("POST", url, { id: data.id, reason });
    getReportShortDetails();
    handleCloseModal();
  };

  const confirmHideModal = async () => {
    if (!hideReason) {
      alert("Please add reason first");
      return;
    }
    const url = `reel/hide/admin/v1`;
    const result = await helpers.apiCall("POST", url, { id: data.id, reason: hideReason });
    handleCloseHideModal();
  };

  const confirmResolve = async () => {
    console.log("hello")
    if (!resolveReason) {
      alert("Please add reason first");
      return;
    }
    const url = `report/admin/resolve/report/${id}/v1`;
    const result = await helpers.apiCall("PUT", url, { reason: resolveReason });
    handleCloseResolveModal();
    history.goBack();
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper} square>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Report Reel Details</h4>
            <Grid container spacing={3}>
              <Grid sm={12} xs={12} item>
                <hr />
                <h3>Report Reel</h3>
                <div className="row">
                  <div className="col-md-4 col-sm-12 mt-3" style={{ border: "1px solid grey" }}>
                    <div className="post_card">
                      <div className="post_card_header">
                        <div>
                          <img src={data?.reelDetails?.createdByUserDetails?.profilePic} alt="" style={{ cursor: "pointer" }} onClick={e => history.push(`/dashboard/users/${data?.reelDetails?.createdByUserDetails?._id}`)} />
                          <span className="ml-3">{data?.reelDetails?.createdByUserDetails?.name}</span>
                        </div>
                        <div>
                          {
                            !data?.reelDetails?.deleted &&
                            <Button variant="contained" color="primary" onClick={e => handleDeleteShort(data?.reelDetails?._id)}>Delete Short</Button>
                          }
                        </div>
                      </div>
                      <div className="post_card_content">
                        <div className="post_media">
                          <p>{data?.reelDetails?.title}</p>
                          <span style={{ display: "block" }}><span
                            style={{ fontSize: "18px" }}
                            className="mdi mdi-bookmark-music"
                          ></span> {data?.reelDetails?.songDetails?.name} {data?.reelDetails?.isOriginal && <span
                            style={{ fontSize: "18px" }}
                            className="mdi mdi-check-circle"
                          ></span>}</span>
                          <span style={{ display: "block", cursor: "pointer" }}>category - {data?.reelDetails?.reelCategory?.name}</span>
                          <video controls style={{ maxHeight: "280px", width: "auto", maxWidth: "100%", height: "auto", display: "block", margin: 0 }}>
                            <source src={data?.reelDetails?.url} />
                          </video>
                        </div>
                        <div className="post_hashtags">
                          {
                            data?.reelDetails?.tags && data?.reelDetails?.tags?.length > 0 && data?.reelDetails?.tags?.map(tag => (
                              <span>#{tag}</span>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="mt-3">{data?.title}</h4>
                <p className="mt-3">{data?.description}</p>
                <hr />
                <h3>Created User</h3>
                <div style={{ display: "flex", alignItems: "center", height: "50px" }} className="mt-3">
                  <img src={data?.createdByUserDetails?.profilePic} style={{ height: "50px", width: "50px", cursor: "pointer" }} className="rounded-circle" alt="User_Img" onClick={e => history.push(`/dashboard/users/${data?.createdBy}`)} />
                  <h4 style={{ marginLeft: "20px" }}>{data?.createdByUserDetails?.name}</h4>
                </div>
              </Grid>
            </Grid>
            {
              !data?.resolve &&
              <div style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                <button className="btn btn-success btn-lg" onClick={e => setResolveModal(true)}>Resolve</button>
              </div>
            }

            {
              data?.resolve &&
              <>
                <hr />
                <h3 className="mt-5">Resolve Reason</h3>
                <p>{data?.resolveReason}</p>
              </>
            }
          </div>
        </div>
      </Paper>
      <DeleteReson handleCloseModal={handleCloseModal} handleDelete={confirmDeleteModal} modal={modal} reason={reason} setReason={setReason} />
      <HideReson handleCloseModal={handleCloseHideModal} handleHide={confirmHideModal} modal={hideModal} reason={hideReason} setReason={setHideReason} />
      <ResolveReson handleCloseModal={handleCloseResolveModal} handleResolve={confirmResolve} modal={resolveModal} reason={resolveReason} setReason={setResolveReason} />
    </React.Fragment>
  )
};

export default ReportShortDetails;