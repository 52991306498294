import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../pages/Auth/Login/Login";
import Users from "../pages/Users";
import DeletedUsers from "../pages/DeletedUsers";
import Plan from "../pages/Plan";
import Gifts from "../pages/Gifts";
import Banner from "../pages/Banner";
import Gif from "../pages/gifs";
import Feedback from "../pages/Feedback";
import FeedbackContainer from "../pages/Feedback/FeedbackContainer";
import GiveAway from "../pages/GiveAway";
import GiveAwayList from "../pages/GiveAway/list";
import Spinner from "../app/shared/Spinner";
import Task from "../pages/Task";
import Zole from "../pages/Zole";
import PaymentHistory from "../pages/PaymentHistory";
import PaymentHistoryContainer from "../pages/PaymentHistory/PaymentHistoryContainer";
import ChangePassword from "../pages/Auth/ChangePassword";
import UserContainer from "../pages/Users/UserContainer";
import UpdateUserDetails from "../pages/Users/UpdateUserDetails";
import Subscription from "../pages/Subscription";
import Channels from "../pages/Channel";
import Dashboard from "./dashboard/Dashboard";
import CreatePlan from "../pages/Plan/CreatePlan/index";
import CreateGifts from "../pages/Gifts/CreateGifts";
import CreateBanner from "../pages/Banner/CreateBanner";
import CreateGif from "../pages/gifs/CreateGif";

import CreateTask from "../pages/Task/CreateTask";
import CreateZole from "../pages/Zole/CreateZole";
import SendNotification from "../pages/Notification/Notification";
import Notification from "../pages/Notification";
import StaticPage from "../pages/Static";
import AddStaticPage from "../pages/Static/StaticPage";
import DeleteHistory from "../pages/DeleteHistory/index";
import Invite from "../pages/Invite";
import CreateInvite from "../pages/Invite/CreateTask";
import KycReq from "../pages/KycReq";
import Shorts from "../pages/shorts/Shorts";
// import ShortsDetail from "../pages/shorts/ShortsDetail";
import Posts from "../pages/posts/Posts";
import PostTags from "../pages/posts/PostTags";
import ShortTags from "../pages/shorts/ShortTags";
import ShortCategory from "../pages/shorts/ShortCategory";
import ShortMusics from "../pages/shorts/ShortMusics";
import GameHistoryList from "../pages/GameHistory/GameHistoryList";
import ShortsCategory from "../pages/ShortsCategory";
import CreateCategory from "../pages/ShortsCategory/CreateCategory";
import UpdateCategory from "../pages/ShortsCategory/UpdateCategory";
import UpdatePoster from "../pages/Poster/UpdatePoster";
import Zems from "../pages/Zems";
import UpdateZems from "../pages/Zems/UpdateZems";
import Poster from "../pages/Poster";
import UpdateSplash from "../pages/Splash/UpdateSplash";
import Splash from "../pages/Splash";
import ProfileVerification from "../pages/ProfileVerification";
import UpdateProfileVerification from "../pages/ProfileVerification/UpdateProfileVerification";
import PopularityReward from "../pages/popularityReward";
import CreateReward from "../pages/popularityReward/CreateReward";
import UpdateReward from "../pages/popularityReward/UpdateReward";
import ReportUsers from "../pages/ReportUsers";
import ReportUserDetails from "../pages/ReportUsers/ReportUserDetails";
import ReportPosts from "../pages/ReportPosts";
import ReportPostDetails from "../pages/ReportPosts/ReportPostDetails";
import ReportShorts from "../pages/ReportShorts";
import ReportShortDetails from "../pages/ReportShorts/ReportShortDetails";
import RazorPay from "../pages/RazorPay";
import UpdateRazorPay from "../pages/RazorPay/UpdateKey";
import EmailEditor from "../pages/Email/EmailEditor";
import SendSms from "../pages/Sms/SendSms";
// const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Register1 = lazy(() => import("./user-pages/Register"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path="/dashboard" component={Dashboard} />

        <Route path="/basic-ui/buttons" component={Buttons} />
        <Route path="/basic-ui/dropdowns" component={Dropdowns} />

        <Route path="/form-Elements/basic-elements" component={BasicElements} />

        <Route path="/tables/basic-table" component={BasicTable} />

        <Route path="/icons/mdi" component={Mdi} />

        <Route path="/charts/chart-js" component={ChartJs} />

        <Route path="/user-pages/login-1" component={Login} />
        <Route path="/user-pages/register-1" component={Register1} />

        <Route path="/error-pages/error-404" component={Error404} />
        <Route path="/error-pages/error-500" component={Error500} />

        <Route exact path="/dashboard/users" component={Users} />
        <Route exact path="/dashboard/users/deleted" component={DeletedUsers} />
        <Route exact path="/dashboard/kycreq" component={KycReq} />
        <Route exact path="/dashboard/shorts" component={Shorts} />
        {/* <Route exact path="/shorts-detail" component={ShortsDetail} /> */}
        <Route exact path="/dashboard/posts" component={Posts} />
        <Route exact path="/dashboard/mail" component={EmailEditor} />
        <Route exact path="/dashboard/post/tags" component={PostTags} />
        <Route exact path="/dashboard/short/tags" component={ShortTags} />
        <Route
          exact
          path="/dashboard/short/category"
          component={ShortCategory}
        />
        <Route exact path="/dashboard/short/musics" component={ShortMusics} />
        <Route
          exact
          path="/dashboard/gamehistory"
          component={GameHistoryList}
        />
        <Route exact path="/dashboard/plans" component={Plan} />
        <Route exact path="/dashboard/create-plans" component={CreatePlan} />
        <Route
          exact
          path="/dashboard/create-plans/:id"
          component={CreatePlan}
        />
        <Route exact path="/dashboard/gifts" component={Gifts} />
        <Route exact path="/dashboard/create-gifts" component={CreateGifts} />
        <Route
          exact
          path="/dashboard/create-gifts/:id"
          component={CreateGifts}
        />
        <Route exact path="/dashboard/banner" component={Banner} />
        <Route exact path="/dashboard/create-banner" component={CreateBanner} />
        <Route
          exact
          path="/dashboard/create-banner/:id"
          component={CreateBanner}
        />

        <Route
          exact
          path="/dashboard/shorts/category"
          component={ShortsCategory}
        />
        <Route
          exact
          path="/dashboard/shorts/category/create"
          component={CreateCategory}
        />
        <Route
          exact
          path="/dashboard/shorts/category/update/:id"
          component={UpdateCategory}
        />

        <Route exact path="/dashboard/report/users" component={ReportUsers} />
        <Route
          exact
          path="/dashboard/report/user/:id"
          component={ReportUserDetails}
        />
        <Route exact path="/dashboard/report/posts" component={ReportPosts} />
        <Route
          exact
          path="/dashboard/report/post/:id"
          component={ReportPostDetails}
        />
        <Route exact path="/dashboard/report/shorts" component={ReportShorts} />
        <Route
          exact
          path="/dashboard/report/short/:id"
          component={ReportShortDetails}
        />
        <Route exact path="/dashboard/razorpay" component={RazorPay} />
        <Route
          exact
          path="/dashboard/razorpay/update"
          component={UpdateRazorPay}
        />

        <Route exact path="/dashboard/splash" component={Splash} />
        <Route
          exact
          path="/dashboard/splash/update/:id"
          component={UpdateSplash}
        />
        <Route
          exact
          path="/dashboard/profile/verification"
          component={ProfileVerification}
        />
        <Route
          exact
          path="/dashboard/profile/verification/update/:id"
          component={UpdateProfileVerification}
        />
        <Route exact path="/dashboard/zems" component={Zems} />
        <Route exact path="/dashboard/zems/update" component={UpdateZems} />
        <Route exact path="/dashboard/poster" component={Poster} />
        <Route
          exact
          path="/dashboard/poster/update/:id"
          component={UpdatePoster}
        />
        <Route
          exact
          path="/dashboard/popularity/reward"
          component={PopularityReward}
        />
        <Route
          exact
          path="/dashboard/popularity/reward/create"
          component={CreateReward}
        />
        <Route
          exact
          path="/dashboard/popularity/reward/update/:id"
          component={UpdateReward}
        />

        <Route exact path="/dashboard/gif" component={Gif} />
        <Route exact path="/dashboard/create-gif" component={CreateGif} />
        <Route exact path="/dashboard/create-gif/:id" component={CreateGif} />

        <Route exact path="/dashboard/feedback" component={Feedback} />
        <Route
          exact
          path="/dashboard/feedback/:id"
          component={FeedbackContainer}
        />
        <Route exact path="/dashboard/giveaway" component={GiveAway} />
        <Route exact path="/dashboard/giveaway/list" component={GiveAwayList} />
        <Route exact path="/dashboard/tasks" component={Task} />
        <Route exact path="/dashboard/create-tasks" component={CreateTask} />
        <Route
          exact
          path="/dashboard/create-tasks/:id"
          component={CreateTask}
        />

        <Route exact path="/dashboard/invite" component={Invite} />
        <Route exact path="/dashboard/create-invite" component={CreateInvite} />
        <Route
          exact
          path="/dashboard/create-invite/:id"
          component={CreateInvite}
        />

        <Route exact path="/dashboard/zole" component={Zole} />
        <Route exact path="/dashboard/create-zole" component={CreateZole} />
        <Route exact path="/dashboard/create-zole/:id" component={CreateZole} />
        <Route
          exact
          path="/dashboard/payment-history"
          component={PaymentHistory}
        />
        <Route
          exact
          path="/dashboard/payment-history/:id"
          component={PaymentHistoryContainer}
        />
        <Route
          exact
          path="/dashboard/change-password"
          component={ChangePassword}
        />
        <Route exact path="/dashboard/users/:id" component={UserContainer} />
        <Route
          exact
          path="/dashboard/users/update/details/:id"
          component={UpdateUserDetails}
        />
        <Route exact path="/dashboard/sub" component={Subscription} />
        <Route exact path="/dashboard/channel" component={Channels} />
        <Route exact path="/dashboard/notification" component={Notification} />
        <Route
          exact
          path="/dashboard/send-notification"
          component={SendNotification}
        />
        <Route exact path="/dashboard/staticpage" component={StaticPage} />
        <Route exact path="/dashboard/add-static" component={AddStaticPage} />
        <Route
          exact
          path="/dashboard/add-static/:id"
          component={AddStaticPage}
        />

        <Route exact path="/dashboard/delete" component={DeleteHistory} />
        <Route exact path="/dashboard/sms" component={SendSms} />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
