import { Button, FormHelperText, Grid, InputLabel, MenuItem, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { helpers } from "../../helper/helper";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import ResolveReson from "../../components/ResolveReson";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "91%",
  },
}));

const ReportUserDetails = () => {

  const [data, setData] = useState();
  const [resolveModal, setResolveModal] = React.useState(false);
  const [resolveReason, setResolveReason] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const id = useParams().id;
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseResolveModal = () => {
    setResolveModal(false);
    setResolveReason("");
  };

  useEffect(() => {
    getReportDetails();
  }, [id]);

  const getReportDetails = async () => {
    const url = `report/admin/list/user/details/${id}/v1`;
    const result = await helpers.apiCall("GET", url);
    setData(result && result.data && result.data.result && result.data.result.response);
  };

  const confirmResolve = async () => {
    console.log("hello")
    if (!resolveReason) {
      alert("Please add reason first");
      return;
    }
    const url = `report/admin/resolve/report/${id}/v1`;
    const result = await helpers.apiCall("PUT", url, { reason: resolveReason });
    handleCloseResolveModal();
    history.goBack();
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper} square>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Report User Details</h4>
            <Grid container spacing={3}>
              <Grid sm={8} xs={12} item>
                <hr />
                <h3>Report User</h3>
                <div style={{ display: "flex", alignItems: "center", height: "50px" }} className="mt-3">
                  <img src={data?.reportedUserDetails?.profilePic} style={{ height: "50px", width: "50px", cursor: "pointer" }} className="rounded-circle" alt="User_Img" onClick={e => history.push(`/dashboard/users/${data?.id}`)} />
                  <h4 style={{ marginLeft: "20px" }}>{data?.reportedUserDetails?.name}</h4>
                </div>
                <h4 className="mt-3">{data?.title}</h4>
                <p className="mt-3">{data?.description}</p>
                <hr />
                <h3>Created User</h3>
                <div style={{ display: "flex", alignItems: "center", height: "50px" }} className="mt-3">
                  <img src={data?.createdByUserDetails?.profilePic} style={{ height: "50px", width: "50px", cursor: "pointer" }} className="rounded-circle" alt="User_Img" onClick={e => history.push(`/dashboard/users/${data?.createdBy}`)} />
                  <h4 style={{ marginLeft: "20px" }}>{data?.createdByUserDetails?.name}</h4>
                </div>
              </Grid>
            </Grid>
            {
              !data?.resolve &&
              <div style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                <button className="btn btn-success btn-lg" onClick={e => setResolveModal(true)}>Resolve</button>
              </div>
            }

            {
              data?.resolve &&
              <>
                <hr />
                <h3 className="mt-5">Resolve Reason</h3>
                <p>{data?.resolveReason}</p>
              </>
            }
          </div>
        </div>
      </Paper>
      <ResolveReson handleCloseModal={handleCloseResolveModal} handleResolve={confirmResolve} modal={resolveModal} reason={resolveReason} setReason={setResolveReason} />
    </React.Fragment>
  )
};

export default ReportUserDetails;