import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {FormControl, Grid} from "@material-ui/core";

const UpdateForm = ({formik}) => {

    const {handleChange, values, touched, errors  } = formik;

    return (
        <Grid container spacing={3}>
            <Grid sm={6} xs={12} item>
                <FormControl component="fieldset">
                    <FormLabel component="legend">&nbsp;&nbsp;Status</FormLabel>
                    <RadioGroup aria-label="gender" name="gender1" value={values.status} style={{margin: '5px'}}>
                        <FormControlLabel name="status" value="pending" control={<Radio/>} label="Pending"
                                          onChange={handleChange}/>
                        <FormControlLabel name="status" value="inProgress" control={<Radio/>} label="In Progress"
                                          onChange={handleChange}/>
                                           <FormControlLabel name="status" value="resolved" control={<Radio/>} label="Resolved"
                                          onChange={handleChange}/>
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid sm={6} xs={12} item>

                <label>Admin Remark</label>
                <br/>
                <TextareaAutosize
                    minRows={8}
                    style={{ width: "100%" }}
                    fullWidth
                    variant="outlined"
                    id="adminRemark"
                    name="adminRemark"
                    label="adminRemark"
                    value={values.adminRemark}
                    onChange={handleChange}
                    error={touched.adminRemark && Boolean(errors.adminRemark)}
                    helperText={touched.adminRemark && errors.adminRemark}
                />
            </Grid>

        </Grid>
    );
};

export default UpdateForm;
