import React from "react";
import moment from "moment";
import { LinearProgress, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

const sortIcon = <ArrowDownward />;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    table: {
      minWidth: 650,
    },
  },
  btn: {
    margin: theme.spacing(4),
  },
}));

const EmailHistoryList = ({ emailHistoryList }) => {
  const classes = useStyles();

  const htmlToPlainText = (html) => {
    var tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const heading = [
    {
      name: "Sr. No",
      wrap: true,
      sortable: true,
      cell: (row, index) => index + 1,
    },
    //  {
    //     name: "Image",
    //     wrap: true,
    //     sortable: true,
    //     cell: (emailHistoryList) => <>
    //     {
    //         emailHistoryList.image ?
    //         <a target="_blank" href={emailHistoryList.image} style={{textDecoration: 'none'}}>
    //             <img src={emailHistoryList.image} alt="Img" style={{ width: "50px", height: "50px" }} />
    //         </a> : <p style={{ textAlign: "center" }}>-</p>
    //     }
    //     </>
    // },
    {
      name: "Title",
      selector: "title",
      wrap: true,
      sortable: true,
    },
    //  {
    //     name: "Message",
    //     selector: "body",
    //     wrap: true,
    //     sortable: true
    // }
    {
      name: "Message",
      wrap: true,
      sortable: true,
      cell: (row) => (
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(row.body) }}
        />
      ),
    },
    ,
    {
      name: "User Count",
      selector: "usersCount",
      wrap: true,
      sortable: true,
      // cell: (emailHistoryList) => (
      //     <Link to="/dashboard/users" style={{textDecoration: 'none'}}>
      //         <p>{emailHistoryList && emailHistoryList.toUsers && emailHistoryList.toUsers.length}</p>
      //     </Link>
      // )
    },
    {
      name: "Date",
      wrap: true,
      sortable: true,
      cell: (emailHistoryList) => (
        <p>
          {emailHistoryList && emailHistoryList.createdAt
            ? moment(new Date(emailHistoryList.createdAt)).format(
                "DD/MM/YYYY hh:mm"
              )
            : ""}
        </p>
      ),
    },
  ];

  return (
    <div>
      {!emailHistoryList ? (
        <LinearProgress color="primary" />
      ) : (
        <Paper className={classes.paper} square>
          {/* <Link to='/dashboard/send-notification'>
                        <Button variant="contained" color="primary" className={classes.btn}>Send Notification</Button>
                    </Link> */}
          <DataTable
            columns={heading}
            noHeader={true}
            data={emailHistoryList}
            pagination
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
            sortIcon={sortIcon}
            striped={true}
            highlightOnHover={true}
            responsive={true}
          />
        </Paper>
      )}
    </div>
  );
};

export default EmailHistoryList;
