import React from "react";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import SettingsPanel from "./shared/SettingsPanel";
import Footer from "./shared/Footer";
import { SnackbarProvider } from "notistack";
import Login from "../pages/Auth/Login/Login";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Pages from "../pages/Static/Pages";
import { ThemeProvider } from "@material-ui/core";
import Contact from "../pages/Contact/Contact"

import theme from "./theme";

const App = (props) => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const lo = location.pathname.split("/");
  if (location.pathname === "/dashboard" && !token) {
  }
  return (
    <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route
            exact
            path="/pages/support"
            component={Contact}
          />
          <Route exact path="/pages/:slug" component={Pages} />

        </Switch>

        {!token && lo[1] !== "pages" ? (
          <div className="container-scroller if">
            <Switch>
              <Redirect to="/login" />
            </Switch>
          </div>
        ) : (token || !token) &&
          lo[1] === "pages" ? // <div className="container-scroller if">
          //   <div className="container-fluid page-body-wrapper">
          //     <div className="main-panel">
          //       <div className="content-wrapper"></div>
          //     </div>
          //   </div>
          // </div>
          null : token && lo[1] === "login" ? (
            <div className="container-scroller if">
              <Switch>
                <Redirect to="/dashboard" />
              </Switch>
            </div>
          ) : (
            <div className="container-scroller else">
              <Navbar />
              <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                  <div className=" bg-transparent">
                    <AppRoutes />
                    <SettingsPanel />
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          )}
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default App;
