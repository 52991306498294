import React from "react";
import { IconButton, LinearProgress, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const sortIcon = <ArrowDownward />;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    table: {
      minWidth: 650,
    },
  },
  btn: {
    margin: theme.spacing(4),
  },
}));

const StaticPageList = ({
  giftsList,
  editGifts,
  deleteGifts,
  check,
  singleGiftsById,
  setOpen,
  open,
  handleClose,
}) => {
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const heading = [
    {
      name: "Sr. No",
      wrap: true,
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Title",
      selector: "title",
      wrap: true,
      sortable: true,
    },
    {
      name: "Sub Title",
      selector: "subTitle",
      wrap: true,
      sortable: true,
    },
    //  {
    //     name: "Description",
    //     // selector: "description",
    //     wrap: true,
    //     sortable: true,
    //     // cell:(giftsList)=>(
    //     //     <div>
    //     //         <p>{giftsList.description.toString("markdown")}</p>
    //     //     </div>
    //     // )
    // },
    {
      name: "Action",
      wrap: true,
      center: true,

      cell: (giftsList) => (
        <div>
          <Link to={"/dashboard/add-static/" + giftsList.slug}>
            <IconButton edge="end" aria-label="edit">
              <EditIcon color="primary" />
            </IconButton>
          </Link>
          <Link to={"../pages/" + giftsList.slug}>
            <IconButton edge="end" aria-label="edit">
              <VisibilityIcon color="primary" />
            </IconButton>
          </Link>

          {open && (
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Delete plan"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  No
                </Button>
                <Button
                  onClick={() => {
                    deleteGifts(giftsList._id);
                  }}
                  color="primary"
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {/* <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={handleClickOpen}
                    >
                        <DeleteIcon color="primary"/>
                    </IconButton> */}
        </div>
      ),
    },
  ];

  return (
    <div>
      {!giftsList ? (
        <LinearProgress color="primary" />
      ) : (
        <Paper className={classes.paper} square>
          {check ? (
            <>
              {/* <Link to="/dashboard/add-static">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                >
                  Add
                </Button>
              </Link> */}
              <DataTable
                columns={heading}
                noHeader={true}
                data={giftsList}
                pagination
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                sortIcon={sortIcon}
                striped={true}
                highlightOnHover={true}
                responsive={true}
              />
            </>
          ) : (
            ""
          )}
        </Paper>
      )}
    </div>
  );
};

export default StaticPageList;
