import { createTheme } from "@material-ui/core";

const theme = createTheme({
  spacing: [0, 2, 3, 5, 8],
  palette: {
    primary: {
      //   main: '#f78da7',
      main: "#F85476",

      light: "#ffe6f4",
      //   dark: '#F85476',
      dark: "#f78da7",
      contrastText: "#fff",
    },
  },
  //   components:{
  //       MuiIcons:
  //   }

  //   '&:hover': {
  //     background: "#F85476 !important",
  //  },
  //   '&:focus': {
  //     background: "#F85476",
  //  },
});

export default theme;
