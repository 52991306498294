import React, { useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import SearchLayout from "../../layouts/SearchLayout";

const sortIcon = <ArrowDownward />;

const GiveAwayLists = ({ userList }) => {
  const [filterText, setFilterText] = useState("");

  const filteredItems = userList
    ? userList.filter((item) => {
        let search = filterText.toLowerCase();
        let IDs =
          item.userId?.userId &&
          item.userId?.userId.toLowerCase().includes(search);
        let name =
          item.userId?.name && item.userId?.name.toLowerCase().includes(search);
        return IDs || name;
      })
    : "";

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <React.Fragment>
        <SearchLayout
          onFilter={setFilterText}
          placeHolderText="Search by Name / User Id"
        />
      </React.Fragment>
    );
  }, [setFilterText]);

  const headingAdmin = [
    {
      name: "UserId",
      selector: (row) => row.userId.userId,
      wrap: true,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.userId.name,
      wrap: true,
      sortable: true,
    },
    {
      name: "Plan",
      selector: (row) => (row.planId ? row.planId.name : ""),
      wrap: true,
      sortable: true,
    },
    {
      name: "Gift",
      selector: (row) => (row.giftId ? row.giftId.zole + " zoles" : ""),
      wrap: true,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) =>
        row.createdAt
          ? moment(new Date(row.createdAt)).format("DD/MM/YYYY hh:mm")
          : "",
      wrap: true,
      sortable: true,
    },
  ];
  return (
    <React.Fragment>
      <DataTable
        columns={headingAdmin}
        data={filteredItems}
        defaultSortField="name"
        pagination
        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
        sortIcon={sortIcon}
        subHeader
        noHeader={true}
        subHeaderWrap={false}
        subHeaderAlign="right"
        subHeaderComponent={[subHeaderComponentMemo]}
        striped={true}
        highlightOnHover={true}
        responsive={true}
      />
    </React.Fragment>
  );
};

export default GiveAwayLists;
