import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';


import { Trans } from 'react-i18next';
import AppsIcon from '@material-ui/icons/Apps';
import PersonIcon from '@material-ui/icons/Person';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import GifSharpIcon from '@material-ui/icons/Gif';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import FeedbackIcon from '@material-ui/icons/Feedback';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DeleteIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import { DynamicFeed, Store, VideoCallSharp, AcUnitSharp, LocalAtmOutlined, AddToQueueOutlined, LoyaltyOutlined, DateRangeOutlined, LocalOfferSharp, Report,  MusicNote } from '@material-ui/icons';


class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
 
  } 
  render () {
    return (
      <nav className="sidebar sidebar-offcanvas"  id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <Link className="sidebar-brand brand-logo" to="/dashboard"><img src={require("../../assets/images/Group 626.svg")} alt="logo" /></Link>
          <Link className="sidebar-brand brand-logo-mini pt-3" to="/dashboard"><img src={require("../../assets/images/Group 626.svg")} alt="logo" /></Link>
          {/*<a className="sidebar-brand brand-logo" href="index.html"><img src={require("../../assets/images/Group 626.svg")} alt="logo" /></a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="index.html"><img src={require("../../assets/images/Group 626.svg" )} alt="logo" /></a>*/}
        </div>
        <ul  className="nav" style={{marginLeft:"-10px"}}>
          <br/>
      
          <li className={ 'nav-item '  }>
            <Link className="nav-link"  to="/dashboard">
              <AppsIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title" ><Trans>Dashboard</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/users') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/users">
            <PersonIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Users</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/users/deleted') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/users/deleted">
            <PersonIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Deleted Users</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/kycreq') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/kycreq">
            <ReceiptIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Kyc Request</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/shorts') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/shorts">
            <SlowMotionVideoIcon /> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Shorts</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/short/tags') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/short/tags">
            <LocalOfferSharp /> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Short Tags</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/short/category') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/short/category">
            <LocalOfferSharp /> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Short Category</Trans></span>
            </Link>
          </li>
          {/* <li className={ this.isPathActive('/dashboard/short/musics') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/short/musics">
            <MusicNote /> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Short Musics</Trans></span>
            </Link>
          </li> */}
          <li className={ this.isPathActive('/dashboard/posts') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/posts">
            <DynamicFeed /> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Posts</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/mail') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/mail">
            <DynamicFeed /> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Mail</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/sms') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/sms">
            <DynamicFeed /> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Sms</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/post/tags') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/post/tags">
            <LocalOfferSharp /> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Post Tags</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/plans') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/plans">
            <Store/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Plan</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/gifts') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/gifts">
              <CardGiftcardIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Gifts</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/banner') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/banner">
              <ViewCarouselIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Banner</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/shorts/category') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/shorts/category">
              <VideoCallSharp/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Shorts Category</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/poster') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/poster">
              <AcUnitSharp/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Poster</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/zems') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/zems">
              <LocalAtmOutlined /> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Zems</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/splash') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/splash">
              <AddToQueueOutlined /> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Splash</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/profile/verification') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/profile/verification">
              <LoyaltyOutlined /> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Profile Verification</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/popularity/reward') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/popularity/reward">
              <DateRangeOutlined /> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Popularity Reward</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/gif') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/gif">
              <GifSharpIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Gif</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/feedback') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/feedback">
              <FeedbackIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Feedback</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/giveaway') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/giveaway">
              <AssignmentTurnedInIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>GiveAway</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/tasks') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/tasks">
              <AssignmentIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Reward By Tasks</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/zole') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/zole">
              <VerticalSplitIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Zole</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/payment-history') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/payment-history">
              <AccountBalanceIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Payment-history</Trans></span>
            </Link>
          </li>
          {/* <li className={ this.isPathActive('/dashboard/subscription-history') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/subscription-history">
              <AccountBalanceIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Subscription-history</Trans></span>
            </Link>
          </li> */}
          <li className={ this.isPathActive('/dashboard/change-password') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/change-password">
              <VpnKeyIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Change Password</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/notification') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/notification">
              <NotificationsActiveIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Notification</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/staticpage') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/staticpage">
              <FileCopyIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Static Page</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/delete') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/delete">
              <DeleteIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Delete History</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/invite') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/invite">
              <InsertInvitationIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Invite and Earn</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/gamehistory') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/gamehistory">
              <SportsEsportsIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Game History</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/razorpay') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/razorpay">
              <SportsEsportsIcon/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Razorpay Keys</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/report/users') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/report/users">
              <Report/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Report Users</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/report/posts') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/report/posts">
              <Report/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Report Posts</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/dashboard/report/shorts') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard/report/shorts">
              <Report/> &nbsp;
              &nbsp;
              &nbsp;
              <span className="menu-title"><Trans>Report Shorts</Trans></span>
            </Link>
          </li>
          {/* <li className={ this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title"><Trans>Basic UI Elements</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse >
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link' } to="/basic-ui/buttons"><Trans>Buttons</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link' } to="/basic-ui/dropdowns"><Trans>Dropdowns</Trans></Link></li>
              </ul>
            </Collapse>
          </li>  */}
          {/* <li className={ this.isPathActive('/form-elements') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('formElementsMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              <span className="menu-title"><Trans>Form Elements</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.formElementsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link' } to="/form-elements/basic-elements"><Trans>Basic Elements</Trans></Link></li>
              </ul>
            </Collapse>
          </li> */}
          {/* <li className={ this.isPathActive('/tables') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('tablesMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-table-large menu-icon"></i>
              <span className="menu-title"><Trans>Tables</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link' } to="/tables/basic-table"><Trans>Basic Table</Trans></Link></li>
              </ul>
            </Collapse>
          </li> */}
          {/* <li className={ this.isPathActive('/icons') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('iconsMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-account-box-outline menu-icon"></i>
              <span className="menu-title"><Trans>Icons</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.iconsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link' } to="/icons/mdi">Material</Link></li>
              </ul>
            </Collapse>
          </li> */}
          {/* <li className={ this.isPathActive('/charts') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('chartsMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-chart-line menu-icon"></i>
              <span className="menu-title"><Trans>Charts</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.chartsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link' } to="/charts/chart-js">Chart Js</Link></li>
              </ul>
            </Collapse>
          </li> */}
          {/* <li className={ this.isPathActive('/user-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('userPagesMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-lock-outline menu-icon"></i>
              <span className="menu-title"><Trans>User Pages</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.userPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/login-1"><Trans>Login</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/register-1"><Trans>Register</Trans></Link></li>
              </ul>
            </Collapse>
          </li> */}
          {/* <li className={ this.isPathActive('/error-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-information-outline menu-icon"></i>
              <span className="menu-title"><Trans>Error Pages</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.errorPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-404">404</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-500">500</Link></li>
              </ul>
            </Collapse>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="http://www.bootstrapdash.com/demo/star-admin-free/react/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
              <i className="mdi mdi-file-outline menu-icon"></i>
              <span className="menu-title"><Trans>Documentation</Trans></span>
            </a>
          </li> */}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    // console.log('locatioon', this.props.location.pathname.startsWith(path))

    // return this.props.location.pathname.startsWith(path);
    return this.props.location.pathname === path;

  }
  

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);