import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SweetAlert from 'sweetalert2-react';


const theme = createTheme();

export default function SignIn() {
  const [showalert, Setalert] = useState(false)
  const [success, SetalertSuccess] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let datas = {
      name: data.get('name'),
      email: data.get('email'),
      description: data.get('description'),
    }

    if (datas.email == "" || datas.name == "" || data.description == "") {
      Setalert(true)
    }
    else {
      SetalertSuccess(true)
    }

  };

  return (
    <div style={{ backgroundColor: "#ffe6f4", height: "120vh" }}>
      <Container component="main" maxWidth="xs"  >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <center>
            <img src="http://zolemate.com/static/media/Group%20626.bcf3170e.svg" alt="Logo" style={{ paddingTop: "5px" }} />
          </center> <br />
          <center>
            <Typography component="h1" variant="h4">
              Contact Us
            </Typography>
          </center>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, pt: 3, mb: 5 }} style={{ backgroundColor: "white", borderTopRightRadius: "20px", height: "auto", width: "70vh" }}>
            <center>

              <TextField
                margin="normal"
                required
                style={{ width: "70%" }}
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                autoFocus
              />

              <TextField
                margin="normal"
                required
                style={{ width: "70%" }}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />


              <TextField
                margin="normal"
                multiline
                minRows={5}
                required
                style={{ width: "70%" }}
                id="email"
                label="Enter Description"
                name="description"
                autoComplete="description"
                autoFocus
              />



              <Button
                type="submit"

                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{ backgroundColor: "#f85476", width: "70%" }}

              >
                Contact Us
              </Button>
            </center>

          </Box></Box>
        <div>
          <center>
            <h4>OR</h4>
            <h5>reach us at</h5>
            <h5>support@zolemate.com</h5>

          </center>
        </div>

        {/* <Grid container>
          <Grid item xs>
            OR
          </Grid>
          <Grid item>

          </Grid>
        </Grid> */}
      </Container>

      <SweetAlert
        show={showalert}
        title="Please Fill Fields"
        // text="SweetAlert in React"
        onConfirm={() => Setalert(false)}
      />
      <SweetAlert
        show={success}
        title="Submitted SucessFully"
        // text="SweetAlert in React"
        onConfirm={() => SetalertSuccess(false)}
      />
    </div>
  );
}



