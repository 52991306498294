import React from "react";
import { Grid, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const CreateTaskForm = ({
  formik,
  image,
  setImage,
  handleChanges,
  rewardTypes,
  Id,
}) => {
  const { handleChange, values, touched, errors } = formik;
  
  return (
    <>
      <Grid container spacing={3}>
        <Grid sm={6} xs={12} item>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">
              Select Reward Type
            </InputLabel>
            <Select
              variant="filled"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={rewardTypes ? rewardTypes : values.rewardType}
              onChange={handleChanges}
              error={touched.rewardType && Boolean(errors.rewardType)}
              helperText={touched.rewardType && errors.rewardType}
            >
              <MenuItem value={"reward"}>Reward task</MenuItem>
              <MenuItem value={"weekly"}>Weekly task</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        
        {values.rewardType || rewardTypes ? (
          <Grid sm={6} xs={12} item>
            {(
            //   Id
            //     ? values.rewardType === "reward" && rewardTypes === "reward"  :
                 values.rewardType === "reward" || rewardTypes === "reward"
            ) ? (
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Title
                </InputLabel>
                <Select
                  variant="filled"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.title}
                  onChange={handleChange("title")}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                >
                  <MenuItem value={"AD Impression"}>AD Impression</MenuItem>
                  <MenuItem value={"Watch Video"}>Watch Video</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Title
                </InputLabel>
                <Select
                  variant="filled"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.title}
                  onChange={handleChange("title")}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                >
                  <MenuItem value={"Youtube"}>Youtube</MenuItem>
                  <MenuItem value={"Facebook"}>Facebook</MenuItem>
                  <MenuItem value={"Instagram"}>Instagram</MenuItem>
                  <MenuItem value={"Twitter"}>Twitter</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
        ) : (
          ""
        )}

        <Grid sm={6} xs={12} item>
          <TextField
            fullWidth
            variant="outlined"
            id="link"
            name="link"
            label="Link"
            value={values.link}
            onChange={handleChange}
            error={touched.link && Boolean(errors.link)}
            helperText={touched.link && errors.link}
          />
        </Grid>
        {values.rewardType || rewardTypes ? (
          <Grid sm={6} xs={12} item>
            <TextField
              fullWidth
              variant="outlined"
              id="reward"
              name="reward"
              type="number"
              label={
                (values.rewardType || rewardTypes) === "reward"
                  ? "Message"
                  : "Popularity"
              }
              value={values.reward}
              onChange={handleChange}
              error={touched.reward && Boolean(errors.reward)}
              helperText={touched.reward && errors.reward}
            />
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default CreateTaskForm;
