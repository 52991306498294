import React, {useEffect, useState, useCallback} from "react";
import PageContainer from "../../layouts/PageContainer";

import {Grid} from "@material-ui/core";
import StaticPageList from "./StaticPageList";
import {helpers} from "../../helper/helper";

import {useSnackbar} from "notistack";

const StaticPage = () => {
    const {enqueueSnackbar} = useSnackbar();
    const [giftsList, setGiftsList] = useState();
    const [check, setCheck] = useState(true);
    const [open, setOpen] = React.useState(false);
    
    
    const handleClose = () => {
      setOpen(false);
    };
  
    

    const GiftsData = useCallback(async () => {
        const url = "static";
        const result = await helpers.apiCall("GET", url);
        setGiftsList(
            result && result.data && result.data.result ? result.data.result : []
        );
    }, [setGiftsList]);

    useEffect(() => {
        GiftsData();
        setOpen(false)
    }, [GiftsData]);


    const deleteGifts = async (id) => {
        const url = `static/${id}`;
        const result = await helpers.apiCall("DELETE", url);
        if (result.status === 200) {
            GiftsData();
            enqueueSnackbar("Gifts Deleted successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        } else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };
    // const handleChange = () => {
    //     setCheck(!check);
    // };

    return (
        <PageContainer pageheading="STATIC PAGE">
            <Grid container spacing={3}>
               
               
                <Grid item sm={12} xs={12}>
                    {/* <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch checked={check} onChange={handleChange} name="check" color="primary"/>
                            }
                            label="Show Gifts List"
                        /> */}
                    {/* </FormGroup> */}

                    <StaticPageList
                        giftsList={giftsList}
                        deleteGifts={deleteGifts}
                        // editGifts={editGifts}
                        // singleGiftsById={singleGiftsById}
                        check={check}
                        setOpen={setOpen}
                        open={open}
                        handleClose={handleClose}
                        setCheck={setCheck}
                    />
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default StaticPage;
