import React from "react";
import PageContainer from "../../layouts/PageContainer";
import List from "./List";
import History from "./History";

const Poster = () => {
  return (
    <React.Fragment>
      <PageContainer pageheading="PROFILE VERIFICATION">
        <List />
      </PageContainer>
      <PageContainer pageheading="PROFILE VERIFICATION HISTORY">
        <History />
      </PageContainer>
    </React.Fragment>
  )
};

export default Poster;