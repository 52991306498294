import {authHeader} from "./auth-header";

export const helpers = {
    login,
    api,
    apiCall,
    apiCallForm
};

// const apiUrl = "http://43.204.11.188:4002/api/";
const apiUrl = "https://api.zolemate.com/api/";
// const apiUrl = "http://localhost:4002/api/";

async function login(method, data) {
    // Default options are marked with *
    const response = await fetch(`${apiUrl}auth/admin-login`, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    return await response.json(); // parses JSON response into native JavaScript objects
}

async function api(method, url, data) {
    // for multipart  dont stringify it
    const response = await fetch(`${apiUrl}${url}`, {
        method: method,
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
    });

    return await response.json();
}

async function apiCall(method, url, data) {
    // for multipart  dont stringify it
    const response = await fetch(`${apiUrl}${url}`, {
        method: method,
        mode: "cors", // no-cors, *cors, same-origin
        headers: authHeader(),
        body: JSON.stringify(data),
    });

    return await response.json();
}

async function apiCallForm(method, url, data) {
    // for multipart  dont stringify it
    const response = await fetch(`${apiUrl}${url}`, {
        method: method,
        mode: "cors", // no-cors, *cors, same-origin
        headers: authHeader(true),
        body: data,
    });

    return await response.json();
}
