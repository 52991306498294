import { Button, FormHelperText, Grid, InputLabel, MenuItem, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { helpers } from "../../helper/helper";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "91%",
  },
}));

const UpdateSplash = () => {

  const [data, setData] = useState({
    logo: "",
    splash: ""
  });
  const classes = useStyles();
  const history = useHistory();
  const id = useParams().id;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getSplashDetails();
  }, [id]);

  const getSplashDetails = async () => {
    const url = `splash/admin/get/${id}/v1`;
    const result = await helpers.apiCall("GET", url);
    setData(result && result.data && result.data.result && result.data.result.response);
  };

  const handleUpdateSplash = async () => {
    if (!data.logo || !data.splash) {
      enqueueSnackbar("Please Fill all Fields", {
        variant: 'warning',
        autoHideDuration: 3000,
      });
      return;
    }
    if (typeof data.splash === 'object' || typeof data.logo === 'object') {
      const formData = new FormData();
      if (typeof data.splash === 'object') {
        formData.append("splash", data.splash[0]);
      }
      if (typeof data.logo === 'object') {
        formData.append("logo", data.logo[0]);
      }
      const url = `splash/update/${id}/v1`;
      const result = await helpers.apiCallForm("PUT", url, formData);
      if (result.status === 200) {
        enqueueSnackbar("Updated successfully", {
          variant: 'success',
          autoHideDuration: 3000,
        });
        history.push("/dashboard/splash")
      }
      else {
        enqueueSnackbar(result.data ? result.data.result.message : result.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } else {
      history.push("/dashboard/splash");
    }
  };

  const checkImageDimension = (file, which) => {
    if (file) {
      if (which === "logo") {
        // Desired dimensions
        const desiredWidth = 176;
        const desiredHeight = 38;

        // Read the file as a data URL
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function (event) {
          const img = new Image();

          img.onload = function () {
            // Check if image dimensions match the desired values
            if (img.width === desiredWidth && img.height === desiredHeight) {
              // Dimensions match the desired values
              setData({ ...data, logo: [file] });
            } else {
              enqueueSnackbar(`Logo dimensions should be ${desiredWidth}x${desiredHeight} pixels.`, {
                variant: 'error',
                autoHideDuration: 3000,
              });
            }
          };
          img.src = event.target.result;
        };

      } else if (which === "splash") {
        // Desired dimensions
        const desiredWidth = 1560;
        const desiredHeight = 3376;

        // Read the file as a data URL
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function (event) {
          const img = new Image();

          img.onload = function () {
            // Check if image dimensions match the desired values
            if (img.width === desiredWidth && img.height === desiredHeight) {
              // Dimensions match the desired values
              setData({ ...data, splash: [file] });
            } else {
              enqueueSnackbar(`Splash dimensions should be ${desiredWidth}x${desiredHeight} pixels.`, {
                variant: 'error',
                autoHideDuration: 3000,
              });
            }
          };
          img.src = event.target.result;
        };

      } else {
        enqueueSnackbar("Something went wrong with uploaded File", {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } else {
      enqueueSnackbar("File not selected", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  }

  return (
    <React.Fragment>
      <Paper className={classes.paper} square>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Update Splash</h4>
            <Grid container spacing={3}>
              <Grid sm={8} xs={12} item>
                {data.splash && (typeof data.splash) == "object" ? <img src={URL.createObjectURL(data.splash[0])} style={{ width: "200px", marginTop: "20px" }} /> : <img src={data.splash} style={{ width: "200px", marginTop: "20px" }} />}
                <InputLabel style={{ marginTop: "10px" }}>Select Splash (1560 X 3376)</InputLabel>
                <TextField
                  type="file"
                  inputProps={{ accept: 'image/*' }}
                  fullWidth
                  variant="outlined"
                  onChange={e => {
                    checkImageDimension(e.target.files[0], "splash");
                    // setData({ ...data, splash: e.target.files });
                  }}
                />
              </Grid>
              <Grid sm={8} xs={12} item>
                {data.logo && (typeof data.logo) == "object" ? <img src={URL.createObjectURL(data.logo[0])} style={{ width: "200px", marginTop: "20px" }} /> : <img src={data.logo} style={{ width: "200px", marginTop: "20px" }} />}
                <InputLabel style={{ marginTop: "10px" }}>Select Logo  (176 X 38)</InputLabel>
                <TextField
                  type="file"
                  inputProps={{ accept: 'image/*' }}
                  fullWidth
                  variant="outlined"
                  onChange={e => {
                    checkImageDimension(e.target.files[0], "logo");
                    // setData({ ...data, logo: e.target.files });
                  }}
                />
              </Grid>
            </Grid>
            <Grid align="right" item style={{ marginTop: "10px" }}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.submit}
                  color="primary"
                  onClick={e => handleUpdateSplash()}
                >
                  Update
                </Button>
              </div>
            </Grid>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  )
};

export default UpdateSplash;