import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Paper, Button, Box} from "@material-ui/core";
import {Formik, Form} from "formik";
import {helpers} from "../../helper/helper";


import {useSnackbar} from "notistack";
import { useHistory } from "react-router";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import PageContainer from "../../layouts/PageContainer";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(4, 0, 1, 0),
    },
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "91%",
    },
}));


const DeleteHistory=()=>{
    const history = useHistory();
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const initialValues = {
        type:"",
        time: "",
        
    };
    

    const handleSubmit = async (values) => {

        const url = "settings/delete-history";
       
        const result = await helpers.apiCall("POST", url, values);
        if (result.status === 200) {
           
            enqueueSnackbar("Deleted  successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
            history.push("/dashboard")
        } else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };
    return(
    <PageContainer pageheading="DELETE HISTORY">

        <Paper className={classes.paper} >
        <Box p={3} >
        <Formik
            enableReinitialize="true"
            initialValues={initialValues}
            onSubmit={ handleSubmit}
           
        >
            {(formik) => {
                return (
                    <Form autoComplete="off">
                        <Grid container spacing={3}>
                        <Grid sm={6} xs={12} item>
            <FormControl  variant="outlined" style={{width: "100%"}}>
                    <InputLabel id="demo-simple-select-label">
                        Select Type
                    </InputLabel>
                    <Select
                    variant="outlined"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formik.values.name}
                        onChange={formik.handleChange('type')}
                      
                    >
                        <MenuItem value={"feedback"}>FeedBack</MenuItem>
                        <MenuItem value={"payment"}>Payment</MenuItem>
                        <MenuItem value={"notification"}>Notifications</MenuItem>

                    </Select>
                </FormControl>
            </Grid>
            <Grid sm={6} xs={12} item>
            <FormControl   variant="outlined" style={{width: "100%"}}>
                    <InputLabel id="demo-simple-select-label">
                        Select Time
                    </InputLabel>
                    <Select
                    variant="outlined"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formik.values.name}
                        onChange={formik.handleChange('time')}
                     
                    >
                        <MenuItem value={"3"}>Last 3 Months</MenuItem>
                        <MenuItem value={"6"}>Last 6 Months</MenuItem>
                        <MenuItem value={"12"}>Last 1 Year</MenuItem>
                        
                    </Select>
                </FormControl>
            </Grid>
                      
                    <Grid align="left" >
                        <br/>
                        <div style={{textAlign:'right'}} className={classes.wrapper}>
                            <Button
                                variant="contained"
                                type="submit"
                               
                                color="primary"
                            >
                                {"Delete"}
                            </Button>&nbsp;
                            <Button
                                variant="text"
                              onClick={()=>history.goBack()}
                                color="primary"
                            >
                                {"cancel"}
                            </Button>
                        </div>
                    </Grid>
                    </Grid>
                </Form>
                    );
                }}
            </Formik>
            </Box>
        </Paper>
        </PageContainer>
    )
   
}

export default DeleteHistory; 