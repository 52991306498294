import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { helpers } from '../../helper/helper';
import { Card, Col, Container, FormGroup, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSnackbar } from 'notistack';

const genderList = ["Male", "Female", "Other"];

const bloodGroupList = ['O +', 'O –', 'A +', 'A –', 'B +', 'B –', 'AB +', 'AB –'];

const bodyTypeList = ["Muscular", "Average", "Fit", "Thin"];

const educationList = [
  'High School',
  'Higher Secondary School',
  'Under Graduation',
  'Diploma',
  'Post Graduation',
  'Other'
];

const lookingForList = [
  'Relationship',
  'Friends/ BFF',
  'Fun',
  'Marriage',
  'Other'
];

const relationShipStatusList = [
  'Single',
  'In-Relationship',
  'Divorced',
  'Married',
  'Widowed',
  'Separated'
];

const religionList = [
  'Muslim',
  'Hindu',
  'Sikh',
  'Christian',
  'Buddhist',
  'Jain',
  'Others'
];

const workspaceList = [
  'Entrepreneur',
  'Youtuber',
  'PUBG & Games Player',
  'Sports Person',
  'Student',
  'Teacher',
  'Doctor',
  'Actor',
  'Businessman',
  'Software Developer',
  'Engineer',
  'Other'
];

const UpdateUserDetails = () => {

  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({});
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateId, setStateId] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const { id } = useParams();
  const fileInputRef = useRef(null);

  useEffect(() => {
    getStateList();
  }, []);

  useEffect(() => {
    if (id) {
      getUserDetails();
    } else {
      setData({});
    }
  }, [id]);

  useEffect(() => {
    if (data && Object.keys(data)?.length > 0) {
      if (stateList && stateList?.length > 0) {
        setStateId(stateList.filter(state => state.name === data.state)[0]?.id);
      }
    }
  }, [stateList, data]);

  useEffect(() => {
    if (stateId) {
      handleGetCityList();
    }
  }, [stateId]);

  console.log('data', data)

  const getUserDetails = async () => {
    try {
      const url = `users/profile/admin/${id}/v1`;
      const result = await helpers.apiCall("GET", url);
      setData(result && result.data && result.data.result);
    } catch (error) {
      console.error("User Details error - ", error);
      setData({});
    }
  };

  const getStateList = async () => {
    try {
      const url = `location/state`;
      const result = await helpers.apiCall("POST", url, { countryId: 101 });
      setStateList(result && result.data && result.data.result);
    } catch (error) {
      console.error("User Details error - ", error);
      setStateList([]);
    }
  };

  const handleGetCityList = async () => {
    try {
      const url = `location/city`;
      const result = await helpers.apiCall("POST", url, { stateId });
      setCityList(result && result.data && result.data.result);
    } catch (error) {
      console.error("User Details error - ", error);
      setCityList([]);
    }
  };

  const handleUpdateUserDetails = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }
      const url = `users/profile/admin/update/${id}/v1`;
      const result = await helpers.apiCallForm("PUT", url, formData);
      enqueueSnackbar("User Updated successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
      getUserDetails();
    } catch (error) {
      console.error("User Details error - ", error);
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleUpdateProfilePic = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      if (profilePic) {
        formData.append('profilePic', profilePic);
      }
      formData.append('isProfilePicVerified', data?.isProfilePicVerified);
      const url = `users/profile/admin/update/${id}/v1`;
      const result = await helpers.apiCallForm("PUT", url, formData);
      enqueueSnackbar("User Updated successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
      getUserDetails();
    } catch (error) {
      console.error("User Details error - ", error);
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <React.Fragment>
      <Container fluid>
        {
          data && Object.keys(data)?.length > 0 ?
            <>
              <Row>
                <Col>
                  <Card className='mt-3'>
                    <h4 className="card-title mt-3 mb-3 ml-3">Update User Details</h4>
                    <div className='card-body'>
                      <form onSubmit={e => handleUpdateUserDetails(e)}>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Name</label>
                              <input type='text' required className='form-control' value={data?.name} onChange={e => setData({ ...data, name: e.target.value })} />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Email</label>
                              <input type='email' required className='form-control' value={data?.email} onChange={e => setData({ ...data, email: e.target.value })} />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Mobile</label>
                              <input type='number' required className='form-control' value={data?.mobile} onChange={e => {
                                if (e.target.value.length <= 10) {
                                  setData({ ...data, mobile: e.target.value })
                                }
                              }} />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>DOB</label>
                              <DatePicker
                                className="form-control w-100"
                                selected={new Date(data?.dob)}
                                onChange={date => setData({ ...data, dob: new Date(date).getTime() })}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>User ID</label>
                              <input type='text' required className='form-control' value={data?.userId} onChange={e => setData({ ...data, userId: e.target.value })} />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Height (Min 4.0, Max 8.0)</label>
                              <input type='number' className='form-control' value={data?.height} onChange={e => {
                                if (e.target.value) {
                                  if (e.target.value >= 4 && e.target.value <= 8) {
                                    setData({ ...data, height: e.target.value });
                                  }
                                } else {
                                  setData({ ...data, height: "" });
                                }
                              }} />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Refer Code</label>
                              <input type='text' className='form-control' value={data?.referCode} onChange={e => setData({ ...data, referCode: e.target.value })} />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Popularity</label>
                              <input type='number' className='form-control' value={data?.popularity} onChange={e => setData({ ...data, popularity: e.target.value })} />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Wallet</label>
                              <input type='number' className='form-control' value={data?.wallet} onChange={e => setData({ ...data, wallet: e.target.value })} />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Zems</label>
                              <input type='number' className='form-control' value={data?.zems} onChange={e => setData({ ...data, zems: e.target.value })} />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Gender</label>
                              <select className='form-control' value={data?.gender} onChange={e => setData({ ...data, gender: e.target.value })}>
                                <option value="">Select</option>
                                {
                                  genderList.map(detail => (
                                    <option value={detail}>{detail}</option>
                                  ))
                                }
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Blood Group</label>
                              <select className='form-control' value={data?.bloodGroup} onChange={e => setData({ ...data, bloodGroup: e.target.value })}>
                                <option value="">Select</option>
                                {
                                  bloodGroupList.map(detail => (
                                    <option value={detail}>{detail}</option>
                                  ))
                                }
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Body Type</label>
                              <select className='form-control' value={data?.bodyType} onChange={e => setData({ ...data, bodyType: e.target.value })}>
                                <option value="">Select</option>
                                {
                                  bodyTypeList.map(detail => (
                                    <option value={detail}>{detail}</option>
                                  ))
                                }
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Country</label>
                              <input type='text' className='form-control' disabled value={"India"} />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>State</label>
                              <select className='form-control' value={data?.state} onChange={e => setData({ ...data, state: e.target.value })}>
                                <option value="">Select</option>
                                {
                                  stateList && stateList?.length > 0 && stateList.map(detail => (
                                    <option value={detail?.name}>{detail?.name}</option>
                                  ))
                                }
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>City</label>
                              <select className='form-control' value={data?.district} onChange={e => setData({ ...data, district: e.target.value })}>
                                <option value="">Select</option>
                                {
                                  cityList && cityList?.length > 0 && cityList.map(detail => (
                                    <option value={detail?.name}>{detail?.name}</option>
                                  ))
                                }
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Education</label>
                              <select className='form-control' value={data?.education} onChange={e => setData({ ...data, education: e.target.value })}>
                                <option value="">Select</option>
                                {
                                  educationList && educationList?.length > 0 && educationList.map(detail => (
                                    <option value={detail}>{detail}</option>
                                  ))
                                }
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Looking For</label>
                              <select className='form-control' value={data?.lookingFor} onChange={e => setData({ ...data, lookingFor: e.target.value })}>
                                <option value="">Select</option>
                                {
                                  lookingForList && lookingForList?.length > 0 && lookingForList.map(detail => (
                                    <option value={detail}>{detail}</option>
                                  ))
                                }
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Relationship Status</label>
                              <select className='form-control' value={data?.relationshipStatus} onChange={e => setData({ ...data, relationshipStatus: e.target.value })}>
                                <option value="">Select</option>
                                {
                                  relationShipStatusList && relationShipStatusList?.length > 0 && relationShipStatusList.map(detail => (
                                    <option value={detail}>{detail}</option>
                                  ))
                                }
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Religion</label>
                              <select className='form-control' value={data?.religion} onChange={e => setData({ ...data, religion: e.target.value })}>
                                <option value="">Select</option>
                                {
                                  religionList && religionList?.length > 0 && religionList.map(detail => (
                                    <option value={detail}>{detail}</option>
                                  ))
                                }
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Workspace</label>
                              <select className='form-control' value={data?.workspace} onChange={e => setData({ ...data, workspace: e.target.value })}>
                                <option value="">Select</option>
                                {
                                  workspaceList && workspaceList?.length > 0 && workspaceList.map(detail => (
                                    <option value={detail}>{detail}</option>
                                  ))
                                }
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Drink</label>
                              <select className='form-control' value={data?.drunker} onChange={e => setData({ ...data, drunker: e.target.value })}>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Smoking</label>
                              <select className='form-control' value={data?.smoker} onChange={e => setData({ ...data, smoker: e.target.value })}>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Available Messages</label>
                              <input type='number' className='form-control' value={data?.message} onChange={e => setData({ ...data, message: e.target.value })} />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <label>Update Verify Account</label>
                              <select className='form-control' value={data?.accountVerifyStatus} onChange={e => setData({ ...data, accountVerifyStatus: e.target.value })}>
                                <option value="">Select</option>
                                <option value="A">Accepted</option>
                                <option value="R">Rejected</option>
                                <option value="P">Pending</option>
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <label>About Status</label>
                              <textarea className='form-control' value={data?.aboutStatus} onChange={e => setData({ ...data, aboutStatus: e.target.value })} />
                            </FormGroup>
                          </Col>
                          <button className="btn btn-success btn-block" type='submit'>UPDATE DETAILS</button>
                        </Row>
                      </form>
                      <hr className='mt-3 mb-3' />
                      <h4 className="card-title mt-3 mb-3 ml-3">Update User Profile pic & Verified Status (Green Tick)</h4>
                      <form onSubmit={e => handleUpdateProfilePic(e)}>
                        <Row className='mt-3'>
                          <Col md="6">
                            <img src={profilePic ? URL.createObjectURL(profilePic) : data?.profilePic} style={{ height: "200px", width: "200px", cursor: "pointer" }} onClick={e => triggerFileInput()} />
                            <input
                              type="file"
                              accept="image/*"
                              style={{ display: 'none' }}
                              ref={fileInputRef}
                              onChange={e => setProfilePic(e.target.files[0])}
                            />
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Profile Pic Verified</label>
                              <select className='form-control' value={data?.isProfilePicVerified} onChange={e => setData({ ...data, isProfilePicVerified: e.target.value })}>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                        <button type='submit' className='btn btn-success btn-block mt-3'>UPDATE</button>
                      </form>
                    </div>
                  </Card>
                </Col>
              </Row>
            </> :
            <>
              <h2 className='text-center mt-5 text-muted'>User not found</h2>
            </>
        }
      </Container>
    </React.Fragment>
  )
};

export default UpdateUserDetails;