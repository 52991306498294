import React , {useState , useEffect , useCallback} from "react";
import { helpers } from "../../helper/helper";
import "./Pages.css";

const Pages=(props)=>{
    const Id = props.match.params.slug;
    const [check , setCheck] = useState(false);
const [singleStatic , setSingleStatic] = useState();
    const singlePlanById = useCallback(async (id) => {
        setCheck(false);
        const url = `static/${Id}`;
        const result = await helpers.apiCall("get", url);
        if (result.status === 200) {

            setSingleStatic(result.data.result);
            setCheck(true)
        } else {
            setCheck(false)
        }
    },[Id]);

    useEffect(() => {
       if(Id && !check){
        singlePlanById()
       }
    }, [singlePlanById , Id , check]);
  
return(
    <div>
       <main id="main" class="privacy-policy" style={{backgroundColor:'white' }}>
       
        <section id="services" class="services" style={{background:"none"}}>
            <div class="container" data-aos="fade-up">

                <div class="icon-box" >
                    <div class="row">
                        <div class="col-md-12">
                            <div class="sec-hdr" style={{textAlign:"center" , marginTop:'40px'}}>
                                <h3>{singleStatic && singleStatic.title ? singleStatic.title : ''}</h3>
                            </div>
                            <div class="sec-hdr" style={{textAlign:"center" , marginTop:'40px'}}>
                                <h4>{singleStatic && singleStatic.subTitle ? singleStatic.subTitle : ''}</h4>
                            </div>
                        </div>
                        <div class="col-md-12 p-5" dangerouslySetInnerHTML={{__html: singleStatic && singleStatic.description?singleStatic.description:''}}
> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </main>
    </div>
)
}
export default Pages;