import * as Yup from "yup";

export const PlanValidation = Yup.object().shape({
    amount: Yup.string().required('Amount is Required'),
    name: Yup.string().required('Plan name is Required'),
    bonus: Yup.string().required('Bonus is Required'),
});

export const SubscriptionValidation = Yup.object().shape({
    amount: Yup.string().required('Amount is Required'),
    timePeriod: Yup.string().required('TimePeriod is Required'),
});

export const BannerValidation = Yup.object().shape({
    
    link: Yup.string().required('Link is Required'),
});

export const ChannelValidation = Yup.object().shape({
    title: Yup.string().required('Title is Required'),
    link: Yup.string().required('Link is Required'),
});