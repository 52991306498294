import React, { useEffect, useState } from "react";
import { Button, IconButton, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "react-data-table-component";
import moment from "moment";
import { ArrowDownward } from "@material-ui/icons";
import yellow from "@material-ui/core/colors/yellow";
import { Link } from "react-router-dom";
import { helpers } from "../../helper/helper";
import EditIcon from "@material-ui/icons/Edit";

const sortIcon = <ArrowDownward />;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(32),
      height: theme.spacing(16),
    },
  },
  yellowPaper: {
    backgroundColor: yellow[300],
  },
  customBorder: {
    border: `3px solid ${yellow[200]}`,
  },
  customBorderRadius: {
    borderRadius: 10,
  },
  btn: {
    margin: theme.spacing(4)
  }
}));

const List = () => {

  const [list, setList] = useState([]);
  const classes = useStyles();

  const columns = [
    {
      name: "Created User",
      selector: (row) => row?.createdByUserDetails?.name,
      wrap: true,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => moment(new Date(row.createdAt)).format('DD-MM-YYYY'),
      wrap: true,
      sortable: true,
    },
    {
      name: "Action",
      cell: (channelsList) => (
        <div>
          <Link to={`/dashboard/report/post/${channelsList._id}`}>
            <IconButton
              edge="end"
              aria-label="edit"

            >
              <EditIcon color="primary" />
            </IconButton>
          </Link>
        </div>
      ),
      wrap: true,
      sortable: false
    }
  ];

  useEffect(() => {
    getListOfReportUsers();
  }, []);

  const getListOfReportUsers = async () => {
    const url = "report/admin/list/post/unresolved/v1";
    const result = await helpers.apiCall("GET", url);
    setList(result && result.data && result.data.result ? result.data.result.response : []);
  };

  return (
    <React.Fragment>
      <Paper className={classes.customBorderRadius} elevation={15}>
        <DataTable
          columns={columns}
          data={list}
          defaultSortField="createdAt"
          pagination
          paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
          sortIcon={sortIcon}
          noHeader={true}
          striped={true}
          highlightOnHover={true}
          responsive={true}
        />
      </Paper>
    </React.Fragment>
  )
};

export default List;