import React from "react";
import {IconButton, LinearProgress, Paper , Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";


const sortIcon = <ArrowDownward/>;

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        table: {
            minWidth: 650,
        },
    },
    btn: {
        margin: theme.spacing(4)
      }

}));

const GiftsList = ({giftsList, editGifts, deleteGifts, check, singleGiftsById}) => {
    const classes = useStyles();
    const heading = [
        {
            name: "Sr. No",
            wrap: true,
            sortable: true,
            cell: (row, index) => index + 1,
        }, {
            name: "Zole",
            selector: "zole",
            wrap: true,
            sortable: true,
        }, {
            name: "Popularity",
            selector: "popularity",
            wrap: true,
            sortable: true,
        }, {
            name: "Gift Image",
            selector: "bonus",
            wrap: true,
            sortable: true,

            cell: (giftsList) => (<div>
                <img style={{height: '60px', width: '60px'}} src={giftsList.giftImg} alt="img"/>
            </div>)
        }, {
            name: "Action",
            wrap: true,
            center: true,

            cell: (giftsList) => (
                <div>
                    <Link to={"/dashboard/create-gifts/"+giftsList._id}>
                    <IconButton
                        edge="end"
                        aria-label="edit"

                    >
                        <EditIcon color="primary"/>
                    </IconButton>
                    </Link>

                    <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                            deleteGifts(giftsList._id)
                        }}
                    >
                        <DeleteIcon color="primary"/>
                    </IconButton>
                </div>
            )
        }
    ];

    return (
        <div>
            {!giftsList ? (
                <LinearProgress color="primary"/>
            ) : (
                <Paper className={classes.paper} square>
                    {check ?
                    <>
                    <Link to='/dashboard/create-gifts'><Button variant="contained" color="primary" className={classes.btn} >Add Gifts</Button></Link>
                        <DataTable
                            columns={heading}
                            noHeader={true}
                            data={giftsList}
                            pagination
                            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                            sortIcon={sortIcon}
                            striped={true}
                            highlightOnHover={true}
                            responsive={true}
                        />
                        </>
                        : ''}
                </Paper>
            )}
        </div>
    );
};

export default GiftsList;
