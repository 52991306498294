import { Button, FormHelperText, Grid, InputLabel, MenuItem, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { helpers } from "../../helper/helper";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import HistoryList from "./HistoryList";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "91%",
  },
}));

const UpdateReward = () => {

  const [rewardData, setRewardData] = useState({});
  const [prizeData, setPrizeData] = useState({
    image: "",
    name: ""
  });
  const [prizeTitle, setPrizeTitle] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const id = useParams().id;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getRewardDetails();
  }, [id]);

  useEffect(() => {
    if (Object.keys(rewardData).length > 0) {
      if (prizeTitle) {
        if (rewardData[prizeTitle]) {
          setPrizeData({
            ...prizeData,
            image: rewardData[prizeTitle].image,
            name: rewardData[prizeTitle].name
          });
        } else {
          setPrizeData({
            ...prizeData,
            image: "",
            name: ""
          });
        }
      } else {
        setPrizeData({
          ...prizeData,
          image: "",
          name: ""
        });
      }
    } else {
      setPrizeData({
        ...prizeData,
        image: "",
        name: ""
      });
    }
  }, [rewardData, prizeTitle]);

  const getRewardDetails = async () => {
    const url = `popularity/reward/${id}/v1`;
    const result = await helpers.apiCall("GET", url);
    setRewardData(result && result.data && result.data.result ? result.data.result.response : []);
  };

  const handleUpdateReward = async () => {
    if (!prizeTitle || !prizeData.name || !prizeData.image) {
      enqueueSnackbar("Please Fill all Fields", {
        variant: 'warning',
        autoHideDuration: 3000,
      });
      return;
    }
    const formData = new FormData();
    formData.append("prizeFor", prizeTitle);
    formData.append("name", prizeData.name);
    formData.append("reward", prizeData.image[0]);
    const url = `popularity/create/prizes/for/reward/${id}/v1`;
    const result = await helpers.apiCallForm("PUT", url, formData);
    if (result.status === 200) {
      enqueueSnackbar("Updated successfully", {
        variant: 'success',
        autoHideDuration: 3000,
      });
      history.push("/dashboard/popularity/reward")
    }
    else {
      enqueueSnackbar(result.data ? result.data.result.message : result.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper} square>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Add Popularity Reward Form</h4>
            <p className="card-description"> Add Popularity Reward Here </p>
            <Grid container spacing={3}>
              <Grid sm={8} xs={12} item>
                <TextField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Prize"
                  helperText="Please select Prize"
                  value={prizeTitle}
                  onChange={e => {
                    setPrizeTitle(e.target.value);
                  }}
                >
                  <MenuItem value={"firstPrize"}>1st Prize</MenuItem>
                  <MenuItem value={"secondPrize"}>2nd Prize</MenuItem>
                  <MenuItem value={"thirdPrize"}>3rd Prize</MenuItem>
                  <MenuItem value={"fourToTenPrize"}>4 to 10 Prize</MenuItem>
                  <MenuItem value={"elevenToTwentyFivePrize"}>11 to 25 Prize</MenuItem>
                  <MenuItem value={"twentySixToFiftyPrize"}>26 to 50 Prize</MenuItem>
                  <MenuItem value={"fiftyOneToSeventyFivePrize"}>51 to 75 Prize</MenuItem>
                  <MenuItem value={"seventySixToHundredPrize"}>76 to 100 Prize</MenuItem>
                </TextField>
              </Grid>
              <Grid sm={8} xs={12} item>
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  variant="outlined"
                  id="Enter Prize name"
                  name="Enter Prize name"
                  label="Enter Prize name"
                  value={prizeData.name}
                  onChange={e => setPrizeData({ ...prizeData, name: e.target.value })}
                />
              </Grid>
              <Grid sm={8} xs={12} item>
                {prizeData.image && (typeof prizeData.image) == "object" ? <img src={URL.createObjectURL(prizeData.image[0])} style={{ width: "200px", marginTop: "20px" }} /> : <img src={prizeData.image} style={{ width: "200px", marginTop: "20px" }} />}
                <InputLabel style={{ marginTop: "10px" }}>Select Prize Image</InputLabel>
                <TextField
                  type="file"
                  fullWidth
                  variant="outlined"
                  onChange={e => {
                    setPrizeData({ ...prizeData, image: e.target.files });
                  }}
                />
              </Grid>
            </Grid>
            <Grid align="right" item style={{ marginTop: "10px" }}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.submit}
                  color="primary"
                  onClick={e => handleUpdateReward()}
                >
                  Update
                </Button>
              </div>
            </Grid>
          </div>
        </div>
      </Paper>
      {
        rewardData && Object.keys(rewardData).length > 0 && 
      <HistoryList month={rewardData?.month} year={rewardData?.year} />
      }
    </React.Fragment>
  )
};

export default UpdateReward;