import React from "react";

import {Grid, TextField} from "@material-ui/core";

const CreateZoleForm = ({formik}) => {
    const {handleChange, values, touched, errors} = formik;

    return (
        <Grid container spacing={3}>
            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    id="zole"
                    name="zole"
                    label="Zole"
                    value={values.zole}
                    onChange={handleChange}
                    error={touched.zole && Boolean(errors.zole)}
                    helperText={touched.zole && errors.zole}
                />
            </Grid>

            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    id="price"
                    name="price"
                    label="Price"
                    value={values.price}
                    onChange={handleChange}
                    error={touched.price && Boolean(errors.price)}
                    helperText={touched.price && errors.price}
                />
            </Grid>

            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    id="bonus"
                    name="bonus"
                    label="Bonus"
                    value={values.bonus}
                    onChange={handleChange}
                    error={touched.bonus && Boolean(errors.bonus)}
                    helperText={touched.bonus && errors.bonus}
                />
            </Grid>

            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    id="appleId"
                    name="appleId"
                    label="Apple Id"
                    value={values.appleId}
                    onChange={handleChange}
                    error={touched.appleId && Boolean(errors.appleId)}
                    helperText={touched.appleId && errors.appleId}
                />
            </Grid>
        </Grid>
    );
};

export default CreateZoleForm;
