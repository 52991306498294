import React from "react";

import {Grid, TextField} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const CreatePlanForm = ({formik}) => {
    const {handleChange, values, touched, errors} = formik;

    return (
        <>
        
        
        <Grid container spacing={3}>


        <Grid sm={6} xs={12} item>
            <FormControl style={{width: "100%"}}>
                    <InputLabel id="demo-simple-select-label">
                        Select Plan 
                    </InputLabel>
                    <Select
                    variant="filled"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.name}
                        onChange={handleChange('name')}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                    >
                        <MenuItem value={"1 Day"}>1 Day</MenuItem>
                        <MenuItem value={"1 Week"}>1 Week</MenuItem>
                        <MenuItem value={"1 Month"}>1 Month</MenuItem>
                        <MenuItem value={"3 Months"}>3 Months</MenuItem>
                        <MenuItem value={"6 Months"}>6 Months</MenuItem>
                        <MenuItem value={"1 Year"}>1 Year</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            
           

            {/* <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="validity"
                    disabled
                    name="validity"
                    label="Validity"
                    value={values.validity}
                    onChange={handleChange}
                    error={touched.validity && Boolean(errors.validity)}
                    helperText={touched.validity && errors.validity}
                />
            </Grid> */}

            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="bonus"
                    name="bonus"
                    label="Zole's"
                    value={values.bonus}
                    onChange={handleChange}
                    error={touched.bonus && Boolean(errors.bonus)}
                    helperText={touched.bonus && errors.bonus}
                />
                
            </Grid>
            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    id="amount"
                    name="amount"
                    label="Amount"
                    value={values.amount}
                    onChange={handleChange}
                    error={touched.amount && Boolean(errors.amount)}
                    helperText={touched.amount && errors.amount}
                />
            </Grid>
            <Grid sm={6} xs={12} item>
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    id="apple_id"
                    name="apple_id"
                    label="Apple Id"
                    value={values.apple_id}
                    onChange={handleChange}
                    error={touched.apple_id && Boolean(errors.apple_id)}
                    helperText={touched.apple_id && errors.apple_id}
                />
            </Grid>
        </Grid>
        </>
    );
};

export default CreatePlanForm;
