import React from "react";
import PageContainer from "../../layouts/PageContainer";
import {helpers} from "../../helper/helper";

import {useSnackbar} from "notistack";
import {Grid, Paper, Button, TextField, Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Formik, Form} from "formik";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(4, 0, 1, 0),
    },
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "91%",
    },
}));

const ChangePassword = () => {
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();
    const initialValues = {
        password: '',
        confirm: ''
    };
    const handleSubmit = async (values, action) => {
        let val1 = values.password;
        let val = {
            password: values.password
        };
        let val2 = values.confirm;
        if (val1 === val2) {
            const url = "users/change-password";
            const result = await helpers.apiCall("POST", url, val);
            if (result.status === 200) {

                enqueueSnackbar("Password Change Successfully", {
                    variant: 'success',
                    autoHideDuration: 3000,
                });
            }
        } else {
            enqueueSnackbar("Password Don't match ", {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };

    return (
        <PageContainer pageheading="CHANGE PASSWORD">
            <Paper className={classes.paper}>
            <Box p={3}>
                <Formik
                    enableReinitialize="true"
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {(formik) => {
                        return (
                            <Form autoComplete="off">
                                <Grid container spacing={3}>
                                    <Grid sm={6} xs={12} item>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id="password"
                                            name="password"
                                            label="New Password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid sm={6} xs={12} item>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id="confirm"
                                            name="confirm"
                                            label="Confirm New Password"
                                            value={formik.values.confirm}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className={classes.submit}
                                        color="primary"
                                    >
                                        {"Change"}
                                    </Button>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
                </Box>
            </Paper>
        </PageContainer>
    )

};

export default ChangePassword;