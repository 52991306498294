import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import icon from "../../assets/images/avatar-icon.png";
import { useHistory } from 'react-router-dom';

const DisLikeModal = ({ disLikeModal, handleCloseDisLikeModal, data }) => {

  const history = useHistory();

  return (
    <Modal show={disLikeModal} onHide={handleCloseDisLikeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Likes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="likes-modal-list m-0 p-0">
          {
            data && data?.length > 0 ?
              <>
                {
                  data?.map(disLike => (
                    <li key={disLike?._id} style={{ cursor: "pointer" }} onClick={e => history.push(`/dashboard/users/${disLike?._id}`)}>
                      <img src={disLike?.profilePic ? disLike?.profilePic : icon} alt="" />
                      <span>{disLike?.name}</span>
                    </li>
                  ))
                }
              </> :
              <>
                <div className='text-center'>
                  <h4>No Data Found.</h4>
                </div>
              </>
          }
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDisLikeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

export default DisLikeModal;