import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";

const HideReson = ({ modal, handleCloseModal, handleHide, setReason, reason }) => {

  return (
    <Modal show={modal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Comments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-12'>
            <div className='form-group'>
              <label>Enter Reason</label>
              <textarea className='form-control' value={reason} onChange={e => setReason(e.target.value)} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleCloseModal}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleHide}
        >
          Hide
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

export default HideReson;