import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Button } from "@material-ui/core";
import { Formik, Form } from "formik";
import { helpers } from "../../../helper/helper";
import { PlanValidation } from "../../../helper/ValidationSchema";
import CreatePlanForm from "./CreatePlanForm";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    background: "transparent",
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "91%",
  },
}));

const CreatePlan = (props, { PlanData }) => {
  const Id = props.match.params.id;
  const history = useHistory();
  console.log("sd", Id);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [singlePlan, setSinglePlan] = useState();
  const [planId, setPlanId] = useState();
  const [editCheck, setEditCheck] = useState(false);

  const initialValues = {
    name: "",
    amount: "",
    validity: "",
    bonus: "",
    apple_id: "",
  };

  const editValues = {
    name: singlePlan && singlePlan.name ? singlePlan.name : "",
    amount: singlePlan && singlePlan.amount ? singlePlan.amount : "",
    validity: singlePlan && singlePlan.validity ? singlePlan.validity : "",
    bonus: singlePlan && singlePlan.bonus ? singlePlan.bonus : "",
    apple_id: singlePlan && singlePlan.apple_id ? singlePlan.apple_id : "",
  };

  const handleSubmit = async (values) => {
    const url = "plans";
    const DataPlan = {
      amount: values.amount,
      bonus: values.bonus,
      name: values.name,
      apple_id: values.apple_id,
      
      validity:
        values.name === "1 Day"
          ? "1"
          : values.name === "1 Week"
          ? "7"
          : values.name === "1 Month"
          ? "30"
          : values.name === "3 Months"
          ? "90"
          : values.name === "6 Months"
          ? "180"
          : values.name === "1 Year"
          ? "365"
          : "",
    };
    const result = await helpers.apiCall("POST", url, DataPlan);
    if (result.status === 200) {
      enqueueSnackbar("Plan Created successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
      history.push("/dashboard/plans");
    } else {
      enqueueSnackbar(result.data ? result.data.message : result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const editPlan = async (values) => {
    const url = `plans/${planId}`;

    const result = await helpers.apiCall("PUT", url, values);
    if (result.status === 200) {
      setEditCheck(false);
      enqueueSnackbar("Plan Updated successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
      history.push("/dashboard/plans");
    } else {
      enqueueSnackbar(result.data ? result.data.message : result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const singlePlanById = useCallback(
    async (id) => {
      setPlanId(Id);
      const url = `plans/${Id}`;
      const result = await helpers.apiCall("get", url);
      if (result.status === 200) {
        setSinglePlan(result.data.result);
        setEditCheck(true);
      } else {
        setEditCheck(false);
      }
    },
    [Id]
  );

  useEffect(() => {
    if (Id && !editCheck) {
      singlePlanById();
    }
  }, [singlePlanById, Id, editCheck]);

  return (
    <Paper className={classes.paper} square>
      <Formik
        enableReinitialize="true"
        initialValues={editCheck ? editValues : initialValues}
        onSubmit={editCheck ? editPlan : handleSubmit}
        validationSchema={PlanValidation}
      >
        {(formik) => {
          return (
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Plan Form</h4>
                  <p className="card-description">
                    {" "}
                    Add Plan Form Detail Here{" "}
                  </p>

                  <Form autoComplete="off">
                    <CreatePlanForm formik={formik} />

                    <Grid align="left">
                      <br />
                      <div
                        style={{ textAlign: "right" }}
                        className={classes.wrapper}
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                        >
                          {editCheck ? "UPDATE" : "Create"}
                        </Button>
                        &nbsp;
                        <Button
                          variant="text"
                          onClick={() => history.goBack()}
                          color="primary"
                        >
                          {"cancel"}
                        </Button>
                      </div>
                    </Grid>
                  </Form>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </Paper>
  );
};

export default CreatePlan;
