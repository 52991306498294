import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Button, Checkbox } from "@material-ui/core";
import { Formik, Form } from "formik";
import { helpers } from "../../helper/helper";
import StaticPageFrom from "./StaticPageForm";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import RichTextEditor from "react-rte";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import CountryAndCity from "../../components/StatesAndDistricts.json";

// import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import EmailHistoryList from "./EmailHistoryList";
import {
  LinearProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    background: "transparent",

    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "91%",
  },
}));

const EmailEditor = (props) => {
  const Id = props.match.params.id;
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [singlePlan, setSinglePlan] = useState();
  const [planId, setPlanId] = useState();
  const [editCheck, setEditCheck] = useState(false);
  const [text, setText] = useState(EditorState.createEmpty());
  const [ids, setIds] = useState();
  const [emailHistoryList, setEmailHistoryList] = useState([]);
  const [gender, setGender] = useState("");
  const [selectedAll, setSelectedAll] = useState("false");
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [sendDelete, setSendDelete] = useState(false);
  const [religion, setReligion] = useState("");

  const allReligion = [
    "Hindu",
    "Muslim",
    "Sikh",
    "Christian",
    "Budhhist",
    "Jain",
    "Others",
  ];

  const handleGenderChange = (event) => {
    setGender(event.target.value);
    setSelectedAll("false");
    setSendDelete(false);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
    setSelectedAll("false");
    setSendDelete(false);
  };

  const handleCountryChange = (event) => {
    setState(event.target.value);
    setCity("");
    setSelectedAll("false");
    setSendDelete(false);
  };

  const handleReligionClick = (e) => {
    setReligion(e.target.value);
    setSelectedAll("false");
    setSendDelete(false);
  };

  const handleDeleteClick = () => {
    setSendDelete(!sendDelete);
    setCity("");
    setState("");
    setGender("");
    setSelectedAll("false");
    setReligion("");
  };

  const handleSelectAllChange = (event) => {
    setSelectedAll(event.target.value);
    setSendDelete(false);
    setCity("");
    setState("");
    setGender("");
    setReligion("");
  };

  let initialValues = {
    title: "",
    message: "",
    subTitle: "",
  };

  const handleSubmit = async (values) => {
    if (!values.title.length > 0) {
      return enqueueSnackbar("Please enter details", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    const url = "users/send/email/to/all/users/v1";
    const data = {
      state,
      city,
      selectedAll,
      gender,
      sendDelete,
      religion,
      subject: values.title,
      subTitle: values.subTitle,
      data: draftToHtml(convertToRaw(text.getCurrentContent())),
    };

    console.log(text.getCurrentContent(), "values");
    setLoading(true);
    const result = await helpers.apiCall("POST", url, data);
    setLoading(false);
    if (result.status === 200) {
      enqueueSnackbar("Email Send successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
      emailHistoryData();
      // history.push("/dashboard/staticpage");
    } else {
      setLoading(false);
      console.log(result.data, "data");
      enqueueSnackbar(result.data ? result.data.message : result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  // Email history data:
  const emailHistoryData = useCallback(async () => {
    const url = "users/admin/get/all/email/for/users/v1";
    const result = await helpers.apiCall("GET", url);
    setEmailHistoryList(
      result && result.data && result.data.result ? result.data.result : []
    );
  }, [setEmailHistoryList]);

  useEffect(() => {
    emailHistoryData();
  }, [emailHistoryData]);

  console.log(emailHistoryList, "emailHistoryList");

  // Handle change for the radio buttons
  return (
    <>
      <Paper className={classes.paper} square>
        <Formik
          enableReinitialize="true"
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            return (
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <h4
                    style={{
                      fontWeight: 500,
                      textAlign: "left",
                      marginLeft: "2vh",
                      paddingTop: "2vh",
                    }}
                  >
                    Filters
                  </h4>
                 
                  <Grid container spacing={2} style={{ padding: '2vh' }}>
    <Grid item xs={12} sm={6} md={2}>
      <FormControl fullWidth>
        <InputLabel>Gender</InputLabel>
        <Select value={gender} onChange={handleGenderChange}>
          <MenuItem value="Other"><em>Other</em></MenuItem>
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
        </Select>
      </FormControl>
    </Grid>

    <Grid item xs={12} sm={6} md={2}>
      <FormControl fullWidth>
        <InputLabel>State</InputLabel>
        <Select value={state} onChange={handleCountryChange}>
          <MenuItem value=""><em>None</em></MenuItem>
          {Object.keys(CountryAndCity)
            .sort()
            .map(c => (
              <MenuItem key={c} value={c}>{c}</MenuItem>
            ))}
        </Select>
      </FormControl>
    </Grid>

    <Grid item xs={12} sm={6} md={2}>
      <FormControl fullWidth>
        <InputLabel>City</InputLabel>
        <Select value={city} onChange={handleCityChange}>
          <MenuItem value=""><em>None</em></MenuItem>
          {state && CountryAndCity[state]?.map(c => (
            <MenuItem key={c} value={c}>{c}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>

    <Grid item xs={12} sm={6} md={2}>
      <FormControl fullWidth>
        <InputLabel>Select Religion</InputLabel>
        <Select value={religion} onChange={handleReligionClick}>
          <MenuItem value=""><em>None</em></MenuItem>
          {allReligion?.map(c => (
            <MenuItem key={c} value={c}>{c}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>

    <Grid item xs={12} sm={6} md={2} className="ml-3">
      <FormControl fullWidth>
        <RadioGroup value={selectedAll} onChange={handleSelectAllChange}>
          <FormControlLabel value="false" control={<Radio />} label={<em>None</em>} />
          <FormControlLabel value="true" control={<Radio />} label="Select All Users" />
        </RadioGroup>
      </FormControl>
    </Grid>

    <Grid item xs={12} sm={6} md={2}>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Checkbox checked={sendDelete} onChange={handleDeleteClick} />
          }
          label="Send Only Deleted Users"
        />
      </FormControl>
    </Grid>
  </Grid>
                  <div className="card-body">
                    <h4 className="card-title">Send Email to Users</h4>
                    {/* <p className="card-description">
                  {" "}
                  Add Static Page Detail Here{" "}
                </p> */}
                    <Form autoComplete="off">
                      <StaticPageFrom
                        formik={formik}
                        planId={planId}
                        RichTextEditor={RichTextEditor}
                        setText={setText}
                        text={text}
                      />
                      <Grid align="right" item style={{ marginTop: "10px" }}>
                        <div className={classes.wrapper}>
                          <Button
                            variant="contained"
                            type="submit"
                            className={classes.submit}
                            color="primary"
                          >
                            {loading ? "Sending..." : "Send"}
                          </Button>
                        </div>
                      </Grid>
                    </Form>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </Paper>

      <Grid container spacing={3}>
        <Grid item sm={12} xs={12}>
          <EmailHistoryList emailHistoryList={emailHistoryList} />
        </Grid>
      </Grid>
    </>
  );
};

export default EmailEditor;
