import React, {useEffect, useState, useCallback} from "react";
import PageContainer from "../../layouts/PageContainer";

import {Grid} from "@material-ui/core";
import TaskList from "./TaskList";
import {helpers} from "../../helper/helper";
import Swal from "sweetalert2";
import {useSnackbar} from "notistack";

const Task = () => {
    const {enqueueSnackbar} = useSnackbar();
    const [ChannelsList, setChannelsList] = useState();
    const [check, setCheck] = useState(false);
  

    const TaskData = useCallback(async () => {
        const url = "tasks";
        const result = await helpers.apiCall("GET", url);
        setChannelsList(
            result && result.data && result.data.result ? result.data.result : []
        );
    }, [setChannelsList]);

    useEffect(() => {
        TaskData();
    }, [TaskData]);


    const deleteGifts = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#f50057",
            cancelButtonColor: "#263273",
            confirmButtonText: "Yes, delete it!",
          }).then(async (result) => {
            if (result.value) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              const url = `tasks/${id}`;
        const result = await helpers.apiCall("DELETE", url);
        if (result.status === 200) {
            TaskData();
            enqueueSnackbar("Deleted successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        }
        else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
            }
          });
        
    };
    // const handleChange = () => {
    //     setCheck(!check);
    // };

    return (
        <PageContainer pageheading="TASK">
            <Grid container spacing={3}>
               
                <Grid item sm={12} xs={12}>
                    {/* <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch checked={check} onChange={handleChange} name="check" color="primary"/>
                            }
                            label="Show Tasks List"
                        />
                    </FormGroup> */}

                    <TaskList
                        channelsList={ChannelsList}
                        deleteGifts={deleteGifts}
                        setCheck={setCheck}
                        check={check}
                    />
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Task;
