import React, {useState, useEffect, useCallback} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Paper, Button} from "@material-ui/core";
import {Formik, Form} from "formik";
import {helpers} from "../../../helper/helper";
import CreateInviteForm from "./CreateInviteForm";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        background: 'transparent',

        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(4, 0, 1, 0),
    },
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "91%",
    },
}));

const CreateInvite = (props) => {
    const Id = props.match.params.id;
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();
    const history = useHistory();
    const [img, setImg] = useState(false);
    const [singleGifts, setSingleGifts] = useState();
    const [giftsId, setGiftsId] = useState();
    const [editCheck, setEditCheck] = useState(false);
    const [file, setFile] = useState();
    const [image, setImage] = useState([]);
    const [rewardTypes, setRewardTypes] = React.useState('');
    const [planList, setPlanList] = React.useState([]);

    const handleChanges = (event) => {
        setRewardTypes(event.target.value);
    };
    const initialValues = {
        popularity: '',
        zole: '',
        message:'',
        planId:''
    };
console.log("singleGifts", singleGifts);
    const editValues = {
        popularity: singleGifts && singleGifts.popularity ? singleGifts.popularity : "",
        zole: singleGifts && singleGifts.zole ? singleGifts.zole : "",
        message:singleGifts && singleGifts.message ? singleGifts.message : "",
        planId:singleGifts && singleGifts.planId ? singleGifts.planId : ""
    };

    const handleSubmit = async (values, action) => {
        let formData = new FormData();
        formData.append("title", values.title);
        formData.append("link", values.link);
        formData.append("reward", values.reward);
        formData.append("rewardType", rewardTypes ? rewardTypes : '');

        const url = "tasks";
        const result = await helpers.apiCallForm("POST", url, formData);
        if (result.status === 200) {
            setRewardTypes('');
            setImg(true);
            enqueueSnackbar("Created successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
            history.push("/dashboard/tasks")
        }
        else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
        setImg(false);
    };

    const editChannels = async (values) => {
        const url = `reward/${giftsId}`;
        const result = await helpers.apiCall("PUT", url, values);
        if (result.status === 200) {
            setEditCheck(false);
            setSingleGifts();
            setImage([]);
            enqueueSnackbar("Updated  successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
            history.push("/dashboard/invite")
        }
        else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };

    const singleGiftsById = useCallback(async (id) => {
        setGiftsId(Id);
        const url = `reward/${Id}`;
        const result = await helpers.apiCall("get", url);
        if (result.status === 200) {
            setSingleGifts(result.data.result);
            setEditCheck(true);
        } else {
            setEditCheck(false);
        }
        console.log('resulw', result)
    }, [Id]);

    useEffect(() => {
        if (Id && !editCheck) {
            singleGiftsById()
        }
    }, [singleGiftsById, Id, editCheck]);

    const PlanData = useCallback(async () => {
        const url = "plans";
        const result = await helpers.apiCall("GET", url);
        setPlanList(
          result && result.data && result.data.result ? result.data.result : []
        );
    }, [setPlanList]);

    useEffect(() => {
        PlanData();
    }, [PlanData]);

    return (
        <Paper className={classes.paper} square>
            <Formik
                enableReinitialize="true"
                initialValues={editCheck ? editValues : initialValues}
                onSubmit={editCheck ? editChannels : handleSubmit}
            >
                {(formik) => {
                    return (
                        <div className="col-md-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Invite Form</h4>
                                    <p className="card-description"> Add Invite Form Detail Here </p>
                                    <Form autoComplete="off">
                                        <CreateInviteForm
                                            formik={formik}
                                            setFile={setFile}
                                            file={file}
                                            singleGifts={singleGifts}
                                            setSingleGifts={setSingleGifts}
                                            image={image}
                                            setImage={setImage}
                                            img={img}
                                            handleChanges={handleChanges}
                                            rewardTypes={rewardTypes}
                                            planList={planList}
                                        />
                                        <Grid align="right" item style={{marginTop: "10px"}}>
                                            <div className={classes.wrapper}>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    className={classes.submit}
                                                    color="primary"
                                                >
                                                    {editCheck ? "UPDATE" : "Create"}
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Formik>
        </Paper>
    );
};

export default CreateInvite;
