import React, { useState, useCallback, useEffect } from "react";
import { helpers } from "../../helper/helper";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import { Avatar, Typography } from "@material-ui/core";
import img from "../../assets/images/check-circle.gif";

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  city: "Los Angeles",
  country: "USA",
  jobTitle: "Senior Developer",
  name: "Katarina Smith",
  timezone: "GTM-7",
};

const PaymentHistoryContainer = (props) => {
  const [values, setValues] = useState({
    firstName: "Katarina",
    lastName: "Smith",
    email: "demo@devias.io",
    phone: "",
    state: "Alabama",
    country: "USA",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const Id = props.match.params.id;
  const [userData, setUserData] = useState();

  const UsersData = useCallback(async () => {
    const url = `history/${Id}`;
    const result = await helpers.apiCall("GET", url);
    setUserData(result.data.result);
  }, [setUserData, Id]);

  useEffect(() => {
    UsersData();
  }, [UsersData]);
console.log(userData)
  return (
    <div>
      <Card
        {...props}
        style={{ display: "inline-block", verticalAlign: "top", width: "100%" }}
      >
        <CardContent>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Avatar
              style={{ width: "100px", height: "100px" }}
              src={
                userData && userData.userId && userData.userId.profilePic
                  ? userData.userId.profilePic
                  : user.avatar
              }
              sx={{
                height: 100,
                width: 100,
              }}
            />
            <Typography color="textPrimary" gutterBottom variant="h3">
              {userData && userData.userId && userData.userId.name
                ? userData.userId.name
                : "name"}
            </Typography>
            <Typography color="textSecondary" variant="body1">
              {`${
                userData && userData.userId && userData.userId.district
                  ? userData.userId.district
                  : "District"
              }, ${
                userData && userData.userId && userData.userId.country
                  ? userData.userId.country
                  : "Country"
              }`}
            </Typography>
            <Typography color="textSecondary" variant="body1"></Typography>
          </Box>
        </CardContent>
        <Divider />
      </Card>
      &nbsp;&nbsp;
      <form
        autoComplete="off"
        noValidate
        {...props}
        style={{ display: "inline-block", width: "100%" }}
      >
        <Card>
         {userData?.status?<CardHeader
            title={
              <div>
                <p style={{ display: "inline-block" }}>Payment Successful</p>{" "}
                &nbsp;
                <img
                  src={img}
                  style={{
                    verticalAlign: "middle",
                    color: "green",
                    height: "40px",
                  }}
                  alt="success"
                />
              </div>
            }
          />:<CardHeader
          title={
            <div>
              <p style={{ display: "inline-block" }}>Payment Fail</p>{" "}
              &nbsp;
                  <CancelIcon
                    style={{ verticalAlign: "middle", color: "red" }}
                  />
            </div>
          }
        />}
          <CardHeader
            subheader={
              userData &&
              userData.userId &&
              userData.userId.isProfilePicVerified ? (
                userData.userId.isProfilePicVerified ? (
                  <div>
                    <p style={{ display: "inline-block" }}>Profile Verified</p>{" "}
                    &nbsp;
                    <CheckCircleIcon
                      style={{ verticalAlign: "middle", color: "green" }}
                    />
                  </div>
                ) : (
                  ""
                )
              ) : (
                <div>
                  <p style={{ display: "inline-block" }}>Profile Verified</p>{" "}
                  &nbsp;
                  <CancelIcon
                    style={{ verticalAlign: "middle", color: "red" }}
                  />
                </div>
              )
            }
            title="Profile"
          />

          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  required
                  value={
                    userData && userData.userId && userData.userId.email
                      ? userData.userId.email
                      : "email"
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Mobile"
                  required
                  value={
                    userData && userData.userId && userData.userId.mobile
                      ? userData.userId.mobile
                      : "email"
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Divider />
                <CardHeader title="Payment Details" />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Payment Id"
                  required
                  value={
                    userData && userData.paymentId
                      ? userData.paymentId
                      : "Payment Id"
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Order Id"
                  required
                  value={
                    userData && userData.orderId
                      ? userData.orderId
                      : "Order Id"
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Amount"
                  onChange={handleChange}
                  required
                  value={
                    userData && userData.amount ? userData.amount : "amount"
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Device Id"
                  required
                  value={
                    userData && userData.deviceId
                      ? userData.deviceId
                      : "Device Id"
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Platform"
                  type="text"
                  value={
                    userData && userData.platform
                      ? userData.platform
                      : "Plat form"
                  }
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Payment Type"
                  required
                  value={
                    userData && userData.paymentType
                      ? userData.paymentType
                      : "Payment Type"
                  }
                  variant="outlined"
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <br />
                <Divider />
                <CardHeader title="Plan Details" />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Plan Name"
                  required
                  value={
                    userData && userData.planId && userData.planId.name
                      ? userData.planId.name
                      : "Name"
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Amount"
                  required
                  value={
                    userData && userData.planId && userData.planId.amount
                      ? userData.planId.amount
                      : "Amount"
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Bonus"
                  required
                  value={
                    userData && userData.planId && userData.planId.bonus
                      ? userData.planId.bonus
                      : "bonus"
                  }
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </form>
    </div>
  );
};

export default PaymentHistoryContainer;
