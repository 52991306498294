import React from "react";
import {IconButton, LinearProgress, Paper , Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";

const sortIcon = <ArrowDownward/>;

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        table: {
            minWidth: 650,
        }
    },
    btn: {
        margin: theme.spacing(4)
      }
}));

const ZoleList = ({planList, editPlan, deletePlan, check, singlePlanById}) => {
    const classes = useStyles();

    const heading = [
        {
            name: "Sr. No",
            wrap: true,
            sortable: true,
            cell: (row, index) => index + 1,
        }, {
            name: "Zole",
            selector: "zole",
            wrap: true,
            sortable: true,
        }, {
            name: "Price",
           
            wrap: true,
            sortable: true,
            cell:(planList)=>(
                <p>
                    {"RS "+planList.price}
                </p>
            )
        }, {
            name: "Bonus",
            selector: "bonus",
            wrap: true,
            sortable: true,
        }, {
            name: "Apple Id",
            selector: "appleId",
            wrap: true,
            sortable: true,
        }, {
            name: "Action",
            wrap: true,
            center: true,
            cell: (planList) => (
                <div>
                    <Link to={"/dashboard/create-zole/"+planList._id}>
                    <IconButton
                        edge="end"
                        aria-label="edit"
                       
                    >
                        <EditIcon color="primary"/>
                    </IconButton>
                    </Link>
                    <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                            deletePlan(planList._id)
                        }}
                    >
                        <DeleteIcon color="primary"/>
                    </IconButton>
                </div>
            )
        }
    ];

    return (
        <div>
            {!planList ? (
                <LinearProgress color="primary"/>
            ) : (
                <Paper className={classes.paper} square>
                     <Link to='/dashboard/create-zole'><Button variant="contained" color="primary" className={classes.btn}>Add Zole</Button></Link>
                    {!check ?
                        <DataTable
                            columns={heading}
                            noHeader={true}
                            data={planList}
                            pagination
                            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                            sortIcon={sortIcon}
                            striped={true}
                            highlightOnHover={true}
                            responsive={true}
                        />
                        : ''}
                </Paper>
            )}
        </div>
    );
};

export default ZoleList;
