import React, { useState, useCallback, useEffect } from "react";
import { helpers } from "../../helper/helper";
import { Formik, Form } from "formik";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ScrollToTop from "react-scroll-to-top";
import Swal from "sweetalert2";
const moment = require("moment"); // or import { moment } from 'moment';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  city: "Los Angeles",
  country: "USA",
  jobTitle: "Senior Developer",
  name: "Katarina Smith",
  timezone: "GTM-7",
};

const UserContainer = (props) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const Id = props.match.params.id;
  const [userData, setUserData] = useState();
  const [newDate, setNewDate] = useState();
  const [open, setOpen] = React.useState(false);
  const [openPic, setOpenPic] = React.useState(false);
  const [image, setImage] = useState([]);

  const UsersData = useCallback(async () => {
    const url = `users/${Id}`;
    const result = await helpers.apiCall("GET", url);
    console.log("reslut", result);
    setUserData(result.data.result);
    if (result.data.result.isSubscribed) {
      let dates = result.data.result.subscription[0].createdAt.split("t");
      let dayscount = result.data.result.subscription[0].planId.validity
        ? result.data.result.subscription[0].planId.validity
        : "";
      let newdate = addDays(dates[0], parseInt(dayscount));
      setNewDate(newdate);
    }
  }, [setUserData, Id]);

  useEffect(() => {
    UsersData();
  }, [UsersData]);

  const addDays = (dates, days) => {
    let date = new Date(dates);
    date.setDate(date.getDate() + days);
    return date;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpen = () => {
    setOpenPic(true);
  };

  const handleClose = () => {
    setOpenPic(false);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const verifyUser = async (checkKyc) => {
    const url = checkKyc === 0 ? `users/verify/${Id}` : `users/reject/${Id}`;
    const result = await helpers.apiCall("PUT", url);
    if (result.status === 200) {
      handleClickClose();
      UsersData();
      handleClose();

      enqueueSnackbar(checkKyc === 0 ? "User Verified" : "Kyc Operation Done", {
        variant: "success",
        autoHideDuration: 3000,
      });
      history.push("/dashboard/kycreq");
    } else {
      enqueueSnackbar(result.data ? result.data.message : result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const initialValues = {
    name: userData && userData.name ? userData.name : "Name",
    age: userData && userData.age ? userData.age : "Age",
    gender: userData && userData.gender ? userData.gender : "Gender",
    dob: userData && userData.dob ? userData.dob : "DOB",
    number:
      userData && userData.mobile ? parseInt(userData.mobile) : "Mobile Number",
    email: userData && userData.email ? userData.email : "Email",
    profilePic: userData && userData.profilePic ? userData.profilePic : "",
    country: userData && userData.country ? userData.country : "Country",
    state: userData && userData.state ? userData.state : "State",
    district: userData && userData.district ? userData.district : "",
    userId: userData && userData.userId ? userData.userId : "Zolemate Id",
  };

  const handleSubmit = async (values, action) => {
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("age", values.age);
    formData.append("gender", values.gender);
    formData.append("dob", values.dob);
    formData.append("mobile", values.number);
    formData.append("email", values.email);
    formData.append("profilePic", image ? image[0] : values.profilePic);

    const url = "users";
    const result = await helpers.apiCallForm("PUT", url, formData);
    if (result.status === 200) {
      enqueueSnackbar("User Updated successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });

      action.resetForm();
      setImage([]);
      history.push("/dashboard/users");
    } else {
      enqueueSnackbar(result.data ? result.data.message : result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleRedirectUser = (redirectUrl) => {
    history.push(redirectUrl);
    return <ScrollToTop smooth />;
  };

  const BlockUser = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't to Block /Unblock ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f50057",
      cancelButtonColor: "#263273",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        Swal.fire("", "", "success");
        const url = `users/block/${id}`;
        const result = await helpers.apiCall("PUT", url);
        if (result.status === 200) {
          UsersData();
          enqueueSnackbar("User BLocked successfully", {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(result.data ? result.data.message : result.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      }
    });
  };
  console.log(userData, "userData.memberShipHistory");
  const formattedDate = (date) => {
    return moment(date).format("MMMM Do YYYY, h:mm A");
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "end",
          marginRight: "10px",
          marginTop: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: "10px", marginLeft: "10px" }}
          onClick={(e) =>
            history.push(`/dashboard/users/update/details/${userData._id}`)
          }
        >
          UPDATE DETAILS
        </Button>
        <Button
          color={"primary"}
          variant="outlined"
          size="small"
          onClick={() => {
            BlockUser(userData?._id);
          }}
        >
          {userData && userData?.isBlocked ? "UnBlock" : "Block"}
        </Button>
      </div>
      <Formik
        enableReinitialize="true"
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <>
              <Box mt={4}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                  columnSpacing={5}
                  rowSpacing={4}
                >
                  <Grid item xs={6} md={6}>
                    <Card {...props}>
                      <CardContent>
                        <Box sx={{ p: 2 }}>
                          <img
                            style={{ width: "100px", height: "100px" }}
                            src={
                              userData && userData.profilePic
                                ? userData.profilePic
                                : user.avatar
                            }
                            onClick={handleOpen}
                            alt="img"
                          />
                          <Dialog
                            open={openPic}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            style={{ textAlign: "center" }}
                          >
                            <DialogTitle id="alert-dialog-title">
                              {"Profile Image"}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                <img
                                  src={
                                    userData && userData.profilePic
                                      ? userData.profilePic
                                      : user.avatar
                                  }
                                  style={{ width: "30%", height: "50%" }}
                                  alt="img   "
                                />
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose} color="primary">
                                close
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h3"
                          >
                            <div>
                              <p style={{ display: "inline-block" }}>
                                {userData && userData.name
                                  ? userData.name
                                  : "name"}
                              </p>
                            </div>
                          </Typography>
                        </Box>
                      </CardContent>
                      <Divider />
                    </Card>
                    &nbsp; &nbsp; &nbsp;
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <Card {...props}>
                      <CardContent>
                        <Box sx={{ p: 2 }}>
                          <img
                            style={{ width: "100px", height: "100px" }}
                            src={
                              userData && userData.kycImage
                                ? userData.kycImage
                                : user.avatar
                            }
                            onClick={handleClickOpen}
                            alt="img"
                          />
                          <Dialog
                            open={open}
                            onClose={handleClickClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            style={{ textAlign: "center" }}
                          >
                            <DialogTitle id="alert-dialog-title">
                              {"KYC Image"}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                <img
                                  src={
                                    userData && userData.kycImage
                                      ? userData.kycImage
                                      : user.avatar
                                  }
                                  style={{ width: "30%", height: "50%" }}
                                  alt="img   "
                                />
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              {userData && userData.isProfilePicVerified ? (
                                ""
                              ) : (
                                <Button
                                  onClick={(e) => verifyUser(0)}
                                  color="primary"
                                  variant="contained"
                                >
                                  Verify
                                </Button>
                              )}
                              {userData && userData.isProfilePicVerified ? (
                                ""
                              ) : (
                                <Button
                                  onClick={(e) => verifyUser(1)}
                                  color="primary"
                                  variant="contained"
                                >
                                  Reject
                                </Button>
                              )}
                              <Button
                                onClick={handleClickClose}
                                color="primary"
                                variant="outlined"
                              >
                                Cancel
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h3"
                          >
                            {userData && userData.isProfilePicVerified ? (
                              <div>
                                <p style={{ display: "inline-block" }}>
                                  Verified
                                </p>
                                &nbsp;
                                <CheckCircleIcon
                                  style={{
                                    verticalAlign: "middle",
                                    color: "green",
                                  }}
                                />
                              </div>
                            ) : (
                              <div>
                                <p style={{ display: "inline-block" }}>
                                  Not verified
                                </p>
                                &nbsp;
                                <CancelIcon
                                  style={{
                                    verticalAlign: "middle",
                                    color: "red",
                                  }}
                                />
                              </div>
                            )}
                          </Typography>
                        </Box>
                      </CardContent>
                      <Divider />
                    </Card>
                    &nbsp;&nbsp;
                  </Grid>
                </Grid>
              </Box>
              <Form autoComplete="off">
                <Card>
                  <CardHeader title="Profile" />
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={4}
                      columnSpacing={{ xs: 2, sm: 2, md: 3 }}
                    >
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="ZOLEMATE ID"
                          value={
                            userData && userData.referCode
                              ? userData.userId
                              : "Zolmate ID"
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Name"
                          required
                          value={formik.values.name}
                          variant="outlined"
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="D.O.B"
                          value={formik.values.dob}
                          required
                          onChange={formik.handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Phone Number"
                          onChange={formik.handleChange}
                          type="number"
                          value={formik.values.number}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Email"
                          value={formik.values.email}
                          variant="outlined"
                          onChange={formik.handleChange}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="REFER CODE"
                          value={
                            userData && userData.referCode
                              ? userData.referCode
                              : "Refer Code"
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="SUBSCRIBED"
                          value={
                            userData && userData.isSubscribed
                              ? "Active"
                              : "Inactive"
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Plan activated on"
                          value={
                            userData && userData.isSubscribed
                              ? new Date(
                                  userData.subscription[0].createdAt
                                ).toLocaleDateString()
                              : ""
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Expires On"
                          value={
                            newDate
                              ? new Date(newDate).toLocaleDateString()
                              : ""
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="About Status"
                          required
                          value={
                            userData && userData.aboutStatus
                              ? userData.aboutStatus
                              : ""
                          }
                          variant="outlined"
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Matches"
                          required
                          value={
                            userData && userData.matchCount
                              ? userData.matchCount
                              : ""
                          }
                          variant="outlined"
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Popularity"
                          required
                          value={
                            userData && userData.popularity
                              ? userData.popularity
                              : ""
                          }
                          variant="outlined"
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Wallet Point"
                          required
                          value={
                            userData && userData.wallet ? userData.wallet : ""
                          }
                          variant="outlined"
                          onChange={formik.handleChange}
                        />
                      </Grid>

                      <React.Fragment>
                        <Grid item md={12} xs={12}>
                          <br />
                          <Divider />
                          <CardHeader title="Basic Information" />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Gender"
                            onChange={formik.handleChange}
                            value={formik.values.gender}
                            required
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Country"
                            onChange={formik.handleChange}
                            value={formik.values.country}
                            required
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="State"
                            onChange={formik.handleChange}
                            value={formik.values.state}
                            required
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="District"
                            onChange={formik.handleChange}
                            value={formik.values.district}
                            required
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Religion"
                            value={
                              userData && userData.religion
                                ? userData.religion
                                : "religion"
                            }
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Relationship Status  "
                            value={
                              userData && userData.relationshipStatus
                                ? userData.relationshipStatus
                                : "Relationship Status"
                            }
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Looking for on Zolemate "
                            value={
                              userData && userData.lookingFor
                                ? userData.lookingFor
                                : ""
                            }
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Blood Group"
                            value={
                              userData && userData.bloodGroup
                                ? userData.bloodGroup
                                : ""
                            }
                            variant="outlined"
                          />
                        </Grid>
                      </React.Fragment>

                      <React.Fragment>
                        <Grid item md={12} xs={12}>
                          <br />
                          <Divider />
                          <CardHeader title="Looks And Personality" />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Height"
                            value={
                              userData && userData.height
                                ? userData.height
                                : "height"
                            }
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Body Type"
                            value={
                              userData && userData.bodyType
                                ? userData.bodyType
                                : "body type"
                            }
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Age"
                            required
                            value={formik.values.age}
                            variant="outlined"
                            onChange={formik.handleChange}
                          />
                        </Grid>
                      </React.Fragment>

                      <React.Fragment>
                        <Grid item md={12} xs={12}>
                          <br />
                          <Divider />
                          <CardHeader title="Life Style" />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Smoking"
                            value={userData && userData.smoker ? "Yes" : "No"}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Drink"
                            value={userData && userData.drunker ? "Yes" : "No"}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Education"
                            required
                            value={
                              userData && userData.education
                                ? userData.education
                                : ""
                            }
                            variant="outlined"
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Workspace"
                            required
                            value={
                              userData && userData.workspace
                                ? userData.workspace
                                : ""
                            }
                            variant="outlined"
                            onChange={formik.handleChange}
                          />
                        </Grid>
                      </React.Fragment>
                      {userData && userData.isSubscribed ? (
                        <React.Fragment>
                          <Grid item md={12} xs={12}>
                            <br />
                            <Divider />
                            <CardHeader title="Plan Details" />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              label="Plan Name"
                              required
                              value={
                                userData &&
                                userData.subscription[0].planId &&
                                userData.subscription[0].planId.name
                                  ? userData.subscription[0].planId.name
                                  : "name"
                              }
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              label="Amount"
                              required
                              value={
                                userData &&
                                userData.subscription[0].planId &&
                                userData.subscription[0].planId.amount
                                  ? userData.subscription[0].planId.amount
                                  : "Amount"
                              }
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              label="Bonus"
                              required
                              value={
                                userData &&
                                userData.subscription[0].planId &&
                                userData.subscription[0].planId.bonus
                                  ? userData.subscription[0].planId.bonus
                                  : "bonus"
                              }
                              variant="outlined"
                            />
                          </Grid>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </CardContent>
                  <Divider />
                </Card>
              </Form>
            </>
          );
        }}
      </Formik>
      {userData && userData.matches && userData.matches.length ? (
        <TableContainer component={Paper}>
          <br />
          <br />

          <CardHeader title="Matches List" />
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  User Id
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ backgroundColor: "blue" }}
                >
                  Profile Image
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ backgroundColor: "blue" }}
                >
                  Email
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ backgroundColor: "blue" }}
                >
                  Phone Number
                </StyledTableCell>

                <StyledTableCell
                  align="right"
                  style={{ backgroundColor: "blue" }}
                >
                  Profile Verified
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ backgroundColor: "blue" }}
                >
                  Country
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ backgroundColor: "blue" }}
                >
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.matches.map((row, index) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.userId}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.profilePic ? (
                      <img
                        style={{ width: "50px", height: "50px" }}
                        src={row.profilePic}
                        alt="img"
                      />
                    ) : (
                      ""
                    )}
                  </StyledTableCell>

                  <StyledTableCell align="center">{row.email}</StyledTableCell>
                  <StyledTableCell align="right">{row.mobile}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.isProfilePicVerified ? "Verified" : "Not Verified"}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.country}</StyledTableCell>
                  <StyledTableCell align="right">
                    {/* <Link to={"/dashboard/users/" + row._id}> */}
                    <VisibilityIcon
                      onClick={(e) =>
                        handleRedirectUser("/dashboard/users/" + row._id)
                      }
                    />
                    {/* </Link> */}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
      {userData && userData.followers && userData.followers.length ? (
        <TableContainer component={Paper}>
          <br />
          <br />

          <CardHeader title="Follower List" />
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  User Id
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ backgroundColor: "blue" }}
                >
                  Profile Image
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ backgroundColor: "blue" }}
                >
                  Email
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ backgroundColor: "blue" }}
                >
                  Phone Number
                </StyledTableCell>

                <StyledTableCell
                  align="right"
                  style={{ backgroundColor: "blue" }}
                >
                  Profile Verified
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ backgroundColor: "blue" }}
                >
                  Country
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ backgroundColor: "blue" }}
                >
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.followers.map((row, index) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.userId}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.profilePic ? (
                      <img
                        style={{ width: "50px", height: "50px" }}
                        src={row.profilePic}
                        alt="img"
                      />
                    ) : (
                      ""
                    )}
                  </StyledTableCell>

                  <StyledTableCell align="center">{row.email}</StyledTableCell>
                  <StyledTableCell align="right">{row.mobile}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.isProfilePicVerified ? "Verified" : "Not Verified"}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.country}</StyledTableCell>
                  <StyledTableCell align="right">
                    {/* <Link to={"/dashboard/users/" + row._id}> */}
                    <VisibilityIcon
                      onClick={(e) =>
                        handleRedirectUser("/dashboard/users/" + row._id)
                      }
                    />
                    {/* </Link> */}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
      {userData && userData.followings && userData.followings.length ? (
        <TableContainer component={Paper}>
          <br />
          <br />

          <CardHeader title="Following List" />
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  User Id
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ backgroundColor: "blue" }}
                >
                  Profile Image
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ backgroundColor: "blue" }}
                >
                  Email
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ backgroundColor: "blue" }}
                >
                  Phone Number
                </StyledTableCell>

                <StyledTableCell
                  align="right"
                  style={{ backgroundColor: "blue" }}
                >
                  Profile Verified
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ backgroundColor: "blue" }}
                >
                  Country
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ backgroundColor: "blue" }}
                >
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.followings.map((row, index) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.userId}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.profilePic ? (
                      <img
                        style={{ width: "50px", height: "50px" }}
                        src={row.profilePic}
                        alt="img"
                      />
                    ) : (
                      ""
                    )}
                  </StyledTableCell>

                  <StyledTableCell align="center">{row.email}</StyledTableCell>
                  <StyledTableCell align="right">{row.mobile}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.isProfilePicVerified ? "Verified" : "Not Verified"}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.country}</StyledTableCell>
                  <StyledTableCell align="right">
                    {/* <p to={"/dashboard/users/" + row._id}> */}
                    <VisibilityIcon
                      onClick={(e) =>
                        handleRedirectUser("/dashboard/users/" + row._id)
                      }
                    />
                    {/* </p> */}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
      {/* zoles history */}
      {userData && userData.zolesHistory && userData.zolesHistory.length ? (
        <TableContainer component={Paper}>
          <br />
          <br />

          <CardHeader title="Zoles History List" />
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Count
                </StyledTableCell>

                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Message
                </StyledTableCell>

                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Type
                </StyledTableCell>

                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Date
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.zolesHistory.map((row, index) => (
                <StyledTableRow key={row.message}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.message}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.type}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {formattedDate(row.createdAt)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
      {/* zems history */}
      {userData && userData.zemsHistory && userData.zemsHistory.length ? (
        <TableContainer component={Paper}>
          <br />
          <br />
          <CardHeader title="Zems History List" />
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Count
                </StyledTableCell>

                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Message
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Type
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Date
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.zemsHistory.map((row, index) => (
                <StyledTableRow key={row.message}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.message}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.type}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {formattedDate(row.createdAt)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
      {/* Membership history */}
      {userData &&
      userData.memberShipHistory &&
      userData.memberShipHistory.length ? (
        <TableContainer component={Paper}>
          <br />
          <br />
          <CardHeader title="Membership List" />
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Sr. No
                </StyledTableCell>{" "}
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Id
                </StyledTableCell>{" "}
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Email
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Message
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Amount
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Validity
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "blue" }}>
                  Date
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.memberShipHistory.map((row, index) => (
                <StyledTableRow key={row.message}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row?.userId?.userId}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row?.userId?.name}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row?.userId?.email}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row?.message||"-"}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row?.planId?.amount}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row?.planId?.name}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {formattedDate(row?.createdAt)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default UserContainer;
