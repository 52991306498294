import { Button, FormHelperText, Grid, InputLabel, MenuItem, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { helpers } from "../../helper/helper";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "91%",
  },
}));

const UpdateRazorpayKey = () => {

  const [keySecret, setKeySecret] = useState("");
  const [keyId, setKeyId] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getRazorpayKey();
  }, []);

  const getRazorpayKey = async () => {
    const url = `razorpay/admin/get/v1`;
    const result = await helpers.apiCall("GET", url);
    setKeySecret(result && result.data && result.data.result && result.data.result.response.keySecret);
    setKeyId(result && result.data && result.data.result && result.data.result.response.keyId);
  };

  const handleUpdateRazorpayKey = async () => {
    const url = `razorpay/update/v1`;
    const result = await helpers.apiCall("POST", url, { keySecret, keyId });
    if (result.status === 200) {
      enqueueSnackbar("Updated successfully", {
        variant: 'success',
        autoHideDuration: 3000,
      });
      history.push("/dashboard/razorpay");
    }
    else {
      enqueueSnackbar(result.data ? result.data.result.message : result.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper} square>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Update Profile Verification</h4>
            <Grid container spacing={3} style={{ marginTop: "30px" }}>
              <Grid sm={12} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="keySecret"
                  name="keySecret"
                  label="Key Secret"
                  value={keySecret}
                  onChange={e => setKeySecret(e.target.value)}
                />
              </Grid>
              <Grid sm={12} xs={12} item className="mt-3">
                <TextField
                  fullWidth
                  variant="outlined"
                  id="keyId"
                  name="keyId"
                  label="Key Id"
                  value={keyId}
                  onChange={e => setKeyId(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid align="right" item style={{ marginTop: "10px" }}>
              <div>
                <Button
                  variant="contained"
                  color="success"
                  onClick={e => handleUpdateRazorpayKey()}
                >
                  Update
                </Button>
              </div>
            </Grid>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  )
};

export default UpdateRazorpayKey;