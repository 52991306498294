import React, {useState , useEffect , useCallback} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Paper, Button} from "@material-ui/core";
import {Formik, Form} from "formik";
import {helpers} from "../../../helper/helper";
import {BannerValidation} from "../../../helper/ValidationSchema";
import CreateBannerForm from "./CreateBannerForm";
import {useSnackbar} from "notistack";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        background: 'transparent',
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(4, 0, 1, 0),
    },
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "91%",
    },
}));

const CreateBanner = (props) => {
    const Id = props.match.params.id
    const classes = useStyles();
    const history = useHistory()
    const {enqueueSnackbar} = useSnackbar();
    const [img, setImg] = useState(false);
    const [singleGifts, setSingleGifts] = useState();
    const [giftsId, setGiftsId] = useState();
    const [editCheck, setEditCheck] = useState(false);
    const [file, setFile] = useState();
    const [image, setImage] = useState([]);
    const [types, setTypes] = React.useState('');
    const [title, setTitle] = React.useState('');

    const handleChanges = (event) => {
        setTypes(event.target.value);
    };

    const handleChangeTitle = (event) => {
        setTitle(event.target.value);
    };
    const initialValues = {
        type: '',
        // title: "",
        image: "",
        link: "",
    };

    const editValues = {
        type: singleGifts && singleGifts.type ? singleGifts.type : "",
        // title: singleGifts && singleGifts.title ? singleGifts.title : "",
        link: singleGifts && singleGifts.link ? singleGifts.link : "",
        image: singleGifts && singleGifts.image ? singleGifts.image : "",
    };

    const handleSubmit = async (values, action) => {
        let formData = new FormData();
        // formData.append("title", title ? title : '');
        formData.append("link", values.link);
        formData.append("image", image ? image[0] : null);
        formData.append("type", types ? types : null);

        const url = "banners";
        const result = await helpers.apiCallForm("POST", url, formData);
        if (result.status === 200) {
         
            setImg(true);
            enqueueSnackbar("Banner Created successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
            history.push("/dashboard/banner")
        }
        else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
        setImg(false)
    };


    const editBanner = async (values, {resetForm}) => {
        let formData = new FormData();
        // formData.append("title", title ? title :values.title);
        formData.append("link", values.link);
        formData.append("image", image ? image[0] : singleGifts.image);
        formData.append("type", types ? types : values.type);
        const url = `banners/${giftsId}`;
        const result = await helpers.apiCallForm("PUT", url, formData);
        if (result.status === 200) {
          
            setEditCheck(false);
            setSingleGifts();
            setImage([]);
            enqueueSnackbar("Banner updated successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
            history.push("/dashboard/banner")
        }
        else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };

    const singleGiftsById = useCallback(async (id) => {
        setGiftsId(Id);
        const url = `banners/${Id}`;
        const result = await helpers.apiCall("get", url);
        if (result.status === 200) {
            setSingleGifts(result.data.result);
            setEditCheck(true);
        } else {
            setEditCheck(false);
        }
    },[Id])

    useEffect(() => {
        if(Id && !editCheck){
            singleGiftsById()
        }
    }, [singleGiftsById , Id , editCheck]);

    return (
        <Paper className={classes.paper} square>
            <Formik
                enableReinitialize="true"
                initialValues={editCheck ? editValues : initialValues}
                onSubmit={editCheck ? editBanner : handleSubmit}
                validationSchema={
                    BannerValidation
                }
            >
                {(formik) => {
                    return (
                        <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                          <div className="card-body">
                            <h4 className="card-title">Banner Form</h4>
                            <p className="card-description"> Add Banner Form Detail Here </p>
                        <Form autoComplete="off">
                            <CreateBannerForm formik={formik} setFile={setFile} file={file} singleGifts={singleGifts}
                                              setSingleGifts={setSingleGifts}
                                              image={image}
                                              setImage={setImage} img={img} types={types}
                                              handleChanges={handleChanges}
                                              handleChangeTitle={handleChangeTitle}
                                              title={title}
                            />
                            <Grid align="right" item style={{marginTop: "10px"}}>
                                <div className={classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className={classes.submit}
                                        color="primary"
                                    >
                                        {editCheck ? "UPDATE" : "Create"}
                                    </Button>
                                </div>
                            </Grid>
                        </Form>
                        </div>
                        </div>
                        </div>
                    );
                }}
            </Formik>
        </Paper>
    );
};

export default CreateBanner;
