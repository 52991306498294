import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import icon from "../../assets/images/avatar-icon.png";
import { useHistory } from 'react-router-dom';

const LikeModal = ({ likeModal, handleCloseLikeModal, data }) => {

  const history = useHistory();

  return (
    <Modal show={likeModal} onHide={handleCloseLikeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Likes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="likes-modal-list m-0 p-0">
          {
            data && data?.length > 0 ?
              <>
                {
                  data?.map(like => (
                    <li key={like?._id} style={{ cursor: "pointer" }} onClick={e => history.push(`/dashboard/users/${like?._id}`)}>
                      <img src={like?.profilePic ? like?.profilePic : icon} alt="" />
                      <span>{like?.name}</span>
                    </li>
                  ))
                }
              </> :
              <>
                <div className='text-center'>
                  <h4>No Data Found.</h4>
                </div>
              </>
          }
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseLikeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

export default LikeModal;