import React, { useEffect, useState, useCallback } from "react";
import PageContainer from "../../layouts/PageContainer";

import { Grid } from "@material-ui/core";
import GiftsList from "./GiftsList";
import { helpers } from "../../helper/helper";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";

const Gifts = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [giftsList, setGiftsList] = useState();
  const [check, setCheck] = useState(true);

  const GiftsData = useCallback(async () => {
    const url = "gifts";
    const result = await helpers.apiCall("GET", url);
    setGiftsList(
      result && result.data && result.data.result ? result.data.result : []
    );
  }, [setGiftsList]);

  useEffect(() => {
    GiftsData();
  }, [GiftsData]);

  const deleteGifts = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f50057",
      cancelButtonColor: "#263273",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        const url = `Gifts/${id}`;
        const result = await helpers.apiCall("DELETE", url);
        if (result.status === 200) {
          GiftsData();
          enqueueSnackbar("Gifts Deleted successfully", {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(result.data ? result.data.message : result.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      }
    });
  };
  // const handleChange = () => {
  //     setCheck(!check);
  // };

  return (
    <PageContainer pageheading="GIFTS">
      <Grid container spacing={3}>
        <Grid item sm={12} xs={12}>
          {/* <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch checked={check} onChange={handleChange} name="check" color="primary"/>
                            }
                            label="Show Gifts List"
                        /> */}
          {/* </FormGroup> */}

          <GiftsList
            giftsList={giftsList}
            deleteGifts={deleteGifts}
            // editGifts={editGifts}
            // singleGiftsById={singleGiftsById}
            check={check}
            setCheck={setCheck}
          />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Gifts;
