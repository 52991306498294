import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "react-data-table-component";
import moment from "moment";
import { ArrowDownward } from "@material-ui/icons";
import yellow from "@material-ui/core/colors/yellow";
import { Link } from "react-router-dom";
import { helpers } from "../../helper/helper";

const sortIcon = <ArrowDownward />;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(32),
      height: theme.spacing(16),
    },
  },
  yellowPaper: {
    backgroundColor: yellow[300],
  },
  customBorder: {
    border: `3px solid ${yellow[200]}`,
  },
  customBorderRadius: {
    borderRadius: 10,
  },
  btn: {
    margin: theme.spacing(4)
  }
}));

const History = () => {

  const [list, setList] = useState([]);
  const classes = useStyles();

  const columns = [
    {
      name: "User",
      selector: (row) => {
        return row?.userDetails?.profilePic ?
          <Link to={`/dashboard/users/${row?.userDetails?._id}`} href={row?.userDetails?.profilePic} style={{ textDecoration: 'none', color: "#000" }}>
            <img src={row?.userDetails?.profilePic} style={{ height: "50px" }} /> <span>{row?.userDetails?.name}</span>
          </Link> : row?.userDetails?.name
      },
      wrap: true,
      sortable: true,
    },
    {
      name: "Updated At",
      selector: (row) => moment(new Date(row.createdAt)).format('DD-MM-YYYY h:mm a'),
      wrap: true,
      sortable: true,
    },
    {
      name: "Insta",
      selector: (row) => row?.instaUrl ? row.instaUrl : '-',
      wrap: true,
      sortable: true,
    },
    {
      name: "Facebook",
      selector: (row) => row?.facebookUrl ? row.facebookUrl : '-',
      wrap: true,
      sortable: true,
    },
    {
      name: "Youtube",
      selector: (row) => row?.youtubeUrl ? row.youtubeUrl : '-',
      wrap: true,
      sortable: true,
    },
    {
      name: "Other",
      selector: (row) => row?.otherUrl ? row.otherUrl : '-',
      wrap: true,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.accountVerifyStatus === "A" ? "Accepted" : "Rejected",
      wrap: true,
      sortable: true,
    }
  ];

  useEffect(() => {
    getListOfProfileVerificationHistory();
  }, []);

  const getListOfProfileVerificationHistory = async () => {
    const url = "profile/verification/history/list/admin/v1";
    const result = await helpers.apiCall("GET", url);
    setList(result && result.data && result.data.result ? result.data.result.response : []);
  };

  return (
    <React.Fragment>
      <Paper className={classes.customBorderRadius} elevation={15}>
        <DataTable
          columns={columns}
          data={list}
          defaultSortField="updatedAt"
          pagination
          paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
          sortIcon={sortIcon}
          noHeader={true}
          striped={true}
          highlightOnHover={true}
          responsive={true}
        />
      </Paper>
    </React.Fragment>
  )
};

export default History;