import React, {useState, useEffect , useCallback} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Paper, Button} from "@material-ui/core";
import {Formik, Form} from "formik";
import {helpers} from "../../../helper/helper";
import StaticPageFrom from "./StaticPageForm";
import {useSnackbar} from "notistack";
import { useHistory } from "react-router";
import RichTextEditor from "react-rte";
import { EditorState, convertToRaw, ContentState} from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        background: 'transparent',

        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(4, 0, 1, 0),
    },
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "91%",
    },
}));


const AddStaticPage = (props) => {
    const Id = props.match.params.id
    const history = useHistory()
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [singlePlan, setSinglePlan] = useState();
    const [planId, setPlanId] = useState();
    const [editCheck, setEditCheck] = useState(false);
    const [text , setText] = useState(EditorState.createEmpty())
    const [ids , setIds] = useState()

    const initialValues = {
        title: "",
        message: "",
        subTitle:""
    };

    const editValues = {

        title: singlePlan && singlePlan.title ? singlePlan.title : '',
        subTitle: singlePlan && singlePlan.subTitle ? singlePlan.subTitle : '',
        message: singlePlan && singlePlan.description ? htmlToDraft(singlePlan.description ): '',
       
    };

    const handleSubmit = async (values) => {
        const url = "static";
        const DataStatic={
            title:values.title,
            subTitle:values.subTitle,
            description:draftToHtml(convertToRaw(text.getCurrentContent()))
        }
        const result = await helpers.apiCall("POST", url, DataStatic);
        if (result.status === 200) {
          
            enqueueSnackbar("Notification Created successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
            history.push('/dashboard/staticpage')
        }
        else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };


    const editPlan = async (values) => {
        const url = `static/${ids}`;
        const DataStatic={
            title:values.title,
            subTitle:values.subTitle,
            description:draftToHtml(convertToRaw(text.getCurrentContent()))
        }
        const result = await helpers.apiCall("PUT", url, DataStatic);
        if (result.status === 200) {
          
            setEditCheck(false);
            enqueueSnackbar("Updated successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
            history.push('/dashboard/staticpage')
        }
        else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };

    const singlePlanById = useCallback(async (id) => {
        setPlanId(Id);
        const url = `static/${Id}`;
        const result = await helpers.apiCall("get", url);
        if (result.status === 200) {
            setIds(result &&result.data && result.data.result && result.data.result._id?result.data.result._id:"")
            setSinglePlan(result.data.result);
            const blocksFromHtml = htmlToDraft(result &&result.data && result.data.result && result.data.result ? result.data.result.description:"");
            const {contentBlocks, entityMap} = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
            );
            const editorState = EditorState.createWithContent(contentState);
            setText(editorState || EditorState.createEmpty());


            setEditCheck(true);
        } else {
            setEditCheck(false);
        }
    },[Id])

    useEffect(() => {
        if(Id && !editCheck){
            singlePlanById()
        }
    }, [singlePlanById , Id , editCheck]);

    return (
        <Paper className={classes.paper} square>
            <Formik
                enableReinitialize="true"
                initialValues={editCheck ? editValues : initialValues}
                onSubmit={editCheck ? editPlan : handleSubmit}
            >
                {(formik) => {
                    return (
                        <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Static Page Form</h4>
                <p className="card-description"> Add Static Page Detail Here </p>
                        <Form autoComplete="off">
                            <StaticPageFrom formik={formik} planId={planId} RichTextEditor={RichTextEditor} setText={setText} text={text}/>
                            <Grid align="right" item style={{marginTop: "10px"}}>
                                <div className={classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className={classes.submit}
                                        color="primary"
                                    >
                                        {editCheck ? "UPDATE" : "Create"}
                                    </Button>
                                </div>
                            </Grid>
                        </Form>
                        </div>
                        </div>
                        </div>
                    );
                }}
            </Formik>
        </Paper>


    );
};

export default AddStaticPage;
