import React, {useEffect, useState, useCallback} from "react";
import PageContainer from "../../layouts/PageContainer";
import Swal from "sweetalert2";
import {Grid} from "@material-ui/core";
import GifList from "./GifList";
import {helpers} from "../../helper/helper";

import {useSnackbar} from "notistack";

const Banner = () => {
    const {enqueueSnackbar} = useSnackbar();
    const [gifList, setGifList] = useState();
    const [check, setCheck] = useState(true);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
      };
    

    const GiftsData = useCallback(async () => {
        const url = "gif";
        const result = await helpers.apiCall("GET", url);
        setGifList(
            result && result.data && result.data.result ? result.data.result : []
        );
    }, [setGifList]);
    // console.log(gifList)

    useEffect(() => {
        GiftsData();
    }, [GiftsData]);

    const deleteGifts = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#f50057",
            cancelButtonColor: "#263273",
            confirmButtonText: "Yes, delete it!",
          }).then(async (result) => {
            if (result.value) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              const url = `gif/delete`;
              const result = await helpers.apiCall("POST", url, {id});
              if (result.status === 200) {
                  GiftsData();
                  handleClose();
                  enqueueSnackbar("Gif Deleted successfully", {
                      variant: 'success',
                      autoHideDuration: 3000,
                  });
              }
              else {
                  enqueueSnackbar(result.data ? result.data.message : result.message, {
                      variant: 'error',
                      autoHideDuration: 3000,
                  });
              }
            }
          });
       
    };

    return (
        <PageContainer pageheading="GIF">
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                    <GifList
                        gifList={gifList}
                        deleteGifts={deleteGifts}
                        setCheck={setCheck}
                        check={check}
                        setOpen={setOpen}
                        open={open}
                        handleClose={handleClose}
                    />
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Banner;
